import {AXIOS_FETCH_ERROR, AXIOS_FETCH_START, AXIOS_FETCH_SUCCESS} from '../../constants/ActionTypes'

export const AxiosFetchStart = () => {
    return {
      type: AXIOS_FETCH_START
    }
  };
  
  export const AxiosFetchSuccess = () => {
    return {
      type: AXIOS_FETCH_SUCCESS
    }
  };
  
  export const AxiosFetchError = (error) => {
    return {
      type: AXIOS_FETCH_ERROR,
      payload: error
    }
  };