import {GET_ALL_COST_CENTER,SET_SEARCH_COST_CENTER,SELECT_FILTER_OPTION_COST_CENTER,FILTER_COST_CENTER,
        SET_ADD_COST_CENTER_MODAL_OPEN,SET_ADD_COST_CENTER_MODAL_CLOSE,TOOGLE_DRAWER_COST_CENTER} from '../../../../constants/ActionTypes'
const InitialState={
    AllData:[],
    Data:[],
    selectedSectionId: 2,
    drawerState: false,
    search: '',
    filterOption: 'All contacts',
    addModalVisible: false,
}

function CostCenterMaintenace(state=InitialState,action){
    switch (action.type) {

        case GET_ALL_COST_CENTER:
        return {
                ...state,
                AllData:action.payload,
                Data:action.payload.filter(x=>((state.selectedSectionId===1)?true:false) || (state.selectedSectionId===2 && x.active===true ) || (state.selectedSectionId===3 && x.active===false )),
            };
        case SET_SEARCH_COST_CENTER:
            return {
                ...state,
                search:action.payload
            };
        case SELECT_FILTER_OPTION_COST_CENTER:
            switch (action.payload) {
                case 1: 
                return{
                    ...state,
                    Data:state.AllData,
                    selectedSectionId:action.payload
                }
                case 2:
                    return {
                        ...state,
                        Data:state.AllData.filter(row=>row.active===true)
                        ,selectedSectionId:action.payload
                    }
                case 3: 
                    return {
                        ...state,
                        Data:state.AllData.filter(row=>row.active===false), 
                        selectedSectionId:action.payload
                    }
                default:
                    return state
            }
            case FILTER_COST_CENTER:
               
            const NData=state.AllData.filter((row)=>`${row.code} ${row.description}${((row.active)?'Activo':'Inactivo')}`.toLowerCase().indexOf(state.search.toLowerCase())!==-1)
                .filter((x)=>((state.selectedSectionId===1)?true:false) || (state.selectedSectionId===2 && x.active===true ) || (state.selectedSectionId===3 && x.active===false ))
               
                return {
                ...state,
                Data:NData
                };

            case SET_ADD_COST_CENTER_MODAL_OPEN:
                return {
                    ...state,
                    addModalVisible:action.payload
                } 
            case SET_ADD_COST_CENTER_MODAL_CLOSE:
                return {
                    ...state,
                    addModalVisible:action.payload
                }
            case TOOGLE_DRAWER_COST_CENTER:
                return {
                    ...state,
                    drawerState:!state.drawerState
                }
        default:
            return state
    }
}

export default CostCenterMaintenace;