import {RESET_COMPANY_CREATE,SET_USER_COMPANY_CREATE,GET_COMPANY_GROUP_COMPANY_CREATE,GET_ALL_COMPANY_BY_USER_COMPANY_CREATE,GET_ALL_BRANCH_BY_USER_COMPANY_CREATE,GET_ALL_USER_BY_USER_COMPANY_CREATE,GET_Branches_LIST_Register} from '../../../constants/ActionTypes'
import { zeroPad } from "../../../util/DaFrameword";
const InitialState={
    USER_ID : 0,
    CompanyGroupData:[],
    CompanyData:[],
    BranchData:[],
    UserData:[],    
    BranchList:[]
}

function CompanyCreated(state=InitialState,action){
    switch (action.type) {
        case SET_USER_COMPANY_CREATE:
            return {
                ...state,
                USER_ID: action.payload
            }
        case GET_COMPANY_GROUP_COMPANY_CREATE:
            return {
                ...state, 
                CompanyGroupData:action.payload
            }
        case GET_ALL_COMPANY_BY_USER_COMPANY_CREATE:
            return {
                ...state, 
                CompanyData:action.payload
            }
            case GET_Branches_LIST_Register:
                return {
                    ...state,
                        BranchList:action.payload.map(x=>({
                        Id:zeroPad(x.id,8),
                        codeBranch:x.codeBranch,
                        displayBranch:x.displayCompany
                    })) 
                }
        case GET_ALL_BRANCH_BY_USER_COMPANY_CREATE:
            return {
                ...state,
                BranchData:action.payload
            }
        case GET_ALL_USER_BY_USER_COMPANY_CREATE:
            return {
                ...state,
                UserData:action.payload
            }
        case RESET_COMPANY_CREATE:
            return InitialState;
        
        default:
            return state;
    }
}


export default CompanyCreated;