import {GET_TABLE_DATA_ITBIS_RETAINED} from '../../../../constants/ActionTypes'
const InitialState={
    TableData:[]
}
function ItbisRetained (state=InitialState, action){
    switch (action.type) {
        case GET_TABLE_DATA_ITBIS_RETAINED:

            let NewData=action.payload.map((data, index)=>(({
                ...data,
                description:`${data.itbisRetentionType.description} (${data.itbisRetentionType.percent}%)`,
                account:data.catalogAccount.account
            })));

            return {

                TableData:NewData
            };
        default:
          return state;
    }
}

export default ItbisRetained;