import {postRequest,getRequest,putRequest} from '../../Axios'
import {GET_ALL_CONTAB_ANNEX,SET_SEARCH_CONTAB_ANNEX,SELECT_FILTER_OPTION_CONTAB_ANNEX,FILTER_CONTAB_ANNEX,SET_ADD_CONTAB_ANNEX_MODAL_OPEN,
        SET_ADD_CONTAB_ANNEX_MODAL_CLOSE,TOOGLE_DRAWER_CONTAB_ANNEX} from '../../../../constants/ActionTypes'

    export  const addContabAnnex=(params,body,CallBack)=>(dispatch)=>{
        dispatch(postRequest("/ContabAnnex",params,body))
        .then(resp=>{
            const {payload:{data,status}}=resp;
            CallBack(data,status)
        }).catch(error=>console.log(error));
    }   

    export  const updateContabAnnex=({Id},body,CallBack)=>(dispatch)=>{
    dispatch(putRequest(`/ContabAnnex/${Id}`,{},{ ...body,Id}))
    .then(resp=>{
        const {payload:{data,status}}=resp;
        CallBack(data,status)
    }).catch(error=>console.log(error));
    }

    export const GetContabAnnex=()=>(dispatch)=>{
        dispatch(getRequest("/ContabAnnex/GetAllContabAnnex",{}))
        .then(resp=>{
            const {payload:{data}}=resp;
            dispatch({
                type:GET_ALL_CONTAB_ANNEX,
                payload:data
            })
        }).catch(error=>console.log(error));
    }

    export const setSearchContabAnnex=(seachText)=>{
        return{
            type:SET_SEARCH_CONTAB_ANNEX,
            payload:seachText
        }
    }

    export const selectOptionContabAnnex=(optionId)=>{  
        return{
            type:SELECT_FILTER_OPTION_CONTAB_ANNEX,
            payload:optionId
        }
    }

    export const filterContabAnnex=()=>{
        return {
            type:FILTER_CONTAB_ANNEX
        }
    }


    export const addContabAnnexModalOpen =()=>({
        type:SET_ADD_CONTAB_ANNEX_MODAL_OPEN,
        payload:true
    })

    export const addContabAnnexModalClose =()=>({
            type:SET_ADD_CONTAB_ANNEX_MODAL_CLOSE,
            payload:false
    })

export const toogleDrawerContabAnnex=()=>({
    type:TOOGLE_DRAWER_CONTAB_ANNEX
})
  
