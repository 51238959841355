import {getRequest} from '../Axios'
import {GET_COMPANY_GROUPS_BY_USER} from '../../../constants/ActionTypes'

export const GetCompanyGroup=()=>(dispatch)=>{
    dispatch(getRequest("CompanyGroup/GetCompanyGroupByUser"))
    .then(({payload:{data}})=>{
        dispatch({
            type:GET_COMPANY_GROUPS_BY_USER,
            payload:data
        })
    })
}