import React from "react";
import {Layout} from 'antd';
import {useDispatch, useSelector} from "react-redux";
import UserInfo from "components/UserInfo";
import {toggleCollapsedSideNav} from "../../../appRedux/actions/Setting";

import {TAB_SIZE} from "../../../constants/ThemeSetting";
import HorizontalNav from "../HorizontalNav";
import {Link, useHistory} from "react-router-dom";

const {Header} = Layout;


const AboveHeader = () => {
  const history=useHistory();
  const dispatch = useDispatch();
  const navCollapsed = useSelector(({common}) => common.navCollapsed);
  const company = useSelector(({auth}) => auth.company);
  const branch = useSelector(({auth}) => auth.branch);
  const width = useSelector(({settings}) => settings.width);

  const Redirect=(Url)=>{
    history.push(Url);
  }

  return (
    company==null?null:
    <div className="gx-header-horizontal gx-header-horizontal-dark gx-above-header-horizontal">
      <div className="gx-header-horizontal-nav gx-header-horizontal-nav-curve gx-d-none gx-d-lg-block">
        <div className="gx-container">
          <div className="gx-header-horizontal-nav-flex">
            <HorizontalNav/>
          </div>
        </div>
      </div>
      <Header
        className="gx-header-horizontal-main">
      <div className="gx-header-horizontal-top" style={{maxHeight:'5px'}}>
              <div className="gx-container gx-text-center gx-text-white">
                 <p className="gx-mb-0 gx-text-truncate" style={{fontSize:'x-large'}}> {company.displayCompany} / {branch.displayBranch} </p>
              </div>
      </div>
        <div className="gx-container">
          <div className="gx-header-horizontal-main-flex">
            <div className="gx-d-block gx-d-lg-none gx-linebar gx-mr-xs-3">
              <i className="gx-icon-btn icon icon-menu"
                 onClick={() => {
                   if (width <= TAB_SIZE) {
                     dispatch(toggleCollapsedSideNav(!navCollapsed));
                   }
                 }}
              />
            </div>
            <Link to="/" className="gx-d-block gx-d-lg-none gx-pointer gx-mr-xs-3 gx-pt-xs-1 gx-w-logo">
            <img alt="" src={company.fileNameImage || ""} style={{height:'40px',borderRadius:'50%'}}/></Link>
            <Link to="/" className="gx-d-none gx-d-lg-block gx-pointer gx-mr-xs-5 gx-logo">
            <img  alt="" src={company.fileNameImage || ""} style={{height:'40px',borderRadius:'50%'}}/></Link>
            <ul className="gx-header-notifications gx-ml-auto">
              <li className="gx-language">
              <span onClick={Redirect.bind(this,"/Maintenance/Main")} className="gx-pointer gx-flex-row gx-align-items-center">
                <i  className={`icon icon-setting icon-2x`} style={{fontSize:18}} /> 
              </span>
              </li>
              <li className="gx-user-nav"><UserInfo/></li>
            </ul>
          </div>
        </div>
      </Header>
    </div>
  );
};
export default AboveHeader;