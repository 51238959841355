import React from 'react'
import {Route,Switch} from 'react-router-dom'
import asyncComponent from "../../../util/asyncComponent";

const FIS_Consult =({match})=>(
    <Switch>
          <Route  path={`${match.url}/CopyProofOfPurchase`} component={asyncComponent(()=>import( './CopyProofOfPurchase/index'))} />
          <Route  path={`${match.url}/NCFAvailable`}        component={asyncComponent(()=>import( './NCFAvailable/index'))} />
          <Route  path={`${match.url}/ITBISRetentionCertification`} component={asyncComponent(()=>import( './ITBISRetentionCertification/index'))} />
    </Switch>
)
export default FIS_Consult;