import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import Settings from "./Settings";
import Auth from "./Auth";
import Notes from "./Notes";
import Contact from "./Contact";
import Common from "./Common";
import Axios from "./Axios";
import Company from './Selector/Company';
import Branch from './Selector/Branch';
import CompanyGroup from './Selector/CompanyGroup';
import FinancialStatement from './FC/Entry/FinancialStatement';
import StateAccountSeach from './FC/Entry/StateAccountSearch'
import RegisterAuxiliar from './FC/Entry/RegisterAuxiliar'
import DocumentMaintenance from './Maintenance/DocumentAbbreviation'
import ActionProgramAccount from './Maintenance/ActionProgramAccount'
import ContabAction from  './Maintenance/ContabAction'
import BankRegister from  './Maintenance/BankRegister'
import CostCenter from  './Maintenance/CostCenter'
import ContabTitle from  './Maintenance/ContabTitle'
import ContabAnnex from './Maintenance/ContabAnnex'
import DebtCollector from './Maintenance/DebtCollector'
import Seller from './Maintenance/Seller'
import AuxiliaryClassification from './Maintenance/AuxiliaryClassification'
import DynamicTax from './Maintenance/DynamicTax'
import BoxCatalogAccount from './Maintenance/BoxCatalogAccount'
import DocumentConfiguration from './Maintenance/DocumentConfiguration'
import VoucherRecord from './FC/Entry/VoucherRecord'
import EditVoucher from './FC/Entry/EditVoucher'
import Default from './Default'
import ConsultAccount from './FC/Consult/Account'
import TPSRequestRegister from './FIS/Entry/TaxPayerSequenceRequestRegister'
import ProductRegister from './FAC/Entry/ProductRegister'
import InvoiceRegister from './FAC/Entry/InvoiceRegister'
import NCFAccount from './FC/ClosingAndOthers/NCFAccount'
import BuyGoodsAndServices from './CXC/Entry/BuyGoodsAndServices'
import ProofOfPurchase from './FIS/Entry/ProofOfPurchase'
import BankAccount from './Bank/Entry/BankAccount'
import IncomeReceipt from './CXC/Entry/IncomeReceipt'
import CustomerMovement from './CXC/Consult/CustomerMovement'
import ProviderMovement from './CXP/Consult/ProviderMovement'
import NCFAvailable from './FIS/Consult/NCFAvailable'
import ProviderPayment from './CXP/Entry/ProviderPayment'
import CompanyCreated from './UC/CompanyCreated'
import LoadingCenter from './LoadingCenter'
import MonthlyClosing from './FC/ClosingAndOthers/MonthlyClosing'
import ModuleClosing from './FC/ClosingAndOthers/ModuleClosing'
import BudgetRegister from './FAC/Entry/BudgetRegister'
import MembershipRegister from './FAC/Entry/MembershipRegister'
import MembershipBilling from './FAC/Entry/MembershipBilling';
import PrintInvoice from './FAC/Entry/PrintInvoice';
import BankConciliationRed from './Bank/ClosingAndOthers/BankConciliation'
import CreditNotice from './CXC/Entry/CreditNotice'
import CurrencyTax from './Maintenance/CurrencyTax'
import ReplenishmentExpenses from './CXP/Entry/ReplenishmentExpenses'
import CreditNotes from './FAC/Entry/CreditNotes'
import MachineTypeRegister from './Maintenance/MachineType';
import BrandRegister from './Maintenance/Brand';
import ModelRegister from './Maintenance/Model';
import ColorRegister from './Maintenance/Color';
import FuelTypeRegister from './Maintenance/FuelType';
import DepartmentRegister from './Maintenance/Department';
import ClasificationRegister from './Maintenance/Clasification';
import AssetCategoryRegister from './Maintenance/AssetCategory';
import AdquisitionRegister from './Maintenance/AdquisitionType';
import AssetMovementData from './Maintenance/AssetsMovementData';
import AccountingDepartment from "./POS/Entry/AccountingDepartment"
import LocalPurchase from './POS/Entry/LocalPurchase';
import PricingModelingMaintenance from './Maintenance/PricingModel';
import InventoryMovement from './POS/Entry/InventoryMovement';
import ITBISRetentionCertification from './FIS/Consult/ITBISRetentionCertification'
import BankCharges from './Bank/Entry/BankCharges';
import ChargesTypeAccount from './Bank/Entry/ChargesTypeAccount';
import ManagerRegister from './Maintenance/ManagerRegister';
import ItbisRetained from './FIS/Entry/ItbisRetained';
import IsrRetained from './FIS/Entry/isrRetained';
import ManufacturerRegister from './Maintenance/Manufacturer';
import LocationSectionRegister from './Maintenance/LocationSection';
import ProductClassificationRegister  from './Maintenance/ProductClassification';
import TrayLocationRegister from './Maintenance/TrayLocation';
import Invoice from './POS/Entry/Invoice';
import ProductSubClassificationRegister from './Maintenance/ProductSubClassification';
import EntryAndExitMovementByItem from './POS/Consult/EntryAndExitMovementByItem'

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  settings: Settings,
  auth: Auth,
  notes: Notes,
  contact: Contact,
  common: Common,
  Axios,
  Company,
  Branch,
  CompanyGroup,
  FinancialStatement,
  StateAccountSeach,
  RegisterAuxiliar,
  DocumentMaintenance,
  ActionProgramAccount,
  ContabAction,
  BankRegister,
  CostCenter,
  ContabTitle,
  ContabAnnex,
  DebtCollector,
  Seller,
  AuxiliaryClassification,
  DynamicTax,
  BoxCatalogAccount,
  DocumentConfiguration,
  VoucherRecord,
  EditVoucher,
  Default,
  ConsultAccount,
  TPSRequestRegister,
  ProductRegister,
  InvoiceRegister,
  NCFAccount,
  BuyGoodsAndServices,
  ProofOfPurchase,
  BankAccount,
  IncomeReceipt,
  CustomerMovement,
  ProviderMovement,
  NCFAvailable,
  ProviderPayment,
  CompanyCreated,
  LoadingCenter,
  MonthlyClosing,
  ModuleClosing,
  BudgetRegister,
  MembershipRegister,
  MembershipBilling,
  PrintInvoice,
  BankConciliationRed,
  CreditNotice,
  CurrencyTax,
  ReplenishmentExpenses,
  CreditNotes,
  MachineTypeRegister,
  BrandRegister,
  ModelRegister,
  ColorRegister,
  FuelTypeRegister,
  DepartmentRegister,
  ClasificationRegister,
  AssetCategoryRegister,
  AdquisitionRegister,
  AssetMovementData,
  AccountingDepartment,
  LocalPurchase,
  PricingModelingMaintenance,
  InventoryMovement,
  ITBISRetentionCertification,
  BankCharges,
  ChargesTypeAccount,
  ManagerRegister,
  ItbisRetained,
  IsrRetained,
  ManufacturerRegister,
  LocationSectionRegister,
  ProductClassificationRegister,
  TrayLocationRegister,
  Invoice,
  ProductSubClassificationRegister,
  EntryAndExitMovementByItem
});

export default createRootReducer;
