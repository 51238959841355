import { GET_AdquisitionType_LIST_Register } from "../../../../constants/ActionTypes";
import { zeroPad } from "../../../../util/DaFrameword";


const InitialValue={
    AdquisitionTypeList:[]
}

function AdquisitionTypeRegister(state=InitialValue,action){

    switch (action.type) {
        case GET_AdquisitionType_LIST_Register:
            return {
                ...state,
                    AdquisitionTypeList:action.payload.map(x=>({
                    Id:zeroPad(x.id,8),
                    description:x.description             
                })) 
            }
        default:
           return state
    }  

}

export default AdquisitionTypeRegister;
