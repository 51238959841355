
import React from 'react'
import {Route,Switch} from 'react-router-dom'
import Entry from '../FIS/Entry'
import Consult from '../FIS/Consult'
import Report from '../FIS/Report'
import ClosingAndOther from './ClosingAndOthers'
const FIS_Module =({match})=>(
    <Switch>
        <Route path={`${match.url}/Entry`} component={Entry}  /> 
        <Route path={`${match.url}/Consult`} component={Consult}  /> 
        <Route path={`${match.url}/Report`} component={Report} /> 
        <Route path={`${match.url}/ClosingAndOthers`} component={ClosingAndOther} /> 
    </Switch>
)
export default FIS_Module;