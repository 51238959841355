import React from 'react'
import {Route,Switch} from 'react-router-dom'
import asyncComponent from "../../../util/asyncComponent";
const FIS_Entry =({match})=>(
    <Switch>
    
          <Route  path={`${match.url}/RNCRegister`} component={asyncComponent(()=>import( './RNCRegister/index'))} />
          <Route  path={`${match.url}/TaxPayerSequenceRegister`} component={asyncComponent(()=>import( './TaxPayerSequenceRequestRegister/index'))} />
          <Route  path={`${match.url}/ProofOfPurchase`} component={asyncComponent(()=>import( './ProofOfPurchase/index'))} />
          <Route  path={`${match.url}/ITBISRetainedConfiguration`} component={asyncComponent(()=>import( './ITBISRetainedConfiguration/index'))} />
          <Route  path={`${match.url}/ISRRetainedConfiguration`} component={asyncComponent(()=>import( './ISRRetainedConfiguration/index'))} />
          <Route  path={`${match.url}/Modification606`} component={asyncComponent(()=>import( './Modification606/index'))} />
    </Switch>
)

export default FIS_Entry;