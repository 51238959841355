import React, {useState} from "react";
import {Col, Layout, Row} from "antd";
import {Link, useHistory} from "react-router-dom";
import {toggleCollapsedSideNav} from "../../appRedux/actions/Setting";
import UserInfo from "components/UserInfo";
import Auxiliary from "util/Auxiliary";
import {NAV_STYLE_DRAWER, NAV_STYLE_FIXED, NAV_STYLE_MINI_SIDEBAR, TAB_SIZE} from "../../constants/ThemeSetting";
import {useDispatch, useSelector} from "react-redux";

const {Header} = Layout;

const Topbar = () => {
  const history=useHistory();
  const {locale, navStyle} = useSelector(({settings}) => settings);
  const{width,navCollapsed}= useSelector(({common})=>common)
  const {searchText, setSearchText} = useState('');
  const dispatch = useDispatch();
  const company = useSelector(({auth}) => auth.company);
  const branch = useSelector(({auth}) => auth.branch);

  const Redirect=(Url)=>{
    history.push(Url);
  }

  return (
    company==null?null:
    
    <Header>
      {navStyle === NAV_STYLE_DRAWER || ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) && width < TAB_SIZE) ?
        <div className="gx-linebar gx-mr-3 gx-text-white">
          <i className="gx-icon-btn icon icon-menu"
             onClick={() => {
               dispatch(toggleCollapsedSideNav(!navCollapsed));
             }}
          />
        </div> : null}
      <Link to="/" className="gx-d-block gx-d-lg-none gx-pointer">
        <img alt="" src={company.fileNameImage || ""} style={{height:'40px',borderRadius:'50%'}}/>
      </Link>
      <Row>
        <Col sm={20}>
      <div className="gx-header-horizontal-top" style={{maxHeight:'0px'}}>
              <div className="gx-container gx-text-center" style={{marginTop: '-12px'}}>
                 <p className="gx-mb-0 gx-text-truncate gx-text-white" style={{fontSize:'x-large'}}> {company.displayCompany} / {branch.displayBranch} </p>
              </div>
      </div>
        
        </Col>
        <Col sm={4}>
      <ul className="gx-header-notifications gx-ml-auto">
        <li className="gx-language">
        <span onClick={Redirect.bind(this,"/Maintenance/Main")} className="gx-pointer gx-flex-row gx-align-items-center gx-text-white">
                <i  className={`icon icon-setting icon-2x`} style={{fontSize:18}} /> 
              </span>
        </li>
        {/* {width >= TAB_SIZE ? null :
          <Auxiliary>
            <li className="gx-user-nav gx-text-white"><UserInfo/></li>
          </Auxiliary>
        } */}
      </ul>
        
        </Col>
      </Row>
   
    </Header>
  );
};

export default Topbar;
