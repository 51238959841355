import {postRequest,getRequest,putRequest} from '../../../actions/Axios'
import {GET_ALL_DOCUMENT_CONFIGURATION,SELECT_FILTER_OPTION_DOCUMENT_CONFIGURATION, FILTER_DOCUMENT_CONFIGURATION,SET_SEARCH_DOCUMENT_CONFIGURATION,FILTER_CONTAB_ACTION,SET_ADD_CONTAB_ACTION_MODAL_OPEN,SET_ADD_CONTAB_ACTION_MODAL_CLOSE,TOOGLE_DRAWER_CONTAB_ACTION} from '../../../../constants/ActionTypes'

    export const GetDocumentConfiguration=()=>(dispatch)=>{

        dispatch(getRequest("/ActionProgramCatalogAccount/GetInfo",{}))
        .then(resp=>{
            const {payload:{data}}=resp;
    
            dispatch({
                type:GET_ALL_DOCUMENT_CONFIGURATION,
                payload:data
            })
        }).catch(error=>console.log(error));
    }

    export  const addDocumentConfiguration=(params,body,CallBack)=>(dispatch)=>{
        dispatch(postRequest("/ActionProgramCatalogAccount",params,body))
        .then(resp=>{
            const {payload:{data,status}}=resp;
            CallBack(data,status)
        }).catch(error=>console.log(error));
    }   

    export  const updateDocumentConfiguration=({Id},body,CallBack)=>(dispatch)=>{
    dispatch(putRequest(`/ActionProgramCatalogAccount/${Id}`,{},{ ...body,Id}))
    .then(resp=>{
        const {payload:{data,status}}=resp;
        CallBack(data,status)
    }).catch(error=>console.log(error));
    }


    
    export const selectOptionDocumentConfiguration=(optionId)=>{  

        return{
            type:SELECT_FILTER_OPTION_DOCUMENT_CONFIGURATION,
            payload:optionId
        }
    }

    export const setSearchDocumentConfiguration=(seachText)=>{
        return{
            type:SET_SEARCH_DOCUMENT_CONFIGURATION,
            payload:seachText
        }
    }


    
    export const filterDocumentConfigurationData=()=>{
        return {
            type:FILTER_DOCUMENT_CONFIGURATION
        }
    }
/*------------------------------------------------------------------------*/



    export const filterContabActionData=()=>{
        return {
            type:FILTER_CONTAB_ACTION
        }
    }


    export const  addContabActionModalOpen =()=>({
        type:SET_ADD_CONTAB_ACTION_MODAL_OPEN,
        payload:true
    })

    export const  addContabActionModalClose =()=>({
            type:SET_ADD_CONTAB_ACTION_MODAL_CLOSE,
            payload:false
    })

export const toogleDrawerContabAction=()=>({
    type:TOOGLE_DRAWER_CONTAB_ACTION
})
  
