import {getRequest} from '../Axios'
import {SET_ALL_COMPANIES} from '../../../constants/ActionTypes'


export const SetCompanies =(data)=>{
    return {
        type:SET_ALL_COMPANIES,
        payload:{
            data
        }
    }
}


export const GetCompany=(params)=>(dispatch)=>{
    dispatch(getRequest(`/Companies/GetCompanies`,params))
    .then(({payload:{data}})=>{
        dispatch({
                    type:SET_ALL_COMPANIES,
                    payload:{
                        data
                    }
                })
    })
}