import {GET_INVOICE_TO_PRINT_PRINT_INVOICE,UPDATE_ROW_SELECTION_PRINT_INVOICE,UPDATE_ALL_ROW_SELECTION_PRINT_INVOICE} from '../../../../constants/ActionTypes'
import { zeroPad,currencyFormat } from '../../../../util/DaFrameword';
import { CUSTOMER_LENGTH } from '../../../../constants/Config';
import moment from 'moment';
const InitialState={
    InvoiceListDataTable:[],
}

function PrintInvoice(state=InitialState, action){
    switch (action.type) {

        
         case GET_INVOICE_TO_PRINT_PRINT_INVOICE:

             return{
                 ...state,
                 InvoiceListDataTable:action.payload.map(x=>({
                     ...x,
                     ...x.imrInvoiceMovementKey,
                     customerCode: zeroPad(x.imrInvoiceMovementKey.customerCode.toString(),CUSTOMER_LENGTH),
                     TotalValueDisplay: currencyFormat((x.value+x.itbisValue)-x.discount),
                     DateDisplay:moment( new Date(x.imrInvoiceMovementKey.date)).format("DD/MM/YYYY"),
                     NumberDisplay:zeroPad(x.imrInvoiceMovementKey.number,x.imrInvoiceMovementKey.voucherDigitsNumber),
                     Selected:false
                 }))
             };
         case UPDATE_ROW_SELECTION_PRINT_INVOICE:
                const index=state.InvoiceListDataTable.findIndex(x=>x.id===action.payload.id);
                state.InvoiceListDataTable[index].Selected=!state.InvoiceListDataTable[index].Selected;
                //console.log( state.InvoiceListDataTable[index].Selected);
                //var AVIndex= state.AvailableVoucher.findIndex(x=>action.payload.voucherTypeAbbreviation===x.abbreviation);//action.payload.filter(x=>state.ListDataTable.filter(z=>z.voucherTypeAbbreviation==x.abbreviation).length>0).map(x=>({...x,Used:0}));
               /*
                if(state.ListDataTable[index].Selected){
                     state.AvailableVoucher[AVIndex].Used++;
               }else{
                  state.AvailableVoucher[AVIndex].Used--;
               }*/
                return{
                    ...state,
                }

         case UPDATE_ALL_ROW_SELECTION_PRINT_INVOICE:  
                var newData=state.InvoiceListDataTable.map((x)=>({
                    ...x,
                    Selected:action.payload
                }))
                return {
                    ...state,
                    InvoiceListDataTable:newData
                }
        default:
            return state;
    }
}
export default PrintInvoice;