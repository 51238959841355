import React from 'react'
import asyncComponent from '../../../util/asyncComponent'
import {Route,Switch} from 'react-router-dom'
const Report=({match})=>(
    <Switch>
     <Route path={`${match.url}/CatalogReport`} component={asyncComponent(()=>import('./CatalogReport/index'))}/>
     <Route path={`${match.url}/GeneralDiary`} component={asyncComponent(()=>import('./GeneralDiary/index'))}/>
     <Route path={`${match.url}/HistoryMajorGeneral`} component={asyncComponent(()=>import('./HistoryMajorGeneral/index'))}/>
     <Route path={`${match.url}/MajorGeneral`} component={asyncComponent(()=>import('./MajorGeneral/index'))}/>
     <Route path={`${match.url}/CheckBalance`} component={asyncComponent(()=>import('./CheckBalance/index'))}/>
     <Route path={`${match.url}/HistoryCheckBalance`} component={asyncComponent(()=>import('./HistoryCheckBalance/index'))}/>
     <Route path={`${match.url}/ConsolidatedMajorGeneral`} component={asyncComponent(()=>import('./ConsolidatedMajorGeneral/index'))}/>
     <Route path={`${match.url}/FinancialStatementReport`} component={asyncComponent(()=>import('./FinancialStatementReport/index'))}/>
     <Route path={`${match.url}/CheckConsolidateBalance`} component={asyncComponent(()=>import('./CheckConsolidateBalance/index'))}/>
     <Route path={`${match.url}/ConsolidatedFinancialStatementReport`} component={asyncComponent(()=>import('./ConsolidatedFinancialStatementReport/index'))}/>
     <Route path={`${match.url}/Annex`} component={asyncComponent(()=>import('./Annex/index'))}/>
    </Switch>
)
export default Report;