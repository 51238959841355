import {GET_STATE_ACCOUNT_SEARCH_DATA} from '../../../../constants/ActionTypes'
const InitiaState={
    StateAccountData:[]
}

function stateAccountSeach(state=InitiaState,action){
    switch (action.type) {
        case GET_STATE_ACCOUNT_SEARCH_DATA:
            
            return {
                ...state,
                StateAccountData:action.payload
            };
        default:
            return state;
    }

}

export default stateAccountSeach;