import {SET_VALIDATION_MESSAGE_VOUCHER_RECORD,TOOGLE_ALERT_VOUCHER_RECORD,GET_SPECIFIC_DOCUMENT_DATA_VOUCHER_RECORD,ADD_ROW_MOVEMENTS_TABLE,UPDATE_ROW_MOVEMENTS_TABLE,TOOGLE_HEADER_VISIBILITY,RESET_VOUCHER_RECORD,DELETE_ROW_MOVEMENTS_TABLE,FILTER_MOVEMENT_DATA_BY_REFERENCE,RESET_TABLE_MOVEMENTS_VOUCHER_RECORD,ADD_ACCOUNT_MOVEMENT_VOUCHER_RECORD, SET_ALL_MOVEMENTS_CLOSING_DATE} from '../../../../constants/ActionTypes'
import {AxiosPDFReport} from '../../../actions/Axios'
import {getRequest,postRequest} from '../../Axios'
export const SetValidationMessagesVoucherRecord=(ValidationMessage)=>{
    return {
        type:SET_VALIDATION_MESSAGE_VOUCHER_RECORD,
        payload:ValidationMessage
    }
}
export const toogle_Alert_Voucher_Record=()=>({
    type:TOOGLE_ALERT_VOUCHER_RECORD
})

export const GetSpecificDocumentInfoVoucherRecord=(DocumentId,ModuleInterfaceId)=>(dispatch)=>{

    dispatch(getRequest(`/DocumentEmission/GetSpecificDocument`,{DocumentEmissionId: DocumentId,ModuleInterfaceId}))
    .then(resp=>{
        const {payload:{data}}=resp;
        dispatch({
            type:GET_SPECIFIC_DOCUMENT_DATA_VOUCHER_RECORD,
             payload:data
        });
    })
    .catch(error=>console.log(error));
}
export const addRowMovement=(data)=>{

    return{
        type:ADD_ROW_MOVEMENTS_TABLE,
        payload:data
    }
    
}
export const setAllMovement=(data)=>{

    return{
        type:SET_ALL_MOVEMENTS_CLOSING_DATE,
        payload:data
    }
    
}
export const updateRowMovement=(data, index)=>({
    type:UPDATE_ROW_MOVEMENTS_TABLE,
    payload:{
        data,index
    }
})
export const removeRowMovement=(index)=>({
    type:DELETE_ROW_MOVEMENTS_TABLE,
    payload:index
})


export const ToogleHeaderVoucherRecord=()=>({
    type:TOOGLE_HEADER_VISIBILITY
})

export const ResetVoucherRecord=()=>({
    type:RESET_VOUCHER_RECORD
})


export const SaveVoucherRecord=(params,CallBack)=>(dispatch)=>{

    dispatch(postRequest(`/AccountMovement`,{},params))
    .then(res=>{    
        const {payload:{data,status}}=res;
        CallBack(data,status)

        if(typeof data["accountMovement"]!=="undefined"){
            dispatch({
                type:ADD_ACCOUNT_MOVEMENT_VOUCHER_RECORD,
                payload:{
                    data:data.accountMovement
                }
            })
        }
    }).catch(error=>console.log(error));
}

export const SaveVoucherRecordEF=(params,CallBack)=>(dispatch)=>{

    dispatch(postRequest(`/AccountMovement/SaveVoucherRecordEF`,{},params))
    .then(res=>{    
        const {payload:{data,status}}=res;
        CallBack(data,status)

        if(typeof data["accountMovement"]!=="undefined"){
            dispatch({
                type:ADD_ACCOUNT_MOVEMENT_VOUCHER_RECORD,
                payload:{
                    data:data.accountMovement
                }
            })
        }
    }).catch(error=>console.log(error));
}

export const filterMovementByReference=(id)=>({
    type:FILTER_MOVEMENT_DATA_BY_REFERENCE,
    payload:{
        id
    }
})

export const resetTableMovement=()=>({
    type:RESET_TABLE_MOVEMENTS_VOUCHER_RECORD
})

export const ValidateNumber=(ObjectValue,CallBack)=>(dispatch)=>{
    const {DocumentEmissionId,Date,Number}=ObjectValue;
    dispatch(getRequest("/AccountMovement/NumberIsAvailable",{DocumentEmissionId,Date,Number}))
        .then(values=>{
            const {payload:{data,status}}=values;
            CallBack(data,status);
    }).catch(error=>console.error(error))
}

export const CreateVoucherPdfReport=(params,dispatch)=>{
    AxiosPDFReport("AccountMovement/CreatePdfVoucher",params,dispatch);
}