import React from 'react'
import {Switch,Route} from 'react-router-dom'
import asyncComponent from "../../../util/asyncComponent";
const ReportCXC =({match})=>(
    <Switch>
          <Route  path={`${match.url}/CopyIncomeReceipt`} component={asyncComponent(()=>import( './CopyIncomeReceipt/index'))} />
          <Route  path={`${match.url}/CopyCreditNotice`} component={asyncComponent(()=>import( './CopyCreditNotice/index'))} />
          <Route  path={`${match.url}/AccountStatements`} component={asyncComponent(()=>import( './AccountStatements/index'))} />
          <Route  path={`${match.url}/CXCSummary`} component={asyncComponent(()=>import( './CXCSummary/index'))} />
          <Route  path={`${match.url}/CXCFacturaConBalance`} component={asyncComponent(()=>import( './CXCFacturaConBalance/index'))} />
    </Switch>
)
export default ReportCXC;