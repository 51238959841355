import React from 'react'
import {Route,Switch} from 'react-router-dom'
import asyncComponent from "../../util/asyncComponent";


const Maintenance=({match})=>( 
    <Switch>
        <Route path={`${match.url}/Main`} component={ asyncComponent(()=>import( './Main')) }  /> 
        <Route path={`${match.url}/DocumentAbbreviation`} component={ asyncComponent(()=>import( './DocumentAbbreviation')) }  />
        <Route path={`${match.url}/ActionProgramAccount`} component={ asyncComponent(()=>import( './ActionProgramAccount')) }  />
        <Route path={`${match.url}/ContabAction`} component={ asyncComponent(()=>import( './ContabAction')) }  />
        <Route path={`${match.url}/DocumentConfiguration`} component={ asyncComponent(()=>import( './DocumentConfiguration')) }  />
        <Route path={`${match.url}/BankRegister`} component={ asyncComponent(()=>import( './BankRegister')) }  />
        <Route path={`${match.url}/CostCenter`} component={ asyncComponent(()=>import( './CostCenter')) }  />
        <Route path={`${match.url}/ContabTitle`} component={ asyncComponent(()=>import( './ContabTitle')) }  />
        <Route path={`${match.url}/ContabAnnex`} component={ asyncComponent(()=>import( './ContabAnnex')) }  />
        <Route path={`${match.url}/DebtCollector`} component={ asyncComponent(()=>import( './DebtCollector')) }  />
        <Route path={`${match.url}/Seller`} component={ asyncComponent(()=>import( './Seller')) }  />
        <Route path={`${match.url}/AuxiliaryClassification`} component={ asyncComponent(()=>import( './AuxiliaryClassification')) }  />  
        <Route path={`${match.url}/DynamicTax`} component={ asyncComponent(()=>import( './DynamicTax')) }  /> 
        <Route path={`${match.url}/BoxCatalogAccount`} component={ asyncComponent(()=>import( './BoxCatalogAccount')) }  /> 
        <Route path={`${match.url}/BalanceAge`} component={ asyncComponent(()=>import( './BalanceAge')) }  /> 
        <Route path={`${match.url}/SpecialDataType`} component={ asyncComponent(()=>import( './SpecialDataType')) }  />
        <Route path={`${match.url}/CurrencyTax`} component={ asyncComponent(()=>import( './CurrencyTax')) }  />
        <Route path={`${match.url}/MachineType`} component={ asyncComponent(()=>import( './MachineType')) }  />
        <Route path={`${match.url}/Brands`} component={ asyncComponent(()=>import( './Brands')) }  />
        <Route path={`${match.url}/Model`} component={ asyncComponent(()=>import( './Model')) }  />
        <Route path={`${match.url}/Color`} component={ asyncComponent(()=>import( './Color')) }  />
        <Route path={`${match.url}/fuelType`} component={ asyncComponent(()=>import( './fuelType')) }  />
        <Route path={`${match.url}/Department`} component={ asyncComponent(()=>import( './Department')) }  />
        <Route path={`${match.url}/AssetCategory`} component={ asyncComponent(()=>import( './AssetCategory')) }  />
        <Route path={`${match.url}/PricingModel`} component={asyncComponent(()=>import('./PricingModel'))}/>
        <Route path={`${match.url}/ManagerRegister`} component={asyncComponent(()=>import('./ManagerRegister'))}/>
        <Route path={`${match.url}/Manufacturer`} component={asyncComponent(()=>import('./Manufacturer'))}/>
        <Route path={`${match.url}/LocationSection`} component={asyncComponent(()=>import('./LocationSection'))}/>
        <Route path={`${match.url}/ProductClassification`} component={asyncComponent(()=>import('./ProductClassification'))}/>
        <Route path={`${match.url}/TrayLocation`} component={asyncComponent(()=>import('./TrayLocation'))}/>
        <Route path={`${match.url}/ProductSubClassification`} component={asyncComponent(()=>import('./ProductSubClassification'))}/>

    </Switch>       
)
export default Maintenance;