import { ADD_INVOICE_DETAIL_MEMBERSHIP_REGISTER, UPDATE_INVOICE_DETAIL_MEMBERSHIP_REGISTER, SET_ITBIS_TAX_INVOICE_MEMBERSHIP,SET_MEMBERSHIP_OBJECT, DELETE_INVOICE_DETAIL_MEMBERSHIP_REGISTER, RESET_MEMBERSHIP_REGISTER } from '../../../../constants/ActionTypes'
import { ID } from '../../../../constants/Config'
import { currencyFormat, zeroPad } from '../../../../util/DaFrameword';
const InitialState = {
    TableData: [],
    percentItbis: null,
    IdCurrentRow: ID()
}

function MembershipRegister(state = InitialState, action) {
    switch (action.type) {
        case ADD_INVOICE_DETAIL_MEMBERSHIP_REGISTER:
            const NewRow = action.payload;
          
            return {
                ...state,
                TableData: [...state.TableData, {...NewRow,DBId:0}],
                IdCurrentRow: ID()
            }
        case UPDATE_INVOICE_DETAIL_MEMBERSHIP_REGISTER:
            const UpdateRow = action.payload;
            return {
                ...state,
                TableData: state.TableData.map(x => x.id === UpdateRow.id ? UpdateRow : x)
            }
        case DELETE_INVOICE_DETAIL_MEMBERSHIP_REGISTER:
            const { productId } = action.payload;
            return {
                ...state,
                TableData: state.TableData.filter(x => x.productId !== productId)
            }
        case SET_ITBIS_TAX_INVOICE_MEMBERSHIP:
            return {
                ...state,
                percentItbis: action.payload.percentItbis * 0.01
            }

        case RESET_MEMBERSHIP_REGISTER:
            return {
                ...state,
                TableData: [],
                IdCurrentRow: ID()
            }
        case SET_MEMBERSHIP_OBJECT:

            var list=action.payload.listDetail;
            return{
                ...state,
                TableData:list.map(x=>({
                                            DBId:x.id,
                                            id:x.id,
                                            cost: x.cost,
                                            discount:x.discount,
                                            price:x.price,
                                            quantity:x.quantity,
                                            itbissn:x.hasItbis,
                                            value:currencyFormat(x.price*x.quantity),
                                            itbisvalue:currencyFormat(x.hasItbis?state.percentItbis*((x.price*x.quantity)-x.discount)  : 0),
                                            total:currencyFormat(((x.price*x.quantity))-x.discount+(x.hasItbis?state.percentItbis*((x.price*x.quantity)-x.discount)  : 0)),
                                            code:zeroPad(x.productCode,8),
                                            description:x.productDisplay,
                                            productId:x.productId

                                        })),
              
            }
        default:
            return state;
    }

}

export default MembershipRegister;