import { ADD_INVOICE_DETAIL_INVOICE_REGISTER, UPDATE_INVOICE_DETAIL_INVOICE_REGISTER, SET_ITBIS_TAX_INVOICE_REGISTER, DELETE_INVOICE_DETAIL_INVOICE_REGISTER, RESER_INVOICE_REGISTER, SET_BILL_DATA_CREDIT_NOTES, DISABLED_TABLE_EDIT_CREDIT_NOTES, SET_MAX_VALUE_DATA_CREDIT_NOTES, SET_DAY_DIFF_CREDIT_NOTES } from '../../../../constants/ActionTypes'
import { ID } from '../../../../constants/Config'
import { currencyFormat, zeroPad } from '../../../../util/DaFrameword';
const InitialState = {
    TableData: [],
    UltimateItbis: null,
    percentItbis: null,
    IdCurrentRow: ID(),
    DisabledTableUpdate: true,
    MaxValue: 0,
    DayDiff: 0
}

function CreditNotes(state = InitialState, action) {
    switch (action.type) {
        case ADD_INVOICE_DETAIL_INVOICE_REGISTER:
            const NewRow = action.payload;
            //console.log("Agregar", NewRow)
            return {
                ...state,
                TableData: [...state.TableData, NewRow],
                IdCurrentRow: ID()
            }
        case UPDATE_INVOICE_DETAIL_INVOICE_REGISTER:
            const UpdateRow = action.payload;
            return {
                ...state,
                TableData: state.TableData.map(x => x.id === UpdateRow.id ? { ...UpdateRow, Update: true } : x),
            }
        case DELETE_INVOICE_DETAIL_INVOICE_REGISTER:
            const { productId } = action.payload;
            return {
                ...state,
                TableData: state.TableData.filter(x => x.productId !== productId)
            }
        case SET_ITBIS_TAX_INVOICE_REGISTER:
            return {
                ...state,
                UltimateItbis: action.payload.percentItbis * 0.01,
                percentItbis: action.payload.percentItbis * 0.01
            }

        case RESER_INVOICE_REGISTER:
            return {
                ...state,
                TableData: [],
                IdCurrentRow: ID()
            }
        case SET_BILL_DATA_CREDIT_NOTES:
            var list = action.payload.details;
            return {
                ...state,
                Update: false,
                TableData: list.map(x => ({
                    DBId: x.id,
                    id: x.id,
                    cost: x.cost,
                    discount: x.discount,
                    price: x.price,
                    quantity: x.quantity,
                    itbissn: x.hasItbis,
                    value: currencyFormat(x.price * x.quantity),
                    itbisvalue: currencyFormat(x.itbisValue),
                    total: currencyFormat(x.total),
                    code: zeroPad(x.code, 8),
                    description: x.description,
                    productId: x.productId,
                    initialItbisValue: x.itbisValue,
                    Update: false
                })),

            }

        case SET_MAX_VALUE_DATA_CREDIT_NOTES:
            return {
                ...state,
                MaxValue: action.payload
            }
        case DISABLED_TABLE_EDIT_CREDIT_NOTES:
            return {
                ...state,
                DisabledTableUpdate: action.payload
            }

        case SET_DAY_DIFF_CREDIT_NOTES:
            var ListData = [];
            if (action.payload > 30) {
                ListData = state.TableData.map((x) => ({
                    ...x,
                    itbisvalue: "0.00",
                    total: currencyFormat(Number(x.value.replaceAll(',', '')) - Number((x.discount.toString() ?? "0").replaceAll(',', '')))
                }))
            } else {
                ListData = state.TableData.map((x) => ({
                    ...x,
                    itbisvalue: x.Update == false ? currencyFormat(x.initialItbisValue) : currencyFormat((Number(x.value.replaceAll(',', '')) - Number((x.discount.toString() ?? "0").replaceAll(',', ''))) * ( x.hasItbis == true ? state.UltimateItbis : 0)),
                    total: currencyFormat(Number(x.value.replaceAll(',', ''))
                                            + (x.Update == false ? (x.initialItbisValue) : ((Number(x.value.replaceAll(',', '')) - Number((x.discount.toString() ?? "0").replaceAll(',', ''))) * ( x.hasItbis == true ? state.UltimateItbis : 0)))
                                            - Number((x.discount.toString() ?? "0").replaceAll(',', '')))
                }))
            }

            return {
                ...state,
                TableData: ListData,
                DayDiff: action.payload,
                percentItbis: action.payload > 30 ? 0 : state.UltimateItbis

            }
        default:
            return state;
    }

}



export default CreditNotes;