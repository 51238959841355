import {RESET_Table_Data_ChargesBank, SET_Table_Data_ChargesBank} from '../../../../constants/ActionTypes'
const InitialState={
    TableData:[]
}
function BankCharges (state=InitialState, action){
    switch (action.type) {
        case SET_Table_Data_ChargesBank:
            return {
                TableData:action.payload
            };
        case RESET_Table_Data_ChargesBank:
            return{
                TableData:[]
            }
        default:
          return state;
    }
}

export default BankCharges;