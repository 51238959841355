//export const BASE_URL="http://localhost:5000"
//export const BASE_URL="https://localhost:61507"
export const BASE_URL=`${window.location.origin}`
export const NotificationErrorTimeOut=10000;
export const NotificationSuccessTimeOut=10000;
export const NotificationWarningTimeOut=10000;
export const DebounceTimeOut=1000;
export const FormItemLayoutType1 = {

    labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
      labelAlign:'left'
};

export const FormItemLayoutType2 = {
  labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },//
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    },
    labelAlign:'right'
};


export const filterOptions=[
  {
    id: 1,
    name: 'Todos',
    icon: 'unordered-list'
  },
  {
    id: 2,
    name: 'Activos',
    icon: 'eye'
  },
  {
    id: 3,
    name: 'Inactivos',
    icon: 'eye-invisible'
  }
]


export const CLOSING_TYPE_ACCOUNTING_ID=1;
export const CLOSING_TYPE_DEPRECIATION_ID=2;

export const MIN_VALUE=0.01;
export const VOUCHER_RECORD_LENGTH=10;
export const CUSTOMER_LENGTH=8;

export const DATE_FORMAT="DD/MM/YYYY"

export const requiredRule=[
  {
    required:true,
    message:"Este campo es requerido."
  }
]


export const lenRule=(Length)=>([
  {
    len:Length,
    message:`Este campo debe contener una longitud de ${Length} caracteres.`
  }
])
export const emailRule=[ 
  {
      type: 'email',
      message: 'Debe introducir un correo Válido.',
  }
]

export const onlyNumberRule=[
  {
    pattern:'^[0-9]*$',
    message:"Este campo solo permite número."
  }
]

export const zeroMinRule=[
  ({ getFieldValue }) => ({
    validator(rule, value) {
      if (!value || value>0) {
        return Promise.resolve();
      }
      return Promise.reject('El valor debe ser mayor a cero');
    },
  })
]

export const ID = function () {
  return '_' + Math.random().toString(36).substr(2, 9);
};

export const UUID=()=> (
   ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (((c ^ crypto.getRandomValues(new Uint8Array(1))[0]) & 15) >> c / 4).toString(16)
  ))

export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const TIME_TO_UPDATE_TOKEN=25;

export const FirstPage="main/dashboard/crypto"

