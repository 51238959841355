import {GET_TABLE_DATA_ISR_RETAINED} from '../../../../constants/ActionTypes'
const InitialState={
    TableData:[]
}
function IsrRetained (state=InitialState, action){
    switch (action.type) {
        case GET_TABLE_DATA_ISR_RETAINED:

            let NewData=action.payload.map((data, index)=>(({
                ...data,
                description:`${data.isrRetentionType.description} (${data.isrRetentionType.percentage}%)`,
                account:data.catalogAccount.account
            })));

            return {

                TableData:NewData
            };
        default:
          return state;
    }
}

export default IsrRetained;