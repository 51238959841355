import {getRequest,putRequest} from './../../Axios';
import {SET_ACCOUNT_MOVEMENT_EDIT_VOUCHER,SET_LIST_ACCOUNT_MOVEMENT_TO_SELECT_EDIT_VOUCHER,ADD_ROW_MOVEMENTS_TABLE_EDIT_VOUCHER,UPDATE_ROW_MOVEMENTS_TABLE_EDIT_VOUCHER,DELETE_ROW_MOVEMENTS_TABLE_EDIT_VOUCHER,SET_LIST_ACCOUNT_MOVEMENT_EDIT_VOUCHER,RESET_EDIT_VOUCHER} from '../../../../constants/ActionTypes'
export const getAccountMovement=(Params,CallBackFunction)=>(dispatch)=>{

    dispatch(getRequest("/AccountMovement/GetAccountMovement",Params))
    .then(resp=>{
        const {payload:{data,status}}=resp;
        CallBackFunction(data,status);
    })
    .catch(error=>{
            console.error(error);
    })
}


export const Set_AccountMovementEditVoucher =(data)=>({
    type:SET_ACCOUNT_MOVEMENT_EDIT_VOUCHER,
    payload:{
        data
    }

})

export const Set_ListAccountMovementToSelectEditVoucher=(data)=>({
    type:SET_LIST_ACCOUNT_MOVEMENT_TO_SELECT_EDIT_VOUCHER,
    payload:{
        data
    }
})

export const addRowMovementEditVoucher=(data)=>{

    return{
        type:ADD_ROW_MOVEMENTS_TABLE_EDIT_VOUCHER,
        payload:data
    }
    
}
export const updateRowMovementEditVoucher=(data, index)=>({
    type:UPDATE_ROW_MOVEMENTS_TABLE_EDIT_VOUCHER,
    payload:{
        data,index
    }
})
export const removeRowMovementEditVoucher=(index)=>({
    type:DELETE_ROW_MOVEMENTS_TABLE_EDIT_VOUCHER,
    payload:index
})
export const GetListAccountMovementEditVoucher=(AccountMovementId)=>dispatch=>{
    dispatch(getRequest(`/AccountMovement/GetAccountMovementDetails`,{AccountMovementId}))
    .then(resp=>{
        const {payload:{data}}=resp;
        dispatch({
            type:SET_LIST_ACCOUNT_MOVEMENT_EDIT_VOUCHER,
            payload:{
                    TableMovement:data
             }
        })
    }).catch(err=>console.error(err));
}
export const resetEditVoucher=()=>({
    type:RESET_EDIT_VOUCHER
})

export const SaveEditVoucherRecord=(params,callBackFunction)=>(dispatch)=>{
   dispatch(putRequest(`/AccountMovement/UpdateAccountMovement/${params.AccountMovementId}`,{Concept:params.Concept},params.ListMovement))
   .then(resp=>{
        const {payload:{data,status}}=resp;
        callBackFunction(data,status);
   }).catch(error=>console.error(error));

}