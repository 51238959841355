import {postRequest,getRequest,putRequest} from '../../../actions/Axios'
import {GET_ALL_DYNAMIC_TAX,SET_SEARCH_DYNAMIC_TAX,SELECT_FILTER_OPTION_DYNAMIC_TAX,FILTER_DYNAMIC_TAX,SET_ADD_DYNAMIC_TAX_MODAL_OPEN,SET_ADD_DYNAMIC_TAX_MODAL_CLOSE,
        TOOGLE_DRAWER_DYNAMIC_TAX} from '../../../../constants/ActionTypes'

    export  const addDynamicTax=(params,body,CallBack)=>(dispatch)=>{
        dispatch(postRequest("/DynamicTaxInput",params,body))
        .then(resp=>{
            const {payload:{data,status}}=resp;
            CallBack(data,status)
        }).catch(error=>console.log(error));
    }   

    export  const updateDynamicTax=({Id},body,CallBack)=>(dispatch)=>{
    dispatch(putRequest(`/DynamicTaxInput/${Id}`,{},{ ...body,Id}))
    .then(resp=>{
        const {payload:{data,status}}=resp;
        CallBack(data,status)
    }).catch(error=>console.log(error));
    }

    export const GetDynamicTax=()=>(dispatch)=>{
        dispatch(getRequest("/DynamicTaxInput/GetAllDynamicTaxInput",{}))
        .then(resp=>{
            const {payload:{data}}=resp;
    
            dispatch({
                type:GET_ALL_DYNAMIC_TAX,
                payload:data
            })
        }).catch(error=>console.log(error));
    }

    export const setSearchDynamicTax=(seachText)=>{
        return{
            type:SET_SEARCH_DYNAMIC_TAX,
            payload:seachText
        }
    }
    

    export const selectOptionDynamicTax=(optionId)=>{  
        return{
            type:SELECT_FILTER_OPTION_DYNAMIC_TAX,
            payload:optionId
        }
    }

    export const filterDynamicTax=()=>{
        return {
            type:FILTER_DYNAMIC_TAX
        }
    }


    export const addDynamicTaxModalOpen =()=>({
        type:SET_ADD_DYNAMIC_TAX_MODAL_OPEN,
        payload:true
    })

    export const  addDynamicTaxModalClose =()=>({
            type:SET_ADD_DYNAMIC_TAX_MODAL_CLOSE,
            payload:false
    })

export const toogleDrawerDynamicTax=()=>({
    type:TOOGLE_DRAWER_DYNAMIC_TAX
})