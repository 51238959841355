import {SET_Table_Data,RESET_ASSETMOVEMENTTABLE} from '../../../../constants/ActionTypes'
const InitialState={
    AssetMovementList:[]
}
function AssetMovementData (state=InitialState, action){
    switch (action.type) {
        case SET_Table_Data:
            return {      
                ...state,
                AssetMovementList:action.payload
            };
        
        case RESET_ASSETMOVEMENTTABLE:
            return {
                ...state,
                AssetMovementList: []
            }
        default:
          return state;
    }
}

export default AssetMovementData;