import React from 'react'
import {Route,Switch} from 'react-router-dom'
import asyncComponent from "../../../util/asyncComponent";
const FC_Entry =({match})=>(
    <Switch>
        {/* { <Redirect exact from={`${match.url}/null`} to={`${match.url}/registerCatalog`}/> } */}
         <Route path={`${match.url}/null`} component={asyncComponent(()=>import( './FinancialStatement/index'))} />
          <Route path={`${match.url}/registerCatalog`} component={asyncComponent(()=>import( './RegisterCatalog/index'))} />
          <Route path={`${match.url}/financialStatement`} component={asyncComponent(()=>import( './FinancialStatement/index'))} />
          <Route path={`${match.url}/registerAuxiliary`} component={asyncComponent(()=>import('./RegisterAuxiliary/index'))}/>
          <Route path={`${match.url}/voucherRecord`} component={asyncComponent(()=>import('./VoucherRecord/index'))}/>
          <Route path={`${match.url}/editVoucher`} component={asyncComponent(()=>import('./EditVoucher/index'))}/>
    </Switch>
)

export default FC_Entry;