export const zeroPad=(num, places)=> {
    if (!num) return num;
    var zero = places - num.toString().length + 1;
    return Array(+(zero > 0 && zero)).join("0") + num;
}

export const  currencyFormat=(num)=> {
    if (num.constructor === String)
        num = num.replace(/[, ]/ig, "");

    return Number(roundNumber(roundNumber((num),3),2)).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
}


export function roundNumber(num, scale) {
    if(!("" + num).includes("e")) {
      return +(Math.round(num + "e+" + scale)  + "e-" + scale);
    } else {
      var arr = ("" + num).split("e");
      var sig = ""
      if(+arr[1] + scale > 0) {
        sig = "+";
      }
      return +(Math.round(+arr[0] + "e" + sig + (+arr[1] + scale)) + "e-" + scale);
    }
  }

export const GetPaymentToMoment=(FrequencyName)=>{
    switch (FrequencyName) {
        case 'DAILY':
            return { Name:'days', Quantity:1 }
        case 'WEEKLY':
            return { Name:'days', Quantity:7 } 
        case 'BIWEEKLY':
            return { Name:'days', Quantity:14 }
        case 'FORTNIGHTLY':
            return { Name:'days', Quantity:15 }
        case 'MONTHLY':
            return { Name:'months', Quantity:1 }
        case 'BIMONTHLY':
            return { Name:'months', Quantity:2 }
        case 'QUARTERLY':
            return { Name:'months', Quantity:3 }
        case 'SEMIANNUAL':
             return { Name:'months', Quantity:6 }
        case 'ANNUALLY':
            return { Name:'months', Quantity:12 }
        default:
            return { Name:'days', Quantity:1 }
    }
}

Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}