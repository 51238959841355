import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_FACEBOOK_USER,
  SIGNIN_FACEBOOK_USER_SUCCESS,
  SIGNIN_GITHUB_USER,
  SIGNIN_GITHUB_USER_SUCCESS,
  SIGNIN_GOOGLE_USER,
  SIGNIN_GOOGLE_USER_SUCCESS,
  SIGNIN_TWITTER_USER,
  SIGNIN_TWITTER_USER_SUCCESS,
  SIGNIN_USER,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER,
  SIGNUP_USER_SUCCESS,
  KEY_PASSWORD,
  SET_ENCRIPTION_COMPANY_GROUP_SELECTED,
  SET_ENCRIPTION_COMPANY_SELECTED,
  SET_ENCRIPTION_COMPANY_BRANCH_SELECTED,
  SET_ENCRIPTION_USER_SELECTED
} from "constants/ActionTypes";
import CryptoJS from 'crypto-js';
import { postRequest } from './Axios'


export const userSignUp = (user) => {
  return {
    type: SIGNUP_USER,
    payload: user
  };
};
export const userSignIn = (user) => {
  return {
    type: SIGNIN_USER,
    payload: user
  }
};
export const userSignOut = () => {
  console.log("DESLOGEADO")
  return {
    type: SIGNOUT_USER
  };
};
export const userSignUpSuccess = (authUser) => {
  return {
    type: SIGNUP_USER_SUCCESS,
    payload: authUser
  };
};

export const userSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_USER_SUCCESS,
    payload: authUser
  }
};
export const userSignOutSuccess = () => {
  return {
    type: SIGNOUT_USER_SUCCESS,
  }
};

export const showAuthMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};


export const userGoogleSignIn = () => {
  return {
    type: SIGNIN_GOOGLE_USER
  };
};
export const userGoogleSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_GOOGLE_USER_SUCCESS,
    payload: authUser
  };
};
export const userFacebookSignIn = () => {
  return {
    type: SIGNIN_FACEBOOK_USER
  };
};
export const userFacebookSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_FACEBOOK_USER_SUCCESS,
    payload: authUser
  };
};
export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};
export const userTwitterSignIn = () => {
  return {
    type: SIGNIN_TWITTER_USER
  };
};
export const userTwitterSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_TWITTER_USER_SUCCESS,
    payload: authUser
  };
};
export const userGithubSignIn = () => {
  return {
    type: SIGNIN_GITHUB_USER
  };
};
export const userGithubSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_GITHUB_USER_SUCCESS,
    payload: authUser
  };
};
export const showAuthLoader = () => {
  return {
    type: ON_SHOW_LOADER,
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};
export const hideAuthLoader = () => {
  return {
    type: ON_HIDE_LOADER,
  };
};

export const setEncriptionCompanyGroup = (companyGroup) => {
  const Data = CryptoJS.AES.encrypt(JSON.stringify(companyGroup), KEY_PASSWORD).toString();
  localStorage.setItem("companyGroup", Data);
  return {
    type: SET_ENCRIPTION_COMPANY_GROUP_SELECTED,
    payload: companyGroup
  }
}

export const setEncriptionCompany = (company) => {
  const Data = CryptoJS.AES.encrypt(JSON.stringify(company), KEY_PASSWORD).toString();
  localStorage.removeItem("company");
  localStorage.setItem("company", Data);
  return {
    type: SET_ENCRIPTION_COMPANY_SELECTED,
    payload: company
  }
}

export const setEncriptionBranch = (branch) => {
  const Data = CryptoJS.AES.encrypt(JSON.stringify(branch), KEY_PASSWORD).toString();
  localStorage.setItem("branch", Data);
  return {
    type: SET_ENCRIPTION_COMPANY_BRANCH_SELECTED,
    payload: branch
  }
}

export const setEncriptionUser = (user) => {
  const Data = CryptoJS.AES.encrypt(JSON.stringify(user), KEY_PASSWORD).toString();
  localStorage.setItem("user", Data);
  return {
    type: SET_ENCRIPTION_USER_SELECTED,
    payload: user
  }
}



export const UpdateToken = () => (dispatch) => {
  dispatch(postRequest(`/users/UpdateToken`, {}, {}))
    .then(resp => {
      const { payload: { data } } = resp;
      if (typeof data !== "undefined") {
        dispatch(userSignIn(data));
      }
    }).catch(err => {
      console.log(err);
    })
}