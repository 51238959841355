import {GET_DATA_TABLE_MEMBERSHIP_BILLING,CALCULATE_CURRENCY_TAX_MEMBERSHIP_BILLING,UPDATE_ROW_SELECTION_MEMBERSHIP_BILLING,GET_AVAILABLE_MEMBERSHIP_BILLING,RESET_MEMBERSHIP_BILLING,UPDATE_ALL_ROW_SELECTION_MEMBERSHIP_BILLING,SHOW_UPDATE_ROW_SELECTION_MEMBERSHIP_BILLING} from '../../../../constants/ActionTypes'
import moment from 'moment'
import { currencyFormat } from '../../../../util/DaFrameword'
const InitialState={
    ListDataTable:[],
    UltimateAvailableVoucher:[],
    AvailableVoucher:[],
    CurrencyTax:1
}
function MembershipBilling(state=InitialState,action){
    switch (action.type) {
        // case value:
            
        //     break;
        case GET_DATA_TABLE_MEMBERSHIP_BILLING:
          
            return {
                ...state,
                ListDataTable:action.payload.map(x=>({
                    ...x,
                    DateDisplay:moment(new Date(x.date)).format("DD/MM/YYYY"),

                    ItbisValueDisplay:  (x.currencyId != x.billingCurrencyId && x.billingCurrencyId !=null) ? currencyFormat(x.itbisValue * state.CurrencyTax) : currencyFormat(x.itbisValue) ,
                    ValueDisplay:( x.currencyId != x.billingCurrencyId && x.billingCurrencyId !=null) ? currencyFormat(x.value * state.CurrencyTax) : currencyFormat(x.value) ,
                    TotalValueDisplay: (x.currencyId != x.billingCurrencyId && x.billingCurrencyId !=null) ? currencyFormat(x.totalValue * state.CurrencyTax) : currencyFormat(x.totalValue) ,     
                    currencyAbbreviation:(x.currencyId != x.billingCurrencyId && x.billingCurrencyId !=null) ? x.billingCurrencyAbbreviation : x.currencyAbbreviation,
                    // ItbisValueDisplay:currencyFormat(x.itbisValue),
                    // ValueDisplay:currencyFormat(x.value),
                    // TotalValueDisplay:currencyFormat(x.totalValue),
                    UltimateItbisValue:(x.itbisValue),
                    UltimateValue:(x.value),
                    UltimateTotalValue:(x.totalValue),
                    Selected:false,
                    Status: -2,
                    Message: ""
                }))
            }
        case UPDATE_ROW_SELECTION_MEMBERSHIP_BILLING:
                const index=state.ListDataTable.findIndex(x=>x.membershipHeaderId==action.payload.membershipHeaderId);
                state.ListDataTable[index].Selected=!state.ListDataTable[index].Selected;

                var AVIndex= state.AvailableVoucher.findIndex(x=>action.payload.voucherTypeAbbreviation===x.abbreviation);//action.payload.filter(x=>state.ListDataTable.filter(z=>z.voucherTypeAbbreviation==x.abbreviation).length>0).map(x=>({...x,Used:0}));
               
                if(state.ListDataTable[index].Selected){
                     state.AvailableVoucher[AVIndex].Used++;
               }else{
                  state.AvailableVoucher[AVIndex].Used--;
               }
                return{
                    ...state,
                   // ListDataTable:last_ListData,
                }
        case GET_AVAILABLE_MEMBERSHIP_BILLING:
            var disponibles=action.payload.filter(x=>state.ListDataTable.filter(z=>z.voucherTypeAbbreviation===x.abbreviation).length>0).map(x=>({...x,Used:0}));
            return {
                ...state,
                AvailableVoucher:disponibles,
                UltimateAvailableVoucher:action.payload
            }
          case  RESET_MEMBERSHIP_BILLING :
              return InitialState;
        case UPDATE_ALL_ROW_SELECTION_MEMBERSHIP_BILLING:  
                
                var newData=state.ListDataTable.map((x)=>({
                    ...x,
                    Selected:action.payload
                }))
                return {
                    ...state,
                    ListDataTable:newData
                }
        case SHOW_UPDATE_ROW_SELECTION_MEMBERSHIP_BILLING:
                var resultMembership = action.payload;

                var list = state.ListDataTable.map(( x =>{

                   var Row = resultMembership.find(f => f.membershipHeaderId == x.membershipHeaderId);

                    return {
                        ...x,
                        Status: typeof Row ==="undefined"? -2:  Row.status,
                        Message:typeof Row ==="undefined"? "":  Row.message,
                    }
                }
                ));
                return {
                    ...state,
                    ListDataTable : list
                }
        case CALCULATE_CURRENCY_TAX_MEMBERSHIP_BILLING:
         //console.log("CALCULATE_CURRENCY_TAX_MEMBERSHIP_BILLING",action.payload)
        let currTas =action.payload;
         var datalist= state.ListDataTable.map((x)=>({
            ...x,
            ItbisValueDisplay:  (x.currencyId != x.billingCurrencyId && x.billingCurrencyId !=null) ? currencyFormat(x.UltimateItbisValue * currTas) : currencyFormat(x.UltimateItbisValue) ,
            ValueDisplay:( x.currencyId != x.billingCurrencyId && x.billingCurrencyId !=null) ? currencyFormat(x.UltimateValue * currTas) : currencyFormat(x.UltimateValue) ,
            TotalValueDisplay: (x.currencyId != x.billingCurrencyId && x.billingCurrencyId !=null) ? currencyFormat(x.UltimateTotalValue * currTas) : currencyFormat(x.UltimateTotalValue) ,
         }));
         //console.log(datalist);


        return{
             ...state,
             CurrencyTax:action.payload,
             ListDataTable:datalist
        }
        
        default:
            return state;
    }
}

export default MembershipBilling;