import {GET_ALL_MODULE_CLOSING,UPDATE_MONTH_MODULE_CLOSING} from '../../../../constants/ActionTypes'
const InitialState={
    ModulesClosingList:[]
}

function ModuleClosing(state=InitialState,action){
    switch (action.type) {
        case UPDATE_MONTH_MODULE_CLOSING:

            const Index=action.payload.Index;
            const Month=action.payload.Month.toLowerCase();
            const Value=action.payload.Value;
            var NewData=  state.ModulesClosingList;
            NewData[Index][Month]=Value;
            return {
                ...state,
                ModulesClosingList: NewData
            }
        case GET_ALL_MODULE_CLOSING:
            return{
                ...state,
                ModulesClosingList:action.payload
            }
        default:
            return state;
    }
}

export default ModuleClosing;