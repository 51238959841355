import { ADD_INVOICE_DETAIL_INVOICE_REGISTER, UPDATE_INVOICE_DETAIL_INVOICE_REGISTER, SET_ITBIS_TAX_INVOICE_REGISTER, DELETE_INVOICE_DETAIL_INVOICE_REGISTER, RESER_INVOICE_REGISTER } from '../../../../constants/ActionTypes'
import { ID } from '../../../../constants/Config'
const InitialState = {
    TableData: [],
    percentItbis: null,
    IdCurrentRow: ID()
}

function InvoiceRegister(state = InitialState, action) {
    switch (action.type) {
        case ADD_INVOICE_DETAIL_INVOICE_REGISTER:
            const NewRow = action.payload;
            console.log("Agregar", NewRow)
            return {
                ...state,
                TableData: [...state.TableData, NewRow],
                IdCurrentRow: ID()
            }
        case UPDATE_INVOICE_DETAIL_INVOICE_REGISTER:
            const UpdateRow = action.payload;
            return {
                ...state,
                TableData: state.TableData.map(x => x.id === UpdateRow.id ? UpdateRow : x)
            }
        case DELETE_INVOICE_DETAIL_INVOICE_REGISTER:
            const { productId } = action.payload;
            return {
                ...state,
                TableData: state.TableData.filter(x => x.productId !== productId)
            }
        case SET_ITBIS_TAX_INVOICE_REGISTER:
            return {
                ...state,
                percentItbis: action.payload.percentItbis * 0.01
            }

        case RESER_INVOICE_REGISTER:
            return {
                ...state,
                TableData: [],
                IdCurrentRow: ID()
            }
        default:
            return state;
    }

}

export default InvoiceRegister;