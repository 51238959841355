import {GET_TABLE_DATA_CHARGES_TYPE_ACCOUNT} from '../../../../constants/ActionTypes'
const InitialState={
    TableData:[]
}
function ChargesTypeAccount (state=InitialState, action){
    switch (action.type) {
        case GET_TABLE_DATA_CHARGES_TYPE_ACCOUNT:

            let NewData=action.payload.map((data, index)=>(({
                ...data,
                account:data.catalogAccount.account
            })));

            return {

                TableData:NewData
            };
        default:
          return state;
    }
}

export default ChargesTypeAccount;