import {postRequest,getRequest,putRequest} from '../../../actions/Axios'
import {GET_ALL_BOX_CATALOG_ACCOUNT,SET_SEARCH_BOX_CATALOG_ACCOUNT,SELECT_FILTER_OPTION_BOX_CATALOG_ACCOUNT,FILTER_BOX_CATALOG_ACCOUNT,
        SET_ADD_BOX_CATALOG_ACCOUNT_MODAL_OPEN,SET_ADD_BOX_CATALOG_ACCOUNT_MODAL_CLOSE,TOOGLE_DRAWER_BOX_CATALOG_ACCOUNT} from '../../../../constants/ActionTypes'

    export  const addBoxCatalogAccount=(params,body,CallBack)=>(dispatch)=>{
        dispatch(postRequest("/BoxCatalogAccount",params,body))
        .then(resp=>{
            const {payload:{data,status}}=resp;
            CallBack(data,status)
        }).catch(error=>console.log(error));
    }   

    export  const updateBoxCatalogAccount=({Id},body,CallBack)=>(dispatch)=>{
    dispatch(putRequest(`/BoxCatalogAccount/${Id}`,{},{ ...body,Id}))
    .then(resp=>{
        const {payload:{data,status}}=resp;
        CallBack(data,status)
    }).catch(error=>console.log(error));
    }

    export const GetBoxCatalogAccount=()=>(dispatch)=>{
        dispatch(getRequest("/BoxCatalogAccount/GetAllBoxCatalogAccount",{}))
        .then(resp=>{
            const {payload:{data}}=resp;
    
            dispatch({
                type:GET_ALL_BOX_CATALOG_ACCOUNT,
                payload:data
            })
        }).catch(error=>console.log(error));
    }

    export const setSearchBoxCatalogAccount=(seachText)=>{
        return{
            type:SET_SEARCH_BOX_CATALOG_ACCOUNT,
            payload:seachText
        }
    }
    

    export const selectOptionBoxCatalogAccount=(optionId)=>{  
        return{
            type:SELECT_FILTER_OPTION_BOX_CATALOG_ACCOUNT,
            payload:optionId
        }
    }

    export const filterBoxCatalogAccount=()=>{
        return {
            type:FILTER_BOX_CATALOG_ACCOUNT
        }
    }


    export const addBoxCatalogAccountModalOpen =()=>({
        type:SET_ADD_BOX_CATALOG_ACCOUNT_MODAL_OPEN,
        payload:true
    })

    export const  addBoxCatalogAccountModalClose =()=>({
            type:SET_ADD_BOX_CATALOG_ACCOUNT_MODAL_CLOSE,
            payload:false
    })

export const toogleDrawerBoxCatalogAccount=()=>({
    type:TOOGLE_DRAWER_BOX_CATALOG_ACCOUNT
})