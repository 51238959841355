import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import IntlMessages from "../../util/IntlMessages";
import { SetNavBarItems } from '../../appRedux/actions/Setting'
import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL
} from "../../constants/ThemeSetting";


const SubMenu = Menu.SubMenu;
// const MenuItemGroup = Menu.ItemGroup;

const HorizontalNav = () => {


  const { companyConsolidatedId, id: CompanyId ,allowUnify:IsUnify } = useSelector(({ auth }) => ({...auth.company,allowUnify:auth.companyGroup.allowUnify}));
  const navStyle = useSelector(({settings}) => settings.navStyle);
  //const pathname = useSelector(({ settings }) => settings.pathname);
  const pathname = useSelector(({common}) => common.pathname);
  const navBarItems = useSelector(({ settings }) => settings.NavBarItems);
  const dispatch = useDispatch();

  const getNavStyleSubMenuClass = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return "gx-menu-horizontal gx-submenu-popup-curve";
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-inside-submenu-popup-curve";
      case NAV_STYLE_BELOW_HEADER:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-below-submenu-popup-curve";
      case NAV_STYLE_ABOVE_HEADER:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-above-submenu-popup-curve";
      default:
        return "gx-menu-horizontal";

    }
  };

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split('/')[1];

  useEffect(() => {
    // if (navBarItems.length > 0)
    //   return;
    console.log()
    dispatch(SetNavBarItems());
  }, [])
  
  return (
    <Menu
      defaultOpenKeys={[defaultOpenKeys]}
      selectedKeys={[selectedKeys]}
      mode="horizontal"
      subMenuOpenDelay={0}
      subMenuCloseDelay={0.1}
    >
      {
        navBarItems.map((item, Mi) => {

          return (
            <SubMenu key={`Module${item.moduleId}`} className={getNavStyleSubMenuClass(navStyle)}
              title={item.moduleDisplay}>
              {
                item.imrTabsInterfaces.map((tab) => {

                  return (
                    <Menu.ItemGroup key={`Module${item.moduleId}Tab${tab.tabId}ItemGroup`}>
                      <Menu.Divider />
                      <Menu.Item key={`Module${item.moduleId}Tab${tab.tabId}Interface0`}>
                        <Link to="#"><b> <i className="icon icon-widgets" />
                          {tab.tabsDisplay}</b>
                        </Link>
                      </Menu.Item>
                      <Menu.Divider />
                      {
                        tab.moduleInterfaces.map((interfa, index) => {
                          return (
                            <Menu.Item  
                                        disabled={interfa.hasAccess===false}
                                        style={{ height: '30px'}} 
                                        key={`${interfa.url.replace("/","")}`}
                                       >
                              <Link style={{paddingLeft:"30px"}}
                                to={`${interfa.url}?I=${interfa.id}&T=${tab.tabId}&M=${item.moduleId}`}  >
                                {interfa.displayInterface}
                              </Link>
                            </Menu.Item>
                          )
                        })
                      }
                    </Menu.ItemGroup>
                  )
                })
              }
            </SubMenu>
          )
        })
      } 

    </Menu>

  );
};

HorizontalNav.propTypes = {};

export default HorizontalNav;

