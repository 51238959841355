import React from 'react'
import {Switch,Route} from 'react-router-dom'
import asyncComponent from "../../../util/asyncComponent";
const EntreCXC =({match})=>(
    <Switch>
          <Route  path={`${match.url}/CustomerRegister`} component={asyncComponent(()=>import( './CustomerRegister/index'))} />
          <Route  path={`${match.url}/IncomeReceipt`} component={asyncComponent(()=>import( './IncomeReceipt/index'))} />
          <Route  path={`${match.url}/CustomerMovements`} component={asyncComponent(()=>import( './CustomerMovements/index'))} />
          <Route  path={`${match.url}/CreditNotice`} component={asyncComponent(()=>import( './CreditNotice/index'))} />
    </Switch>
)
export default EntreCXC;