import { Get_AccountingDepartment_Register} from '../../../../constants/ActionTypes'
import {zeroPad,currencyFormat} from '../../../../util/DaFrameword'

const InitialValue={
    AccountingDepartmentRegisterList:[]
}
function AccountingDepartmentRegister(state=InitialValue,action){

    switch (action.type) {
        case Get_AccountingDepartment_Register:
            return {
                ...state,
                AccountingDepartmentRegisterList:action.payload.map(x=>({
                    code:x.code,
                    description:x.description,
                    purchase:x.purchase,
                    purchaseDiscount:x.purchaseDiscount,
                    purchaseITBIS:x.purchaseITBIS,
                    purchaseTransportation:x.purchaseTransportation,
                    purchaseRefound:x.purchaseRefound,
                    sell:x.sell,
                    sellDiscount:x.sellDiscount,
                    sellTransportation:x.sellTransportation,
                    sellRefound:x.sellRefound
                })) 
            }
        default:
           return state
    }  

}

export default AccountingDepartmentRegister;