import { GET_MOVEMENT_BY_ITEM_LIST,RESET_INVENTORYMOVEMENTTABLE} from '../../../../constants/ActionTypes'
import {zeroPad,currencyFormat} from '../../../../util/DaFrameword'
import moment from 'moment'

const InitialValue={
    EntryAndExitMovementByItemList:[]
}


function EntryAndExitMovementByItem(state=InitialValue,action){

    switch (action.type) {
        case GET_MOVEMENT_BY_ITEM_LIST:            
            return {
                ...state,
                EntryAndExitMovementByItemList:action.payload.map(x=>({
                    date:moment(x.date).format("DD/MM/YYYY"), 
                    voucher:x.voucher,
                    voucherNumber:x.voucherNumber===0?"":x.voucherNumber,
                    conduct:x.conduct,
                    entry:x.entry,
                    exit:x.exit,
                    branch:x.branch,
                    entry:x.entry,
                    exit:x.exit,
                    price:currencyFormat(x.price),
                    total:currencyFormat(x.total)
                })) 
            }
        case RESET_INVENTORYMOVEMENTTABLE:
            return{
                ...state,
                EntryAndExitMovementByItemList:[]
            }
        default:
           return state
    }  

}

export default EntryAndExitMovementByItem;