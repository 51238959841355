import { GET_ProductClassification_LIST_Register } from "../../../../constants/ActionTypes";
import { zeroPad } from "../../../../util/DaFrameword";


const InitialValue={
    ProductClassificationList:[]
}

function ProductClassificationRegister(state=InitialValue,action){

    switch (action.type) {
        case GET_ProductClassification_LIST_Register:
            return {
                ...state,
                    ProductClassificationList:action.payload.map(x=>({
                    Id:zeroPad(x.id,8),
                    description:x.description,
                    code:zeroPad(x.code,4),
                    active:x.active                
                })) 
            }
        default:
           return state
    }  

}

export default ProductClassificationRegister;