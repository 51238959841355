import React from 'react'
import Entry from './Entry'
import Report from './Report'
import {Switch,Route} from 'react-router-dom'
const AssetsModule=({match})=>(
        <Switch>
            <Route path={`${match.url}/Entry`} component={Entry}  /> 
            <Route path={`${match.url}/Report`} component={Report}  /> 
        </Switch>
)
export default AssetsModule;