import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_FACEBOOK_USER_SUCCESS,
  SIGNIN_GITHUB_USER_SUCCESS,
  SIGNIN_GOOGLE_USER_SUCCESS,
  SIGNIN_TWITTER_USER_SUCCESS,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER_SUCCESS,
  SET_ENCRIPTION_COMPANY_GROUP_SELECTED,
  KEY_PASSWORD,
  SET_ENCRIPTION_COMPANY_SELECTED,
  SET_ENCRIPTION_COMPANY_BRANCH_SELECTED,
  SET_ENCRIPTION_USER_SELECTED
} from "constants/ActionTypes";
import CryptoJS from 'crypto-js'


var StringCompanyGroup = (localStorage.getItem('companyGroup') !== null
  ? JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('companyGroup'), KEY_PASSWORD).toString(CryptoJS.enc.Utf8))
  : null)


var StringCompany = (localStorage.getItem('company') !== null
  ? JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('company'), KEY_PASSWORD).toString(CryptoJS.enc.Utf8))
  :null)

var StringBranch = (localStorage.getItem('branch') !== null
  ? JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('branch'), KEY_PASSWORD).toString(CryptoJS.enc.Utf8))
  :null)

var StringUser = (localStorage.getItem('user') !== null
  ? JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('user'), KEY_PASSWORD).toString(CryptoJS.enc.Utf8))
  :null)


const INIT_STATE = {
  loader: false,
  alertMessage: '',
  showMessage: false,
  initURL: '',
  authUser: localStorage.getItem('token'),
  companyGroup: StringCompanyGroup,
  company: StringCompany,
  branch: StringBranch,
  user:StringUser
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SIGNUP_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload
      }
    }
    case SIGNIN_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload
      }
    }
    case INIT_URL: {
      return {
        ...state,
        initURL: action.payload
      }
    }
    case SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        authUser: null,
        initURL: '/',
        loader: false
      }
    }

    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false
      }
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: '',
        showMessage: false,
        loader: false
      }
    }

    case SIGNIN_GOOGLE_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload
      }
    }
    case SIGNIN_FACEBOOK_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload
      }
    }
    case SIGNIN_TWITTER_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload
      }
    }
    case SIGNIN_GITHUB_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload
      }
    }
    case ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true
      }
    }
    case ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false
      }
    }
    case SET_ENCRIPTION_COMPANY_GROUP_SELECTED: {
      return {
        ...state,
        companyGroup: action.payload
      }
    }
    case SET_ENCRIPTION_COMPANY_SELECTED: {

      //console.log( action.payload,"SET_ENCRIPTION_COMPANY_SELECTED");
      return {
        ...state,
        company: action.payload
      }
    }
    case SET_ENCRIPTION_COMPANY_BRANCH_SELECTED: {
      return {
        ...state,
        branch: action.payload
      }
    }
    case SET_ENCRIPTION_USER_SELECTED: {
      return {
        ...state,
        user: action.payload
      }
    }
    default:
      return state;
  }
}
