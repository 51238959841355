import React,{useEffect,useState} from 'react';
import {postRequest} from '../../appRedux/actions/Axios';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { GetCompany } from  '../../appRedux/actions/Selector/Company'
import {Col,Row} from 'antd'
import CardSelector from './CardSelector'
import {userSignIn} from '../../appRedux/actions/Auth'
import CircularProgress from "components/CircularProgress/index";
import LoginHeader from "../Topbar/LoginHeader/index";
import {setEncriptionCompany} from '../../appRedux/actions/Auth';

const CompanySelector=({location})=>{
    const dispatch =useDispatch()
    const IsMounting =React.useRef(true)
    const history = useHistory();
    const {loader,authUser}= useSelector(({auth}) => auth);
    const {companyList}= useSelector(({Company}) => Company);
    const [SearchCritery, setSearchCritery] = React.useState('');
    const [currentTab, setCurrentTab] = useState("1");

   useEffect(()=>{
       if(!authUser){
            history.push('/'); 
            return
       }
       
       if(IsMounting.current){
        IsMounting.current=false;
       //Mount
         dispatch(GetCompany({CompanyGroupId:location.state.CompanyGroup}))
      }else{
       //Update
           if(companyList.length===1){
               HandleSelectCompany(companyList[0]);
           }
      }
  },[loader,companyList])
   const HandleSelectCompany=(company)=>{
       //Seleccionar Cuando hay mas de 1
            dispatch(setEncriptionCompany(companyList.find(x=>x.id===company.id)));
        dispatch(postRequest(`/users/SelectCompany`,{Company_ID:company.id},{}))
        .then(resp=>{
            const {payload:{data}}= resp;
            if(typeof data!=="undefined"){
                dispatch(userSignIn(data));
                history.push({
                    pathname:`/branchSelector`,
                    state:{Company_ID:company.id}
                });
            }
        }).catch(err=>{
            console.log(err);
        })
   }

   const handleTabChange = (tabKey) => {
    setCurrentTab(tabKey);
  };

  function filtrarCompany(CRITERIO){
    setSearchCritery(CRITERIO);
  }

    return (
        <div>
          <LoginHeader Titulo="Compañias" OnFilter={filtrarCompany} OnTab={handleTabChange}/>
            <div className="gx-main-content gx-mt-5 gx-mr-4 gx-ml-4">
            <div className="gx-app-module gx-chat-module">
          <div className="gx-chat-module-box">
            <Col span={24} >
              <Row> 
                {companyList
                .filter(company => {
                 const companyName = company.displayCompany.toUpperCase().includes(SearchCritery.toUpperCase());
                  switch (currentTab) {
                 case "1":
                   return companyName
                 case "2":
                   return companyName && (company.isLocal === true);
                 case "3":
                   return companyName && (company.isLocal === false);
                 default:
                   return companyName;
                }
                }).map((company) => (
                   <Col key={company.id} xl={4} lg={4} md={12} sm={24} xs={24} style={{ marginBottom: '5px' }}>
                     <CardSelector Entity={company} SelectEntity={HandleSelectCompany} type={1} />
                   </Col>
                   ))}
              </Row>
                    {loader ?
                        <div className="gx-loader-view" style={{marginTop: '-25%' }}>
                            <CircularProgress/>
                        </div> : ''
                    }
             </Col>
          </div>
         </div>
        </div>            
       </div>
    )
}
export default CompanySelector;