import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Col, Row, Button } from 'antd'
import { postRequest } from '../../appRedux/actions/Axios'
import { GetBranches } from '../../appRedux/actions/Selector/Branch'
import { userSignIn } from '../../appRedux/actions/Auth'
import LoginHeader from "../Topbar/LoginHeader/index";
import IconWithTextCard from "components/Metrics/IconWithTextCard";
import { setEncriptionBranch } from '../../appRedux/actions/Auth'
import { SetNavBarItems } from '../../appRedux/actions';

const BranchSelector = ({ location }) => {
    const dispatch = useDispatch()
    const IsMounting = React.useRef(true)
    const history = useHistory();
    const { branchList } = useSelector(({ Branch }) => Branch);
    const { authUser } = useSelector(({ auth }) => auth);

    useEffect(() => {
        if (!authUser) {
            history.push('/');
            return
        }
        if (IsMounting.current) {
            IsMounting.current = false;
            dispatch(GetBranches({ CompanyId: location.state.Company_ID }))
        } else {
            if (branchList.length === 1) {
                HandleSelectBranch(branchList[0]);
            }
        }
    }, [branchList]);

    const HandleSelectBranch = (branch) => {
        /*Seleccionar cuando hay mas de 1*/
        dispatch(setEncriptionBranch(branchList.find(x => x.id === branch.id)));
        dispatch(postRequest(`/users/SelectBranch`, { Branch_ID: branch.id }, {}))
            .then(resp => {
                const { payload: { data } } = resp;
                if (typeof data !== "undefined") {
                    dispatch(SetNavBarItems());
                    dispatch(userSignIn(data));
                    history.push(`/main/dashboard/crypto`);
                }
            }).catch(err => {
                console.log(err);
            })
    }

    return (
        <div>
            <LoginHeader Titulo="Sucursales" />
            <div className="gx-main-content gx-text-center gx-mt-3">
                <Row>

                    {
                        branchList.map((branch) => {
                            return (
                                <Col key={branch.id} xl={6} lg={12} md={12} sm={24} xs={24} >
                                    <Button className="gx-text-left" onClick={HandleSelectBranch.bind(this, branch)} style={{ height: "100%", margin: '0.5rem', padding: 0 }} block>
                                        <IconWithTextCard icon="company" iconColor="geekblue" title={`${branch.displayBranch}`} subTitle={`Pertenece a ${branch.displayCompany}`} />
                                    </Button>
                                </Col>
                            )
                        })
                    }

                </Row>
            </div>
        </div>
    )
}
export default BranchSelector;