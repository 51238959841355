import {
    ADD_INVOICE_DETAIL_PROOF_OF_PURCHASE,
    UPDATE_INVOICE_DETAIL_PROOF_OF_PURCHASE,
    SET_ITBIS_TAX_PROOF_OF_PURCHASE,
    DELETE_INVOICE_DETAIL_PROOF_OF_PURCHASE,
    RESET_PROOF_OF_PURCHASE,
    SET_DYNAMIC_TAX_INPUT_PROOF_OF_PURCHASE,
    SET_DYNAMIC_INPUTS_VALUE_PROOF_OF_PURCHASE,
    SET_ISR_PERCENT_PROOF_OF_PURCHASE
} from '../../../../constants/ActionTypes'
// import { currencyFormat } from '../../../../util/DaFrameword'
import { ID } from '../../../../constants/Config'
const InitialState = {
    TableData: [],
    percentItbis: null,
    ISRRetentionPercent:0,
    itbisRetentionPercent:(100*0.01),
    IdCurrentRow: ID(),
    DynamicTaxInputs:[],
    DynamicInputValue:[]
}
function ProofOfPurchase(state = InitialState, action) {
    switch (action.type) {
        case ADD_INVOICE_DETAIL_PROOF_OF_PURCHASE:
            const NewRow = action.payload;
            console.log("Agregar", NewRow)
            return {
                ...state,
                TableData: [...state.TableData, NewRow],
                IdCurrentRow: ID()
            }
        case UPDATE_INVOICE_DETAIL_PROOF_OF_PURCHASE:
            const UpdateRow = action.payload;
            return {
                ...state,
                TableData: state.TableData.map(x => x.id === UpdateRow.id ? UpdateRow : x)
            }
        case DELETE_INVOICE_DETAIL_PROOF_OF_PURCHASE:
            const { productId } = action.payload;
            return {
                ...state,
                TableData: state.TableData.filter(x => x.productId !== productId)
            }
        case SET_ITBIS_TAX_PROOF_OF_PURCHASE:
            return {
                ...state,
                percentItbis: action.payload.percentItbis * 0.01
            }

        case RESET_PROOF_OF_PURCHASE:
            return {
                ...state,
                TableData: [],
                IdCurrentRow: ID(),
                DynamicInputValue:[],
                ISRRetentionPercent:0
            }
        case SET_DYNAMIC_TAX_INPUT_PROOF_OF_PURCHASE:
            return {
                ...state,
                DynamicTaxInputs:action.payload
            }
        case SET_DYNAMIC_INPUTS_VALUE_PROOF_OF_PURCHASE:
            return{
                ...state,
                DynamicInputValue:action.payload
            }
        case SET_ISR_PERCENT_PROOF_OF_PURCHASE:
            return {
                ...state,
                ISRRetentionPercent: action.payload*0.01
            }
        default:
            return state;
    }
}

export default ProofOfPurchase;