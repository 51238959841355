import React from 'react'
import {Route,Switch} from 'react-router-dom'
import asyncComponent from "../../../util/asyncComponent";
const FAC_Entry =({match})=>(
    <Switch>
          <Route  path={`${match.url}/ProductRegister`} component={asyncComponent(()=>import( './ProductRegister/index'))} />
          <Route  path={`${match.url}/InvoiceRegister`} component={asyncComponent(()=>import( './InvoiceRegister/index'))} />
          <Route  path={`${match.url}/MembershipRegister`} component={asyncComponent(()=>import( './MembershipRegister/index'))} />
          <Route  path={`${match.url}/BudgetRegister`} component={asyncComponent(()=>import( './BudgetRegister/index'))} />
          <Route  path={`${match.url}/BudgetClosing`} component={asyncComponent(()=>import( './BudgetClosing/index'))} />
          <Route  path={`${match.url}/MembershipBilling`} component={asyncComponent(()=>import( './MembershipBilling/index'))} />
          <Route  path={`${match.url}/PrintInvoice`} component={asyncComponent(()=>import( './PrintInvoice/index'))} />
          <Route  path={`${match.url}/CreditNotes`} component={asyncComponent(()=>import( './CreditNotes/index'))} />
    </Switch>
)

export default FAC_Entry;