import {DISABLE_HEADER_PROVIDER_PAYMENT,SET_PENDING_MOVEMENT_PROVIDER_PAYMENT,SET_VALUE_TO_DISTRIBUTE_PROVIDER_PAYMENT,UPDATE_TABLE_DATA_PROVIDER_PAYMENT,SET_RETAINED_PERCENT_AND_STATUS_OUTCOME_PAYMENT,UPDATE_VALUE_TO_DISTRIBUTE_PROVIDER_PAYMENT,RESET_PROVIDER_PAYMENT,PENDING_BALANCE_PROVIDER_PAYMENT} from '../../../../constants/ActionTypes'
import moment from 'moment'
import {zeroPad,currencyFormat} from '../../../../util/DaFrameword'
const InitialState={
    DisabledHeader:false,
    PendingMovement:[],
    DistributeValue:0,
    UltimateDistributeValue:0,
    PendingBalance:0,
    AutomaticRetainedITBIS:false,
    PercentRetainedITBIS :undefined,
    AutomaticRetainedISR:false,
    PercentRetainedISR :undefined
}

function ProviderPayment(state=InitialState, action){

        switch (action.type) {

            case DISABLE_HEADER_PROVIDER_PAYMENT:
                return {
                    ...state,
                    DisabledHeader:true
                }
            case SET_PENDING_MOVEMENT_PROVIDER_PAYMENT:
                return {
                    ...state,
                    PendingMovement:action.payload.map(x=>({
                        ...x, 
                        DateDisplay:moment(x.date).format("DD/MM/YYYY"),
                        TypeDisplay:`${x.abbreviationCode}`,
                        DocumentDisplay:zeroPad(x.number,x.length),
                        PendingValueDisplay:currencyFormat(x.pendingDebit),
                        Selected:false,
                        PaidValue:0,
                        ISRRetainedValue:0,
                        ITBISRetainedValue:0,
                        Concept:""
                    }))
                }
            case SET_VALUE_TO_DISTRIBUTE_PROVIDER_PAYMENT:
                
                return {
                    ...state,
                    DistributeValue:action.payload,
                    UltimateDistributeValue:action.payload,
                }
                
            case SET_RETAINED_PERCENT_AND_STATUS_OUTCOME_PAYMENT:
               const q= action.payload
            return {
                ...state,
                AutomaticRetainedITBIS: action.payload.AutomaticRetainedITBIS,
                PercentRetainedITBIS  : action.payload.RetencionPercentITBIS===undefined?0:action.payload.RetencionPercentITBIS,
                AutomaticRetainedISR: action.payload.AutomaticRetainedISR,
                PercentRetainedISR  : action.payload.RetencionPercentISR===undefined?0:action.payload.RetencionPercentISR

            }
        
            case UPDATE_TABLE_DATA_PROVIDER_PAYMENT:
                const Row=action.payload;
                return {
                    ...state,
                    PendingMovement:state.PendingMovement.map(x=>(x.cxpTransactionHeaderId===Row.cxpTransactionHeaderId)?Row:x)
                }
            case UPDATE_VALUE_TO_DISTRIBUTE_PROVIDER_PAYMENT:
                const PaidValue=state.PendingMovement.reduce((prev,curr)=> prev+(curr.PaidValue),0);
                //console.log(PaidValue,state.UltimateDistributeValue,Math.round(PaidValue,4));
                //Antes hacia un redondeo al restar PaidValue
                return {
                    ...state,
                    DistributeValue:(state.UltimateDistributeValue- PaidValue)
                }
            
            case PENDING_BALANCE_PROVIDER_PAYMENT:
                return {
                    ...state,
                    PendingBalance:action.payload
                }
            case RESET_PROVIDER_PAYMENT: 
            return  {
                ...InitialState
            }
        
            default:
                return state;      
      }
}

export default ProviderPayment;
