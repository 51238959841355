import {GET_CUSTOMER_MOVEMENT_CUSTOMER_MOVEMENT,GET_BALANCE_AGE_CUSTOMER_MOVEMENT,RESET_CUSTOMER_MOVEMENT} from '../../../../constants/ActionTypes'
import {zeroPad,currencyFormat} from '../../../../util/DaFrameword'
import moment from 'moment'

var initialState = {
    TableData:[],
    Data:[],
    footerBalance:{
        value1:0,
        value2:0,
        value3:0,
        value4:0,
        display1 :"Balance 1",
        display2 :"Balance 2",
        display3 :"Balance 3",
        display4 :"Balance 4",
    }
}

function CustomerMovement (state=initialState,action){

    switch (action.type) {
        
        case GET_CUSTOMER_MOVEMENT_CUSTOMER_MOVEMENT:

            const Request=action.payload.data;
            var UltimateData=[];
            const OnlyPending=action.payload.OnlyPending;

            console.log(action.payload,"CONSU")

            Request.forEach((element) => {
            var Balance=element.cxcTransactionDetailValue;

            UltimateData.push({
                Type: element.abbreviationCode,
                Document: zeroPad(element.number,element.length),
                Date: moment (new Date(element.date)).format('DD/MM/YYYY'),
                Debit: element.cxcTransactionDetailValue,
                DebitDisplay:currencyFormat(element.cxcTransactionDetailValue),
                Credit: 0.00,
                CreditDisplay:"",
                BalanceDisplay:currencyFormat((OnlyPending)?element.pendindDebit :element.cxcTransactionDetailValue),
                Balance:element.cxcTransactionDetailValue,
                Concept: element.concept
            })

                if (element.pagos.length>0 && !OnlyPending){
                    element.pagos.forEach((pago)=>{
                        Balance=Balance-pago.cxcDetailValue;
                        UltimateData.push({
                            Type: pago.abbreviationCode,
                            Document: zeroPad(pago.number,pago.length),
                            Date: moment (new Date(pago.date)).format('DD/MM/YYYY'),
                            Debit: 0.00,
                            DebitDisplay:"",
                            Credit:pago.cxcDetailValue,
                            CreditDisplay:currencyFormat(pago.cxcDetailValue),
                            BalanceDisplay:currencyFormat(Balance),
                            Balance:Balance,
                            Concept: pago.detailconcept
                        })
                    })
                }
            });

            return{
                ...state,
                Data:action.payload,
                TableData:UltimateData,
                footerBalance:action.payload.footerBalance,
            }
            case GET_BALANCE_AGE_CUSTOMER_MOVEMENT:
                var Balance=action.payload
                return {
                    ...state,
                    footerBalance:{
                        
                        ...state.footerBalance,
                        display1:`Balance (${Balance.beginDay1} - ${Balance.endDay1})`,
                        display2:`Balance (${Balance.beginDay2} - ${Balance.endDay2})`,
                        display3:`Balance (${Balance.beginDay3} - ${Balance.endDay3})`,
                        display4:`Balance (Más de ${Balance.endDay3})`,

                    }
                }
            
            case RESET_CUSTOMER_MOVEMENT:
                return{
                    ...initialState,
                    footerBalance:{
                        ...state.footerBalance,
                        value1:0,
                        value2:0,
                        value3:0,
                        value4:0
                    }
                }
        default:
            return state;
    }
}

export default CustomerMovement;