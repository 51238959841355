import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { GetCompanyGroup } from '../../appRedux/actions/Selector/CompanyGroup'
// import { Col, Row } from 'antd'
// import CircularProgress from "components/CircularProgress/index";
import { Avatar, Button, Form } from "antd";
// import IntlMessages from "util/IntlMessages";
import SelectDA from '../../components/Select'
import LoginHeader from "../Topbar/LoginHeader/index";
import { setEncriptionCompanyGroup } from '../../appRedux/actions/Auth'
import BackGround from '../../assets/images/b.png'
import UserImage from '../../assets/images/icono.png'
import { Redirect } from 'react-router-dom'

const FormItem = Form.Item;
const CompanyGroup = ({ location }) => {
    const dispatch = useDispatch()
    const history = useHistory();
    const IsMounting = React.useRef(true)

    const { loader, authUser, user } = useSelector(({ auth }) => auth);
    const { CompanyGroups } = useSelector(({ CompanyGroup }) => CompanyGroup);

    const [form] = Form.useForm()

    useEffect(() => {
        if (!authUser) {
            history.push('/');
            return
        }

        if (IsMounting.current) {
            //Mount
            IsMounting.current = false;
            dispatch(GetCompanyGroup())
        } else {
            //Update
            if (CompanyGroups.length === 1) {
                OnSubmitForm({ CompanyGroup: CompanyGroups[0].id })
            }else if(CompanyGroups.length === 0){
                history.push('/');
                return
            }
        }

    }, [loader, CompanyGroups,authUser])

    const OnSubmitForm = (form) => {

        dispatch(setEncriptionCompanyGroup(CompanyGroups.find(x => x.id === form.CompanyGroup)));

        history.push({
            pathname: `/companySelector`,
            state: { CompanyGroup: form.CompanyGroup }
        });
    }
    var Styles = {
        backgroundImage: `url(${BackGround})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
    }

    return (
        <>
            <LoginHeader />
            {
                user != null
                    ? (
                        <div className="gx-login-container" style={Styles}>
                            <div className="gx-login-content gx-text-center">
                                <h2 className="gx-mb-4"> Bienvenido/a </h2>
                                <div className="gx-login-header">
                                    <Avatar shape="circle" className="gx-size-100" src={UserImage} />
                                </div>
                                {user == null &&
                                    <div className="gx-mb-4">
                                        <h3>{`${!user.firstName || ""} ${!user.lastName || ""}`}</h3>
                                        <h4>({user.email})</h4>
                                        <p>Seleccione un grupo y presione en Acceder</p>
                                    </div>
                                }

                                <Form form={form} onFinish={OnSubmitForm} className="gx-login-form gx-form-row0">
                                    <FormItem
                                        name="CompanyGroup"
                                        rules={[{ required: true, message: 'Esta campo es requerido.' }]}>
                                        <SelectDA toShow={["", "", "display"]} data={CompanyGroups} Size="large" />
                                    </FormItem>
                                    <FormItem>
                                        <Button className="DaColor" htmlType="submit">
                                            Acceder
                            </Button>
                                    </FormItem>
                                </Form>

                                <div className="gx-mt-3 gx-text-center">
                                    <h5 className="gx-mb-0"  >Ultimo Acceso: <small>  19/02/2021 11:26:57 AM  </small></h5>

                                    <Button className="gx-p-0 gx-m-0" type="link" size="small" >
                                        Propagas / Confecciona Comprobante
                        </Button>
                                </div>
                            </div>
                        </div>)
                    : <Redirect
                        to={{
                            pathname: '/signin',
                            state: { from: location }
                        }} />
            }

        </>

    )
}
export default CompanyGroup;