import React from 'react'
import {Switch,Route} from 'react-router-dom'
import asyncComponent from "../../../util/asyncComponent";
const EntryBank =({match})=>(
    <Switch>
          <Route  path={`${match.url}/BankAccount`} component={asyncComponent(()=>import( './BankAccount/index'))} />
          <Route  path={`${match.url}/BankCharges`} component={asyncComponent(()=>import( './BankCharges/index'))} />
          <Route  path={`${match.url}/ChargesType`} component={asyncComponent(()=>import( './ChargesType/index'))} />
    </Switch>
)
export default EntryBank;