import { GET_BRAND_LIST_Register } from "../../../../constants/ActionTypes";
import { zeroPad } from "../../../../util/DaFrameword";


const InitialValue={
    BrandList:[]
}

function BrandRegister(state=InitialValue,action){

    switch (action.type) {
        case GET_BRAND_LIST_Register:
            return {
                ...state,
                    BrandList:action.payload.map(x=>({
                    Id:zeroPad(x.id,8),
                    description:x.description,
                    active:x.active                
                })) 
            }
        default:
           return state
    }  

}

export default BrandRegister;