import React from 'react'
import Entry from './Entry'
import Consult from './Consult'
import Report from './Report'
import ClosingAndOthers from './ClosingAndOthers'
import {Switch,Route} from 'react-router-dom'
const CXPModule=({match})=>(
        <Switch>
            <Route path={`${match.url}/Entry`} component={Entry}  /> 
            <Route path={`${match.url}/Consult`} component={Consult}  /> 
            <Route path={`${match.url}/Report`} component={Report}  /> 
            <Route path={`${match.url}/ClosingAndOthers`} component={ClosingAndOthers}  /> 
        </Switch>
)
export default CXPModule;