import {postRequest,getRequest,putRequest} from '../../../actions/Axios'
import {GET_CONTAB_ACTIVE_REFERENCES,GET_CONTAB_ACTIVE_ACTION,GET_DOCUMENT_INTERFACE,GET_ALL_ACTION_PROGRAM_ACCOUNT_DATA,TOOGLE_DRAWER_CONTAB_ACTION_PROGRAM_ACCOUNT,
    SET_ADD_ACTION_PROGRAM_ACCOUNT_MODAL_OPEN,SET_ADD_ACTION_PROGRAM_ACCOUNT_MODAL_CLOSE,SET_SEARCH_APC,FILTER_ACTION_PROGRAM_ACCOUNT,SELECT_FILTER_OPTION_ACTION_PROGRAM_ACCOUNT,
    ADD_Actions_Assigned,UPDATE_Actions_Assigned,Reset_Actions_Assigned,ADD_ACTION_ACCOUNT,RESET_ACTION_ACCOUNT,OPEN_CLOSE_ACTION_PROGRAM_ACCOUNT
    } from '../../../../constants/ActionTypes'

    export const getContabReferences=()=>(dispatch)=>{
        dispatch(getRequest(`/ContabReference`,{}))
        .then(resp=>{
            const {payload:{data}}=resp;
            dispatch({
                type:GET_CONTAB_ACTIVE_REFERENCES,
                payload:data
            })
        })
        .catch(err=>console.log(err))
    }

    export const getContabAction=()=>(dispatch)=>{
        dispatch(getRequest(`/ContabAction`,{}))
        .then(resp=>{
            const {payload:{data}}=resp;
            dispatch({
                type:GET_CONTAB_ACTIVE_ACTION,
                payload:data
            })
        })
        .catch(err=>console.log(err))
    }


    export const getDocumentInterface=()=>(dispatch)=>{
        dispatch(getRequest(`/ActionProgramAccount/GetDocumentInterface`,{}))
        .then(resp=>{
            const {payload:{data}}=resp;
            dispatch({
                type:GET_DOCUMENT_INTERFACE,
                payload:data
            })
        })
        .catch(err=>console.log(err))
    }



    export  const addActionProgramAccount=(params,body,CallBack)=>(dispatch)=>{
        dispatch(postRequest("/ActionProgramAccount",params,body))
        .then(resp=>{
            const {payload:{data,status}}=resp;
            CallBack(data,status)
        }).catch(error=>console.log(error));
    }   

    export  const updateActionProgramAccount=({Id},body,CallBack)=>(dispatch)=>{
    dispatch(putRequest(`/ActionProgramAccount/${Id}`,{},{ ...body,Id}))
    .then(resp=>{
        const {payload:{data,status}}=resp;
        CallBack(data,status)
    }).catch(error=>console.log(error));
    }



    export const GetAllActionProgramAccount=()=>(dispatch)=>{

        dispatch(getRequest("/ActionProgramAccount/GetAll",{}))
        .then(resp=>{
            const {payload:{data}}=resp;
    
            dispatch({
                type:GET_ALL_ACTION_PROGRAM_ACCOUNT_DATA,
                payload:data
            })
        }).catch(error=>console.log(error));
    }

    export const GetAllActionProgramAccountNewApproach=()=>(dispatch)=>{
        dispatch(getRequest("/ActionProgramAccount/GetDataAllNewApproach",{}))
        .then(resp=>{
            const {payload:{data}}=resp;
    
            dispatch({
                type:GET_ALL_ACTION_PROGRAM_ACCOUNT_DATA,
                payload:data
            })
        }).catch(error=>console.log(error));
    }

    export const GetActionProgramAssignedVisualization = (moduleInterfaceId,DocumentEmissionId,currencyId,CallBack) => async (dispatch) => {
        return await dispatch(getRequest(`/ActionProgramCatalogAccount/GetActionProgramAssignedVisualization`,{moduleInterfaceId,DocumentEmissionId,currencyId}))
            .then(({ payload: { data, status } }) => ({data,status}))
            .catch((error) => console.error(error))
    }

    export const GetActionProgramAssigned=(moduleInterfaceId,DocumentEmissionId,currencyId)=>(dispatch)=>{
        dispatch(getRequest("/ActionProgramCatalogAccount/GetActionProgramAssigned",{moduleInterfaceId,DocumentEmissionId,currencyId}))
        .then(resp=>{
            const {payload:{data}}=resp;
    
            dispatch({
                type:ADD_Actions_Assigned,
                payload:data
            })
        }).catch(error=>console.log(error));
    }

    export const AddActionProgramCatalogAccount = (pmActionCatalog) => async (dispatch) => {    
        return await dispatch(postRequest("/ActionProgramCatalogAccount/AddActionProgramCatalogAccount", {},pmActionCatalog))
             .then(({ payload: { data, status } }) => ({ data, status }))
              .catch(() => null);
    }

    export const UpdateActionProgramCatalogAccount = (pmActionCatalog) => async (dispatch) => {
        return await dispatch(putRequest(`/ActionProgramCatalogAccount/UpdateActionProgramCatalogAccount`, {}, pmActionCatalog))
            .then(({ payload: { data, status } }) => ({ data, status }))
            .catch(() => null);
    }

    export const ActionProgramAccount=(ActionAccount)=>{
        return{
            type:ADD_ACTION_ACCOUNT,
            payload:ActionAccount
        }
    }
    

   /*export const UpdateActionProgramCatalogAccount = (pmActionCatalog) => async (dispatch) => {    
        return await dispatch(postRequest("/ActionProgramCatalogAccount/UpdateActionProgramCatalogAccount", {},pmActionCatalog))
             .then(({ payload: { data, status } }) => ({ data, status }))
              .catch(() => null);
    }*/
    

    export const ResetActionsAssigned=()=>({
        type:RESET_ACTION_ACCOUNT
    })


    
    export const  ActionProgramAccountModalOpenAndClose =(data)=>({
        
        type:OPEN_CLOSE_ACTION_PROGRAM_ACCOUNT,
        payload:data
        
    })

    export const  addActionProgramAccountModalOpen =()=>({
        type:SET_ADD_ACTION_PROGRAM_ACCOUNT_MODAL_OPEN,
        payload:true
    })

    export const  addActionProgramAccountModalClose =()=>({
            type:SET_ADD_ACTION_PROGRAM_ACCOUNT_MODAL_CLOSE,
            payload:false
    })

    export const setSearchAPC=(seachText)=>{
        return{
            type:SET_SEARCH_APC,
            payload:seachText
        }
    }

    export const filterAPCData=()=>{
        return {
            type:FILTER_ACTION_PROGRAM_ACCOUNT
        }
    }

    export const selectOptionAPC=(optionId)=>{  

        return{
            type:SELECT_FILTER_OPTION_ACTION_PROGRAM_ACCOUNT,
            payload:optionId
        }
    }
    export const toogleDrawerAPC= ()=>(
        {
            type:TOOGLE_DRAWER_CONTAB_ACTION_PROGRAM_ACCOUNT,
        }
    )



