import {GET_SUMMARY_CHECK_BALANCE,GET_SUMMARY_CHECK_BALANCE_ANNUAL,GET_SUMMARYDEPRECIATION_CHECK_BALANCE} from '../../../../constants/ActionTypes'
const InitialState={
  Summary:{
      A:0,
      PC:0,
      CG:0,
      I:0,
      ZeroTest:0
  },
  AnnualSummary:{
    AA:0,
    APC:0,
    ACG:0,
    AI:0,
    AZeroTest:0
    },

  SummaryDepreciation:[]
}

function  MonthlyClosing(state=InitialState,action){
    switch (action.type) {
       case GET_SUMMARY_CHECK_BALANCE:

        const {a,pc,cg,i,zeroTest} =action.payload
           return{
            ...state,
            A:a,
            PC:pc,
            CG:cg,
            I:i,
            ZeroTest:zeroTest,
           };

        case GET_SUMMARY_CHECK_BALANCE_ANNUAL:

              return{
               ...state,
               AA:action.payload.a,
               APC:action.payload.pc,
               ACG:action.payload.cg,
               AI:action.payload.i,
               AZeroTest:action.payload.zeroTest,
              };

        case GET_SUMMARYDEPRECIATION_CHECK_BALANCE:

           return{
            ...state,
            //SummaryDepreciation:[], 

            SummaryDepreciation:action.payload.map((x,index)=>({
                ...x,
                origin:x.origin,
                catalogAccountId:x.catalogAccountId,
                account:x.account,
                display:x.accountDisplay,
                //Auxiliar:auxiliar,
                value:x.accountValue,  
                ListAuxiliaryMovements: [],   
                key: index,
                AuxiliaryType: 2,
        }))
            
           };
        default:
            return  state;
    }
}

export default MonthlyClosing;