import React from 'react'
import Entry from './Entry'
import ClosingAndOthers from './ClosingAndOthers'
import Report from './Report'
import {Switch,Route} from 'react-router-dom'
const BankModule=({match})=>(
        <Switch>
            <Route path={`${match.url}/Entry`} component={Entry}  /> 
            <Route path={`${match.url}/ClosingAndOthers`} component={ClosingAndOthers}  /> 
            <Route path={`${match.url}/Report`} component={Report}  />
        </Switch>
)
export default BankModule;