import React from 'react'
import {Switch,Route} from 'react-router-dom'
import asyncComponent from "../../../util/asyncComponent";
const EntreCXC =({match})=>(
    <Switch>
          <Route  path={`${match.url}/ProviderRegister`} component={asyncComponent(()=>import( './ProviderRegister/index'))} />
          <Route  path={`${match.url}/BuyGoodsAndServices`} component={asyncComponent(()=>import( './BuyGoodsAndServices/index'))} />
          <Route  path={`${match.url}/ProviderPayment`} component={asyncComponent(()=>import( './ProviderPayment/index'))} />
          <Route  path={`${match.url}/ReplenishmentExpenses`} component={asyncComponent(()=>import( './ReplenishmentExpenses/index'))} />
        
    </Switch>
)
export default EntreCXC;