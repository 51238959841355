import { GET_LAST_CONTABLE_CLOSING_DATE, GET_NEXT_CONTABLE_CLOSING_DATE, GET_LAST_Depreciation_CLOSING_DATE, GET_NEXT_Depreciation_CLOSING_DATE } from '../../constants/ActionTypes'
import moment from 'moment'
const InitialState = {
    LastContableClosingDate: undefined,
    LastDepreciationClosingDate:undefined,
    loading: false,
    message: '',
    error: "",
    NextClosingDate: {
        beginDate: undefined,
        endDate: undefined
    },
    NextClosingDateDepreciation: {
        beginDate: undefined,
        endDate: undefined
    }
}


function DefaultReducer(state = InitialState, action) {
    switch (action.type) {

        case GET_LAST_CONTABLE_CLOSING_DATE:
                return {
                ...state,
                LastContableClosingDate: action.payload.ClosingDate
            }
        case GET_NEXT_CONTABLE_CLOSING_DATE:
            var NextDates = action.payload.NextClosing;
            return {
                ...state,
                NextClosingDate: {

                    beginDate: moment(NextDates.beginDate),
                    endDate: moment(NextDates.endDate)
                }
            }

        case GET_LAST_Depreciation_CLOSING_DATE:
            return {
                ...state,
                LastDepreciationClosingDate: action.payload.ClosingDate
            }

        case GET_NEXT_Depreciation_CLOSING_DATE:
            var NextDates = action.payload.NextClosing;
            return {
                ...state,
                NextClosingDateDepreciation: {

                    beginDate: moment(NextDates.beginDate),
                    endDate: moment(NextDates.endDate)
                }
            }


        default:
            return state;
    }
}

export default DefaultReducer;