import {ADD_Inventory_ROW_MOVEMENTS_TABLE,UPDATE_Inventory_ROW_MOVEMENTS_TABLE,Reset_Inventory_ROW_MOVEMENTS_TABLE} from '../../../../constants/ActionTypes'

const InitialState = {
    InventoryMovementTableData:[],
    InventoryMovementHeader:[]
}


//Revisar esto para determinar de donde proviene y Asi setearlo 
function InventoryMovement(state = InitialState, action) {
    switch (action.type) {
        case ADD_Inventory_ROW_MOVEMENTS_TABLE:
        return{
            ...state,
            InventoryMovementHeader:[...state.InventoryMovementHeader,action.payload]          
        }

        case UPDATE_Inventory_ROW_MOVEMENTS_TABLE:
            let NewData=state.InventoryMovementHeader.map((data, index)=>(index != action.payload.index ?  data : ({
                ...data,
                ...action.payload.data
              })));
              return {
                  ...state,
                  InventoryMovementHeader:NewData
        }
        case Reset_Inventory_ROW_MOVEMENTS_TABLE:
            return {
                ...state,
                InventoryMovementHeader:[]
            }
            
        default:
          return state
    }

}

export default InventoryMovement;