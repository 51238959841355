import React, { useEffect }  from "react";
import {Menu, Row, Col} from "antd";
import {Link} from "react-router-dom";
import { SetNavBarItems } from '../../appRedux/actions/Setting'
import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
//import IntlMessages from "../../util/IntlMessages";
import {useSelector, useDispatch } from "react-redux";

const SubMenu = Menu.SubMenu;
//const MenuItemGroup = Menu.ItemGroup;


const SidebarContent = ({sidebarCollapsed, setSidebarCollapsed}) => {

  let {navStyle, themeType} = useSelector(({settings}) => settings);
  const pathname = useSelector(({common}) => common.pathname);
  const navBarItems = useSelector(({ settings }) => settings.NavBarItems);
  const dispatch = useDispatch();


  const getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };
  const getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };
  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split('/')[1];
 /*
  No es necesario buscar las páginas de nuevo porque ya cargaron en HorizontalNav
  useEffect(() => {
     if (navBarItems.length > 0)
      return;
    
    dispatch(SetNavBarItems());
  }, [])*/

  return (
    <>
      <SidebarLogo sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed}/>
      <div className="gx-sidebar-content">
        <div className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}>
          <Row>
            <Col sm={16}>
          <UserProfile/>
            
            </Col>
            <Col sm={8}>
          <AppsNavigation/>
            
            </Col>
          </Row>
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
            mode="inline"
            className="wrap-text-menu">
   {
        navBarItems.map((item, Mi) => {
          return (
            <SubMenu key={`Module${item.moduleId}`} className="gx-menu-group" title={item.moduleDisplay}>
              {
                item.imrTabsInterfaces.map((tab) => {
                  return (
                   <SubMenu key={`Module${item.moduleId}Tab${tab.tabId}Interface0`} popupClassName={getNavStyleSubMenuClass(navStyle)}
                            title={<span><i className="icon icon-widgets"/>
                                            <span>{tab.tabsDisplay}</span>
                                   </span>}>
                      {
                        tab.moduleInterfaces.map((interfa, index) => {
                          return (
                            <Menu.Item key={`${interfa.url.replace("/","")}`}>
                              <Link to={`${interfa.url}?I=${interfa.id}&T=${tab.tabId}&M=${item.moduleId}`}>
                               <span>{interfa.displayInterface}</span>
                              </Link>
                            </Menu.Item>
                          )
                        })
                      }
                    </SubMenu>
                  )
                })
              }
            </SubMenu>
                )
              })
            } 
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

SidebarContent.propTypes = {};
export default SidebarContent;

