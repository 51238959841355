import React from 'react'
import {Route,Switch} from 'react-router-dom'
import asyncComponent from "../../../util/asyncComponent";
const ConsultFAC =({match})=>(
    <Switch>
    
          <Route  path={`${match.url}/Membership`} component={asyncComponent(()=>import( './Membership'))} />
        
    </Switch>
)

export default ConsultFAC;