import {SWITCH_LANGUAGE, TOGGLE_COLLAPSED_NAV, WINDOW_WIDTH,SET_NAVBAR_ITEMS} from "constants/ActionTypes";
import {
  LAYOUT_TYPE,
  LAYOUT_TYPE_FULL,
  NAV_STYLE,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL ,
  THEME_COLOR,
  THEME_TYPE,
  THEME_TYPE_SEMI_DARK
} from "../../constants/ThemeSetting";

const initialSettings = {
  //navCollapsed: false,
  navStyle: NAV_STYLE_INSIDE_HEADER_HORIZONTAL/*NAV_STYLE_FIXED*/,
  layoutType: LAYOUT_TYPE_FULL,
  themeType: THEME_TYPE_SEMI_DARK,
  themeColor: /*THEME_COLOR*/'dark_blue',

  //pathname: '/',
 // width: window.innerWidth,
  isDirectionRTL: false,
  locale: {
    languageId: 'english',
    locale: 'en',
    name: 'Spanish',
    icon: 'es'
  },
  NavBarItems:[]
};

const settings = (state = initialSettings, action) => {
  switch (action.type) {
    // case '@@router/LOCATION_CHANGE':
    //   return {
    //     ...state,
    //     pathname: action.payload.location.pathname,
    //     navCollapsed: false
    //   };
    /*
      Para solucionar, reinicio cuando se agranda se quitó esta parte 
    case WINDOW_WIDTH:
       return {
         ...state,
         width: action.width,
       };*/

     /*
       Para solucionar, reinicio cuando se le da click en donde se despliega se 
      quitó esta parte 
     case TOGGLE_COLLAPSED_NAV:
       return {
         ...state,
         navCollapsed: action.navCollapsed
       };*/
    case THEME_TYPE:
      return {
        ...state,
        themeType: action.themeType
      };
    case THEME_COLOR:
      return {
        ...state,
        themeColor: action.themeColor
      };

    case NAV_STYLE:
      return {
        ...state,
        navStyle: action.navStyle
      };
    case LAYOUT_TYPE:
      return {
        ...state,
        layoutType: action.layoutType
      };

    case SWITCH_LANGUAGE:
      return {
        ...state,
        locale: action.payload,

      };
      case SET_NAVBAR_ITEMS :
        return {
          ...state,
          NavBarItems:action.payload
        }
    default:
      return state;
  }
};

export default settings;
