import { GET_PRODUCT_LIST_PRODUCT_REGISTER} from '../../../../constants/ActionTypes'
import {zeroPad,currencyFormat} from '../../../../util/DaFrameword'

const InitialValue={
    ProductList:[]
}
function ProductRegister(state=InitialValue,action){

    switch (action.type) {
        case GET_PRODUCT_LIST_PRODUCT_REGISTER:
            return {
                ...state,
                ProductList:action.payload.map(x=>({
                    code:zeroPad(x.code,8),
                    description:x.description,
                    id:x.id,
                    companyId:x.companyId,
                    priceDisplay:currencyFormat(x.price),
                    price:x.price,
                    cost:x.cost,
                    costDisplay:currencyFormat(x.cost),
                    active:x.active,
                    indicatorGoodOrService:x.indicatorGoodOrService
                })) 
            }
        default:
           return state
    }  

}

export default ProductRegister;