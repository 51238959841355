import React from 'react'
import {Route,Switch} from 'react-router-dom'
import Entry from '../FC/Entry'
import Consult from './Consult/index'
import Report from './Report/index'
import ClosingAndOthers from './ClosingAndOthers/index'
const FC_Module =({match})=>(
    <Switch>
        <Route path={`${match.url}/Entry`} component={Entry}  /> 
        <Route path={`${match.url}/Consult`} component={Consult}  /> 
        <Route path={`${match.url}/Report`} component={Report} />
        <Route path={`${match.url}/ClosingAndOthers`} component={ClosingAndOthers} />
    </Switch>
)

export default FC_Module;