import {GET_COMPANY_GROUPS_BY_USER} from '../../../constants/ActionTypes'
const InitialState={
    CompanyGroups:[]
}

function CompanyGroup(state=InitialState,action){
    switch (action.type) {
        case GET_COMPANY_GROUPS_BY_USER:
            return{
                ...state,
                CompanyGroups:action.payload
            }
        default:
            return state;
    }
}
export default CompanyGroup;