import React from 'react'
import asyncComponent from '../../../util/asyncComponent'
import {Route,Switch} from 'react-router-dom'
const ClosingAndOthers=({match})=>(
    <Switch>
      <Route path={`${match.url}/NCFAccount`} component={asyncComponent(()=>import('./NCFAccount/index'))}/>
      <Route path={`${match.url}/MonthlyClosing`} component={asyncComponent(()=>import('./MonthlyClosing/index'))}/>
      <Route path={`${match.url}/FiscalClosing`} component={asyncComponent(()=>import('./FiscalClosing/index'))}/>
      <Route path={`${match.url}/ModuleClosing`} component={asyncComponent(()=>import('./ModuleClosing/index'))}/>
      <Route path={`${match.url}/RollbackVoucher`} component={asyncComponent(()=>import('./RollbackVoucher/index'))}/>
      <Route path={`${match.url}/YearlyClosing`} component={asyncComponent(()=>import('./YearlyClosing/index'))}/>
    </Switch>
)
export default ClosingAndOthers;