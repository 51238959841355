import {CALCULATE_QUANTITY_DEAL,UPDATE_ROW_BUDGET_REGISTER,RESET_ROW_BUDGET_REGISTER,ADD_INVOICE_DETAIL_BUGGET_REGISTER,
            UPDATE_INVOICE_DETAIL_BUGGET_REGISTER,DELETE_INVOICE_DETAIL_BUGGET_REGISTER,SET_ITBIS_TAX_INVOICE_BUGGET,RESER_BUGGET_REGISTER}  from '../../../../constants/ActionTypes'
import {GetPaymentToMoment} from '../../../../util/DaFrameword'
import moment from 'moment';
import { ID } from '../../../../constants/Config'
const InitialValue={
 DealTableData:[],
 TableData: [],
 percentItbis: null,
 IdCurrentRow: ID()
}
function BudgetRegister(state=InitialValue,action){
    switch (action.type) {
        case ADD_INVOICE_DETAIL_BUGGET_REGISTER:
            const NewRow = action.payload;
            console.log("Agregar", NewRow)
            return {
                ...state,
                TableData: [...state.TableData, NewRow],
                IdCurrentRow: ID()
            }
        case UPDATE_INVOICE_DETAIL_BUGGET_REGISTER:
            const UpdateRow = action.payload;
            return {
                ...state,
                TableData: state.TableData.map(x => x.id === UpdateRow.id ? UpdateRow : x)
            }
        case DELETE_INVOICE_DETAIL_BUGGET_REGISTER:
            const { productId } = action.payload;
            return {
                ...state,
                TableData: state.TableData.filter(x => x.productId !== productId)
            }
        case SET_ITBIS_TAX_INVOICE_BUGGET:
            return {
                ...state,
                percentItbis: action.payload.percentItbis * 0.01
            }

        case RESER_BUGGET_REGISTER:
            return {
                ...state,
                TableData: [],
                IdCurrentRow: ID()
            }
         case CALCULATE_QUANTITY_DEAL:
                const {TotalValue,InitialValue,   FirstDate, FrequencyId, QuantityPayment} = action.payload;

                if(TotalValue==0)
                    return { ...state, DealTableData:[] }
                
                
                var InitialPayment={
                    key:0,
                    Amount: InitialValue,
                    Date:moment(FirstDate),
                    DateString:moment(FirstDate).format("YYYY-MM-DD"),
                    Display:"Inicial"
                };

                var AmountQuantity= ((TotalValue-InitialValue)/QuantityPayment)
                var ListQuantity=[];
                console.log(InitialValue)

                if(InitialValue>0)
                    ListQuantity.push(InitialPayment);
                
              
                var NextDate=moment(FirstDate);
                
                for(var i=0; i<QuantityPayment;i++){

                    var Frequency=GetPaymentToMoment(FrequencyId);
                    NextDate=NextDate.add(Frequency.Quantity,Frequency.Name);

                    ListQuantity.push({
                        key:i+1,
                        Amount: AmountQuantity,
                        Date:moment(NextDate.format()),
                        DateString:NextDate.format("YYYY-MM-DD"),
                        Display:`Cuota ${i+1}`
                    })


                    if(i==QuantityPayment-1){
                        ListQuantity.push(
                            {
                                key:i+1+1,
                                Amount: TotalValue ,
                                Date:moment(NextDate.format()),
                                Display:"TOTAL",
                                DateString:""
                            }
                        )
                    }
                }
                return { ...state, DealTableData:ListQuantity }
        case UPDATE_ROW_BUDGET_REGISTER:

                const {index,row,Amount,Date}=action.payload;
                var Dtable= state.DealTableData;
              
                Dtable[index]={
                    ...Dtable[index],
                    Amount:Amount,
                    Date:Date,
                    DateString:Date.format("YYYY-MM-DD"),
                } 

                const NewTotalValue=Dtable.filter((_,i)=>i!=Dtable.length-1).reduce((p,c)=>Number(p)+Number(c.Amount),0);
                Dtable[Dtable.length-1]={
                    ...Dtable[Dtable.length-1],
                    Amount:NewTotalValue,
                    DateString:""
                } 

             return { ...state, DealTableData:Dtable }
             
             case RESET_ROW_BUDGET_REGISTER:
                 return {...state,DealTableData:[]}
        default:
        return state;
    }
}

export default BudgetRegister;