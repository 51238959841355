import { GET_ManagerRegister_LIST } from "../../../../constants/ActionTypes";
import { zeroPad } from "../../../../util/DaFrameword";


const InitialValue={
    ManagerList:[]
}

function ManagerRegister(state=InitialValue,action){

    switch (action.type) {
        case GET_ManagerRegister_LIST:
            return {
                ...state,
                    ManagerList:action.payload.map(x=>({
                    name:x.name,
                    position:x.position,
                    email:x.email,
                    Id:x.id    
                })) 
            }
        default:
           return state
    }  

}

export default ManagerRegister;