import React from 'react'
import {Route,Switch} from 'react-router-dom'
import asyncComponent from "../../../util/asyncComponent";
const Report_Entry =({match})=>(
    <Switch>
          <Route  path={`${match.url}/CopyInvoice`} component={asyncComponent(()=>import( './CopyInvoice/index'))} />
          <Route  path={`${match.url}/CopyBudget`} component={asyncComponent(()=>import( './CopyBudget/index'))} />
          <Route  path={`${match.url}/InvoiceMovement`} component={asyncComponent(()=>import( './InvoiceMovement/index'))} />
          <Route  path={`${match.url}/BudgetReport`} component={asyncComponent(()=>import( './BudgetReport/index'))} />
          <Route  path={`${match.url}/InvoiceCommissions`} component={asyncComponent(()=>import( './InvoiceCommission/index'))} />

    </Switch>
)

export default Report_Entry;