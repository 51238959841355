import axios from 'axios'
import moment from 'moment'
import {BASE_URL} from '../../constants/Config'
import { AxiosFetchStart, AxiosFetchSuccess, AxiosFetchError } from '../actions/LoadingCenter'
export const APP_GET_VALUES_REQUEST='APP_GET_VALUES_REQUEST'


export const getRequest = (url,params) => ({
    type: APP_GET_VALUES_REQUEST,
    payload: {
        
        request: {
            method: 'GET',
            url,
            params,
            
        }
    }
});

export const postRequest = (url,params,data) => ({
    type: APP_GET_VALUES_REQUEST,
    payload: {
        request: {
            method: 'POST',
            url,
            params,
            data: JSON.stringify(data)
        }
    }
});

export const putRequest = (url,params,data) => ({
    type: APP_GET_VALUES_REQUEST,
    payload: {
        request: {
            method: 'PUT',
            url,
            params,
            data: JSON.stringify(data)
        }
    }
});


export const UpdateTokenRequest=(CallBack) => {
    axios
        .post(`${BASE_URL}/api/users/UpdateToken`,{}, {
            responseType: "application/json",
            headers: {

                'Authorization': 'Bearer ' + localStorage.getItem("token"),
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS'
            }
        })
        .then((response) => {
            CallBack(response.data);
        })
}



export const GET_HTTP_REQUEST =(Url,Params,CallBack)=>(dispatch)=>{
    dispatch(getRequest(Url,Params))
    .then((resp)=>{
        const {payload:{data,status}}=resp;
        CallBack(data,status);
    })
    .catch((error)=>{
        console.log(error);
    })
}


export const AxiosPDFReport=(url,params,dispatch,CallBack) => {
    dispatch(AxiosFetchStart())
    try {
        axios
            .get(`${BASE_URL}/api/${url}`, {
                responseType: "blob",
                headers: {

                    'Authorization': 'Bearer ' + localStorage.getItem("token"),
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS'
                },
                params
            })
            .then((response) => {
                dispatch(AxiosFetchSuccess())
                if(typeof CallBack==="function"){
                    CallBack();
                }
              
                const file = new Blob([response.data], { type: "application/pdf" });
                //Build a URL from the file
                const fileURL = URL.createObjectURL(file);
                //Open the URL on new Window
                const pdfWindow = window.open();
                pdfWindow.location.href = fileURL;
            })
            .catch((error) => {
                dispatch(AxiosFetchError())
                console.log(error);
            });
    } catch (error) {
        return { error };
    }
}

export const AxiosEXCELReport =(url,params,reportName,dispatch)=>{
    dispatch(AxiosFetchStart())
        try {
            axios
                .get(`${BASE_URL}/api/${url}`, {
                    responseType: "blob",
                    headers: {
    
                        "Authorization": 'Bearer ' + localStorage.getItem("token"),
                        "Content-Type": 'application/json; charset=utf-8',
                        "Access-Control-Allow-Origin": '*',
                        "Access-Control-Allow-Methods": 'GET,PUT,POST,DELETE,PATCH,OPTIONS'
                    },
                    params
                })
                .then((response) => {
                    dispatch(AxiosFetchSuccess())
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', reportName + moment(new Date()).format("YYYY-MM-DD HH:mm:ss") + '.xlsx'); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((error) => {
                    dispatch(AxiosFetchError())
                    console.log(error);
                });
        } catch (error) {
            return { error };
        }
}
export const AxiosTXTReport =(url,params,reportName,dispatch)=>{
    dispatch(AxiosFetchStart())
    try {
        axios
            .get(`${BASE_URL}/api/${url}`, {
                responseType: "blob",
                headers: {
                    "Authorization": 'Bearer ' + localStorage.getItem("token"),
                    "Content-Type": 'application/json; charset=utf-8',
                    "Access-Control-Allow-Origin": '*',
                    "Access-Control-Allow-Methods": 'GET,PUT,POST,DELETE,PATCH,OPTIONS'
                },
                params
            })
            .then((response) => {
                dispatch(AxiosFetchSuccess())
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', reportName /*+ moment(new Date()).format("YYYY-MM-DD HH:mm:ss") */+ '.txt'); //or any other extension
                document.body.appendChild(link);
                link.click();
            })
            .catch((error) => {
                dispatch(AxiosFetchError())
                console.log(error);
            });
    } catch (error) {
        return { error };
    }
}
