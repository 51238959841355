import React from 'react'
import Widget from "components/Widget/index";
import { BASE_URL, ID } from '../../constants/Config';

const CardSelector =({Entity,SelectEntity,type})=>{ 

const ExecuteEntitySelector=()=>{
    SelectEntity(Entity);
}

return (
    <a  onClick={ExecuteEntitySelector} style={{height:"100%",margin:'0rem', padding: 0}}   block >
     
        <Widget styleName="gx-card-full gx-dot-arrow-hover">
      <div className="gx-user-wid-row">
        <div className="gx-user-wid gx-mr-3">
          <img alt="..." src={`${BASE_URL}/api/Companies/GetLogo?Company_ID=${Entity.id}&Random=${ID()}`} className="gx-object-cover" style={{width: '50px',
                         height: '50px',margin: '0.5rem',position: 'relative',borderRadius: '10px',}}  />
        </div>
        <div className="gx-user-wid-body gx-py-3 gx-pr-3">
          <div className="ant-row-flex">
            <p className="gx-mb-1  gx-fs-sm" style={
                        {
                            margin:0,
                            fontSize: '10px',
                            marginBottom: '-15px'
                        }
                      }><b>{ (type)===2?Entity.displayBranch:Entity.displayCompany}</b></p>
          </div>
          <div className="gx-dot-arrow">
          {Entity.isConsolidated === true ? (
            <div className="gx-hover-arrow gx-bg-primary">
             <i className="icon icon-star gx-text-white" />
            </div>
            ) : (
            <div className="gx-hover-arrow" style={{ backgroundColor: '#117A65' }}>
             <i className="icon icon-long-arrow-right gx-text-white" />
            </div>
            )}
            <div className="gx-dot">
              <i className="icon icon-ellipse-v gx-text-white"/>
            </div>
          </div>
        </div>
      </div>
    </Widget>     
    </a> 
    )
}
export default CardSelector; 