import {GET_AUXILIARY_CLASSIFICATION_DATA,SET_AUXILIARY_CLASSIFICATION_ID} from '../../../../constants/ActionTypes'

const InitialState={
    AuxiliarClasification:[],
    ClassificationAuxiliaryId:undefined
}

function registerAuxiliar(state=InitialState,action){
    switch (action.type) {
        case GET_AUXILIARY_CLASSIFICATION_DATA:
            return {
                ...state,
                AuxiliarClasification:action.payload
            }
        case SET_AUXILIARY_CLASSIFICATION_ID:
            return{
                ...state,
                ClassificationAuxiliaryId:action.payload
            }
        default:
            return state;
    }  
}

export default registerAuxiliar;