import {postRequest,getRequest,putRequest} from '../../../actions/Axios'
import {GET_ALL_BANK_REGISTER,SET_SEARCH_BANK_REGISTER,SELECT_FILTER_OPTION_BANK_REGISTER,FILTER_BANK_REGISTER,SET_ADD_BANK_REGISTER_MODAL_OPEN,
        SET_ADD_BANK_REGISTER_MODAL_CLOSE,TOOGLE_DRAWER_BANK_REGISTER} from '../../../../constants/ActionTypes'

    export  const addBankRegister=(params,body,CallBack)=>(dispatch)=>{
        dispatch(postRequest("/Bank",params,body))
        .then(resp=>{
            const {payload:{data,status}}=resp;
            CallBack(data,status)
        }).catch(error=>console.log(error));
    }   

    export  const updateBankRegister=({Id},body,CallBack)=>(dispatch)=>{
    dispatch(putRequest(`/Bank/${Id}`,{},{ ...body,Id}))
    .then(resp=>{
        const {payload:{data,status}}=resp;
        CallBack(data,status)
    }).catch(error=>console.log(error));
    }

    export const GetBankRegister=()=>(dispatch)=>{
        dispatch(getRequest("/Bank/GetAllBankRegister",{}))
        .then(resp=>{
            const {payload:{data}}=resp;
    
            dispatch({
                type:GET_ALL_BANK_REGISTER,
                payload:data
            })
        }).catch(error=>console.log(error));
    }

    export const setSearchBankRegister=(seachText)=>{
        return{
            type:SET_SEARCH_BANK_REGISTER,
            payload:seachText
        }
    }
    

    export const selectOptionBankRegister=(optionId)=>{  
        return{
            type:SELECT_FILTER_OPTION_BANK_REGISTER,
            payload:optionId
        }
    }

    export const filterBankRegister=()=>{
        return {
            type:FILTER_BANK_REGISTER
        }
    }


    export const addBankRegisterModalOpen =()=>({
        type:SET_ADD_BANK_REGISTER_MODAL_OPEN,
        payload:true
    })

    export const  addBankRegisternModalClose =()=>({
            type:SET_ADD_BANK_REGISTER_MODAL_CLOSE,
            payload:false
    })

export const toogleDrawerBankRegister=()=>({
    type:TOOGLE_DRAWER_BANK_REGISTER
})