import {AXIOS_FETCH_ERROR, AXIOS_FETCH_START, AXIOS_FETCH_SUCCESS} from '../../constants/ActionTypes'

const InitialState={
    loading: false,
    message: '',
    error: "",
}

function LoadingCenter(state=InitialState,action){
    switch (action.type) {
        case AXIOS_FETCH_START: {
           //console.log("TRUE PAPA")
            return {...state, error: '', message: '', loading: true};
            }
        case AXIOS_FETCH_SUCCESS: {
            return {...state, error: '', message: '', loading: false};
        }
        case AXIOS_FETCH_ERROR: {
            return {...state, loading: false, error: action.payload, message: ''};
          }
        default:
            return state;
    }
}

export default LoadingCenter;