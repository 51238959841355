import { GET_ProductSubClassification_LIST_Register } from "../../../../constants/ActionTypes";
import { zeroPad } from "../../../../util/DaFrameword";


const InitialValue={
    ProductSubClassificationList:[]
}

function ProductSubClassificationRegister(state=InitialValue,action){

    switch (action.type) {
        case GET_ProductSubClassification_LIST_Register:
            return {
                ...state,
                    ProductSubClassificationList:action.payload.map(x=>({
                        
                    Id:zeroPad(x.id,8),
                    description:x.description,
                    clasification:x.productClasification.description,
                    code:x.code,
                    active:x.active                
                })) 
            }
        default:
           return state
    }  

}

export default ProductSubClassificationRegister;