import {GET_AUXILIARY_CLASSIFICATION_DATA,SET_AUXILIARY_CLASSIFICATION_ID} from '../../../../constants/ActionTypes'
import {getRequest,postRequest,putRequest} from '../../Axios'

export const GetAuxiliaryClassificationData= (params)=>(dispatch)=>{
    dispatch(getRequest("/AuxiliaryClassification",params))
    .then(resp=>{
            dispatch({
                type:GET_AUXILIARY_CLASSIFICATION_DATA,
                payload:resp.payload.data
            })
    })
    .catch(error=>{
        console.log(error)
    })
}

export const addAuxiliar=(params,body,callBack)=>(dispatch)=>{ 
    dispatch(postRequest('/AuxiliaryMaster/',params,body))
    .then(resp=>{
        const {payload:{data,status}}=resp
        callBack(data,status);
    })
    .catch(error=>{
        console.log(error)
    })
}

export const updateAuxiliar=({id},body,callBack)=>(dispatch)=>{ 
    dispatch(putRequest(`/AuxiliaryMaster/${id}`,{id},body))
    .then(resp=>{
        const {payload:{data,status}}=resp
        callBack(data,status);
    })
    .catch(error=>{
        console.log(error)
    })
}

export const  setAuxiliaryClassificationID =(id)=>( 

    {
        type:SET_AUXILIARY_CLASSIFICATION_ID,
        payload:id
    }
)