import {postRequest,getRequest,putRequest} from '../../Axios'
import {GET_ALL_AUXILIARY_CLASSIFICATION,SET_SEARCH_AUXILIARY_CLASSIFICATION,SELECT_FILTER_OPTION_AUXILIARY_CLASSIFICATION,FILTER_AUXILIARY_CLASSIFICATION,
        SET_ADD_AUXILIARY_CLASSIFICATION_MODAL_OPEN,SET_ADD_AUXILIARY_CLASSIFICATION_MODAL_CLOSE,
        TOOGLE_DRAWER_AUXILIARY_CLASSIFICATION} from '../../../../constants/ActionTypes'

    export  const addAuxiliaryClassification=(params,body,CallBack)=>(dispatch)=>{
        dispatch(postRequest("/AuxiliaryClassification/",params,body))
        .then(resp=>{
            const {payload:{data,status}}=resp;
            CallBack(data,status)
        }).catch(error=>console.log(error));
    }   

    export  const updateAuxiliaryClassification=({Id},body,CallBack)=>(dispatch)=>{
    dispatch(putRequest(`/AuxiliaryClassification/${Id}`,{},{ ...body,Id}))
    .then(resp=>{
        const {payload:{data,status}}=resp;
        CallBack(data,status)
    }).catch(error=>console.log(error));
    }

    export const GetAuxiliaryClassification=()=>(dispatch)=>{
        dispatch(getRequest("/AuxiliaryClassification/GetAllAuxiliaryClassification",{}))
        .then(resp=>{
            const {payload:{data}}=resp;
            dispatch({
                type:GET_ALL_AUXILIARY_CLASSIFICATION,
                payload:data
            })
        }).catch(error=>console.log(error));
    }

    export const setSearchAuxiliaryClassification=(seachText)=>{
        return{
            type:SET_SEARCH_AUXILIARY_CLASSIFICATION,
            payload:seachText
        }
    }

    export const selectOptionAuxiliaryClassification=(optionId)=>{  
        return{
            type:SELECT_FILTER_OPTION_AUXILIARY_CLASSIFICATION,
            payload:optionId
        }
    }

    export const filterAuxiliaryClassification=()=>{
        return {
            type:FILTER_AUXILIARY_CLASSIFICATION
        }
    }

    export const addAuxiliaryClassificationModalOpen =()=>({
        type:SET_ADD_AUXILIARY_CLASSIFICATION_MODAL_OPEN,
        payload:true
    })

    export const addAuxiliaryClassificationModalClose =()=>({
            type:SET_ADD_AUXILIARY_CLASSIFICATION_MODAL_CLOSE,
            payload:false
    })

export const toogleDrawerAuxiliaryClassification=()=>({
    type:TOOGLE_DRAWER_AUXILIARY_CLASSIFICATION
})