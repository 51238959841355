import React, {useState} from "react";
import {Layout, Button,Typography,Tabs} from "antd";
import {Link} from "react-router-dom";
import { LogoutOutlined} from '@ant-design/icons'
import {useDispatch} from "react-redux";
import { userSignOut} from '../../../appRedux/actions/Auth'
import { useHistory} from 'react-router-dom'
import {Col,Row} from 'antd'
import SearchBox from "components/SearchBox";
import CustomScrollbars from "util/CustomScrollbars";
const TabPane = Tabs.TabPane;
const {Header} = Layout;

const Title=Typography.Title;
const Topbar = ({Titulo, OnFilter, OnTab}) => {
  const history=useHistory()
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState('');

  const Logout=()=>{
    dispatch(userSignOut());
   //Mejorar Linea de Código
   setTimeout(()=> history.push("/signin"),200);
  }

  const HandleSeach =(value)=>{
    OnFilter(value);   
    setSearchText(value);
  }

  return (
    <Header>
         <Link to="/" className="gx-d-none gx-d-lg-block  gx-pointer">
          <img alt="" src={require("assets/images/w-logo.png")}/>
        </Link>
        <ul className="gx-header-notifications gx-ml-auto" style={{marginBottom: '-80px'}}>
         <li>
          <Row>
            <Col xl={4} lg={4} md={12} sm={24} xs={24} className="gx-d-none gx-d-lg-block">
              <Title style={{color:"white",marginBottom:0}} level={3}> {Titulo} </Title>
            </Col>
            <Col xl={10} lg={10} md={12} sm={10} xs={10} >
                 <SearchBox styleName=" gx-lt-icon-search-bar-lg"
                            placeholder="Buscar Compañia..."
                            value={searchText}
                            onChange={(e) => HandleSeach(e.target.value)}
                 />
            </Col>
            <Col xl={10} lg={10} md={12} sm={14} xs={6} >
                 <Tabs className='gx-text-white' defaultActiveKey="1" onChange={OnTab}>
                   <TabPane tab="Todas" key="1" >
                     <CustomScrollbars className="gx-chat-sidenav-scroll-tab-1">
                       <div className='gx-p-5'></div>
                     </CustomScrollbars>
                   </TabPane>
                   <TabPane tab="Nacionales" key="2">
                     <CustomScrollbars className="gx-chat-sidenav-scroll-tab-2">
                       <div className='gx-p-5'></div>
                     </CustomScrollbars>
                   </TabPane>
                   <TabPane tab="Internacionales" key="3">
                    <CustomScrollbars className="gx-chat-sidenav-scroll-tab-2">
                      <div className='gx-p-5'></div>
                    </CustomScrollbars>
                   </TabPane>
                 </Tabs>
                </Col>
              </Row>
            </li>
        </ul>
        <ul className="gx-header-notifications gx-ml-auto">
          <li className="gx-language">
            <Button  type="link" className="gx-m-0" onClick={Logout}><LogoutOutlined style={{fontSize:"xx-large",color:"#a2e0de"}} /></Button>
          </li>  
        </ul>
    </Header>
  );
};
Topbar.defaultProps={
  Titulo:""
}
export default Topbar;