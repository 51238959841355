import {postRequest,getRequest,putRequest} from '../../Axios'
import {GET_ALL_SELLER,SET_SEARCH_SELLER,SELECT_FILTER_OPTION_SELLER,FILTER_SELLER,SET_ADD_SELLER_MODAL_OPEN,SET_ADD_SELLER_MODAL_CLOSE,
        TOOGLE_DRAWER_SELLER} from '../../../../constants/ActionTypes'

    export  const addSeller=(params,body,CallBack)=>(dispatch)=>{
        dispatch(postRequest("/Seller/AddSeller",params,body))
        .then(resp=>{
            const {payload:{data,status}}=resp;
            CallBack(data,status)
        }).catch(error=>console.log(error));
    }   

    export  const updateSeller=({Id},body,CallBack)=>(dispatch)=>{
    dispatch(putRequest(`/Seller/${Id}`,{},{ ...body,Id}))
    .then(resp=>{
        const {payload:{data,status}}=resp;
        CallBack(data,status)
    }).catch(error=>console.log(error));
    }

    export const GetSeller=()=>(dispatch)=>{
        dispatch(getRequest("/Seller/GetAllSeller",{}))
        .then(resp=>{
            const {payload:{data}}=resp;
            dispatch({
                type:GET_ALL_SELLER,
                payload:data
            })
        }).catch(error=>console.log(error));
    }

    export const setSearchSeller=(seachText)=>{
        return{
            type:SET_SEARCH_SELLER,
            payload:seachText
        }
    }

    export const selectOptionSeller=(optionId)=>{  

        return{
            type:SELECT_FILTER_OPTION_SELLER,
            payload:optionId
        }
    }

    export const filterSeller=()=>{
        return {
            type:FILTER_SELLER
        }
    }


    export const addSellerModalOpen =()=>({
        type:SET_ADD_SELLER_MODAL_OPEN,
        payload:true
    })

    export const addSellerModalClose =()=>({
            type:SET_ADD_SELLER_MODAL_CLOSE,
            payload:false
    })

export const toogleDrawerSeller=()=>({
    type:TOOGLE_DRAWER_SELLER
})
  
