import {ADD_Invoice_ROW_MOVEMENTS_TABLE,UPDATE_Invoice_ROW_MOVEMENTS_TABLE,Reset_Invoice_ROW_MOVEMENTS_TABLE,GET_PendingInvoice_LIST_Register} from '../../../../constants/ActionTypes'
import { zeroPad } from '../../../../util/DaFrameword';
import moment from 'moment';
const InitialState = {
    InvoiceTableData:[],
    InvoiceHeader:[],
    PendingInvoiceList:[]
}

function Invoice(state = InitialState, action) {
    switch (action.type) {
        case ADD_Invoice_ROW_MOVEMENTS_TABLE:
        return{
            ...state,
            InvoiceHeader:[...state.InvoiceHeader,action.payload]          
        }

        case UPDATE_Invoice_ROW_MOVEMENTS_TABLE:
            let NewData=state.InvoiceHeader.map((data, index)=>(data.productId != action.payload.index ?  data : ({
                ...data,
                ...action.payload.data
              })));
              return {
                  ...state,
                  InvoiceHeader:NewData
        }

        case GET_PendingInvoice_LIST_Register:
            return {
                ...state,
                    PendingInvoiceList:action.payload.map(x=>({
                    invoiceId:x.id,
                    custumerName:x.custumerName,
                    date: moment(x.date).format("YYYY-MM-DD"),
                    number:zeroPad(x.code,7),
                    value:x.total    
                })) 
            }
        case Reset_Invoice_ROW_MOVEMENTS_TABLE:
            return {
                ...state,
                InvoiceHeader:[]
            }
            
        default:
          return state
    }

}

export default Invoice;