import {SET_Table_ItbisRetentionCertification,RESET_ItbisRetentionCertification_Table,Update_Table_ItbisRetentionCertification} from '../../../../constants/ActionTypes'
import moment from 'moment'

const InitialState={
    TableData:[]
}

function ITBISRetentionCertification (state=InitialState,action){

        switch (action.type) {
             case SET_Table_ItbisRetentionCertification:
                return {
                    ...state,
                    TableData:action.payload.map(x=>({
                        id:x.id,
                        rnc:x.rnc,
                        name:x.name,
                        date:x.date,
                        ncf:x.ncf,
                        itbisAdvance:x.itbisAdvance,
                        itbisRet:x.itbisRet,
                        isrRetained:x.isrRetained,
                        Switch:x.Switch,
                        value:x.value
                    }))
                }
             case Update_Table_ItbisRetentionCertification:
              
             let updatedData;
             if (Array.isArray(action.payload.data)) {
               
                 updatedData = action.payload.data.reduce((acc, item) => {
                     return acc.map((data) =>
                         data.id !== item.id ? data : { ...data, ...item }
                     );
                 }, state.TableData);
             } else {
               
                 updatedData = state.TableData.map((data) =>
                     data.id !== action.payload.data.id ? data : { ...data, ...action.payload.data }
                 );
             }
 
             return {
                 ...state,
                 TableData: updatedData
             };
            case RESET_ItbisRetentionCertification_Table:
                return {
                    ...state,
                    TableData:[]
                }
                
            default:
                return state;
        }
}

export default ITBISRetentionCertification;