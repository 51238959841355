import {GET_ALL_DOCUMENT_CONFIGURATION,SELECT_FILTER_OPTION_DOCUMENT_CONFIGURATION,FILTER_DOCUMENT_CONFIGURATION,SET_SEARCH_DOCUMENT_CONFIGURATION ,SET_ADD_CONTAB_ACTION_MODAL_OPEN,SET_ADD_CONTAB_ACTION_MODAL_CLOSE,TOOGLE_DRAWER_CONTAB_ACTION} from '../../../../constants/ActionTypes'
const InitialState={
    AllData:[],
    Data:[],
    selectedSectionId: 2,
    drawerState: false,
    search: '',
    filterOption: 'All contacts',
    addModalVisible: false,
}

function documentConfigurationMaintenance(state=InitialState,action){
    switch (action.type) {
        case GET_ALL_DOCUMENT_CONFIGURATION:
            return {
                ...state,
                AllData:action.payload,
                Data: action.payload.map(x=>(
                            {  ...x,
                            documentActionCatalogs:x.documentActionCatalogs.filter((x2)=>((state.selectedSectionId===1)?true:false)|| (state.selectedSectionId===2 && x2.active===true ) || (state.selectedSectionId===3 && x2.active===false))
                            }
                      )).filter(z=>z.documentActionCatalogs.length>0)
                       
            };

            case SELECT_FILTER_OPTION_DOCUMENT_CONFIGURATION:
                switch (action.payload) {
                    case 1: 
                    return{
                        ...state,
                        Data:state.AllData,
                        selectedSectionId:action.payload
                    }
                    case 2:
                        const DataOption2=state.AllData.map(x=>(
                              {  ...x,
                                documentActionCatalogs:x.documentActionCatalogs.filter(x2=> x2.active===true)
                              }
                        )).filter(z=>z.documentActionCatalogs.length>0);
                        return {
                            ...state,
                            Data:DataOption2,
                            selectedSectionId:action.payload
                        }
                    case 3: 

                    const DataOption3= state.AllData.map(x=>(
                          {...x,
                            documentActionCatalogs:x.documentActionCatalogs.filter(x2=> x2.active===false)
                          }
                        )).filter(z=>z.documentActionCatalogs.length>0);
                        return {
                            ...state,
                            Data:DataOption3,
                            selectedSectionId:action.payload
                        }
                    default:
                        return state
                }
        case SET_SEARCH_DOCUMENT_CONFIGURATION:
            return {
                ...state,
                search:action.payload
            };

            case FILTER_DOCUMENT_CONFIGURATION:
               
                const NData=state.AllData.filter((row)=>`${row.contabAction} ${row.moduleInterface} ${row.documentEmissionDisplay}`.toLowerCase().indexOf(state.search.toLowerCase())!==-1)
                    .filter((x)=>((state.selectedSectionId===1)?true:false)|| (state.selectedSectionId===2 &&x.documentActionCatalogs.filter(x2=> x2.active===true).length>0 ) || (state.selectedSectionId===3 && x.documentActionCatalogs.filter(x2=> x2.active===false).length>0))
                   
                    return {
                    ...state,
                    Data:NData
                    };
        /*--------------------------------------------------------------------------------*/

            
            case SET_ADD_CONTAB_ACTION_MODAL_OPEN:
                return {
                    ...state,
                    addModalVisible:action.payload
                } 
            case SET_ADD_CONTAB_ACTION_MODAL_CLOSE:
                return {
                    ...state,
                    addModalVisible:action.payload
                }
            case TOOGLE_DRAWER_CONTAB_ACTION:
                return {
                    ...state,
                    drawerState:!state.drawerState
                }
        default:
            return state
    }
}

export default documentConfigurationMaintenance;