import React, {useEffect} from "react";
import {Button, Checkbox, Form,  Input} from "antd";
import {Link, useHistory} from "react-router-dom";
import { postRequest } from '../appRedux/actions/Axios';
import {useDispatch, useSelector} from "react-redux";
import {NotificationContainer, NotificationManager} from "react-notifications";
import {NotificationErrorTimeOut,NotificationSuccessTimeOut} from '../constants/Config'
import {
   hideMessage,
   showAuthLoader,
   hideAuthLoader,
   //showAuthMessage
  // userFacebookSignIn,
  // userGithubSignIn,
  // userGoogleSignIn,
  // userSignUp,
  // userTwitterSignIn
} from "appRedux/actions/Auth";
import {} from '../'

import IntlMessages from "util/IntlMessages";
import {message} from "antd/lib/index";
import CircularProgress from "components/CircularProgress/index";

const FormItem = Form.Item;

const SignUp = (props) => {

  const dispatch = useDispatch();
  const history = useHistory();
  const {loader, alertMessage, showMessage, authUser} = useSelector(({auth}) => auth);


  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 100);
    }
    if (authUser !== null) {
      history.push('/');
    }
  });
  const handleReset = () => {
    props.form.resetFields();
  };

  const IsAvailable=(e)=>{
    const Email=e.currentTarget.value;
    const IsValid=typeof props.form.getFieldError("Email") ==="undefined";
    if (!IsValid || Email.length<5)
      return; 
    dispatch(
        postRequest(
          `users/EmailIsAvailable`,
          {Email}
          ,{})
      ).then(request=>{
        const {payload:{data}} =request;

        if(data){
            NotificationManager.error("Usuario Registrado sastifactoriamente.", "Correo introducido ya esta siendo utilizado", NotificationErrorTimeOut);
            props.form.resetFields("Email");
        }

        //props.form.resetFields();
        //console.log(data);
     })
     .catch()
  
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      console.log("values", values);
      if (!err) {
        dispatch(showAuthLoader());
        dispatch(postRequest(`/users`,{},values))
          .then((response)=>{
              const {payload:{status}}=response;
              if(status===201){
                  handleReset();
                  dispatch(hideAuthLoader());
                  NotificationManager.success("Usuario Registrado sastifactoriamente.", "Correcto!", NotificationSuccessTimeOut);
                  setTimeout(()=>{
                       history.push('/signin');
                  },2501);
                }
          }).catch((e)=>{
             dispatch(hideAuthLoader());
             const {error:{response:{data}}}=e;
             if(!data.errors)
             return;

             const errors=Object.keys(data.errors); 
             errors.forEach(error => {
              data.errors[error].forEach((ValidationMessage)=>{
                NotificationManager.error(ValidationMessage, "Validación!", NotificationErrorTimeOut);
              });
                //  data.errors[error].map((ValidationMessage)=>{
                //   NotificationManager.error(ValidationMessage, "Validación!", NotificationErrorTimeOut);
                // })
             });
            //  errors.map((error)=>{
            //     data.errors[error].map((ValidationMessage)=>{
            //       NotificationManager.error(ValidationMessage, "Validación!", NotificationErrorTimeOut);
            //     })
            //  });
          });
      }
    });
  };

  const {getFieldDecorator} = props.form;

    return (
      <div className="gx-app-login-wrap">
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-content-bg">
                <img src={"https://via.placeholder.com/272x395"} alt='Neature'/>
              </div>
              <div className="gx-app-logo-wid">
                <h1><IntlMessages id="app.userAuth.signUp"/></h1>
                <p><IntlMessages id="app.userAuth.bySigning"/></p>
                <p><IntlMessages id="app.userAuth.getAccount"/></p>
              </div>
              <div className="gx-app-logo">
                <img alt="example" src={require("assets/images/logo.png")}/>
              </div>
            </div>

            <div className="gx-app-login-content">
              <Form onSubmit={handleSubmit} className="gx-signup-form gx-form-row0">
                <FormItem>
                  {getFieldDecorator('Person.FirstName', {
                    rules: [{required: true, message: 'Please input your username!'}],
                  })(
                    <Input placeholder="Nombres"/>
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('Person.LastName', {
                    rules: [{required: true, message: 'Please input your username!'}],
                  })(
                    <Input  placeholder="Apellidos"/>
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('Email', {
                    rules: [{
                      required: true, type: 'email', message: 'The input is not valid E-mail!',
                    }],
                  })(
                    <Input onBlur={IsAvailable} placeholder="Correo Electrónico"/>
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('Pass', {
                    rules: [{required: true, message: 'Please input your Password!'}],
                  })(
                    <Input type="password" placeholder="Contraseña"/>
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('remember', {
                    valuePropName: 'checked',
                    initialValue: true,
                  })(
                    <Checkbox><IntlMessages id="appModule.iAccept"/></Checkbox>
                  )}
                  <span className="gx-link gx-signup-form-forgot"><IntlMessages
                    id="appModule.termAndCondition"/></span>
                </FormItem>
                <FormItem>
                  <Button type="primary" className="gx-mb-0" htmlType="submit">
                    <IntlMessages id="app.userAuth.signUp"/>
                  </Button>
                  <span><IntlMessages id="app.userAuth.or"/></span> <Link to="/signin"><IntlMessages
                  id="app.userAuth.signIn"/></Link>
                </FormItem>
                {/* <div className="gx-flex-row gx-justify-content-between">
                  <span>or connect with</span>
                  <ul className="gx-social-link">
                    <li>
                      <Icon type="google" onClick={() => {
                        dispatch(showAuthLoader());
                        dispatch(userGoogleSignIn());
                      }}/>
                    </li>
                    <li>
                      <Icon type="facebook" onClick={() => {
                        dispatch(showAuthLoader());
                        dispatch(userFacebookSignIn());
                      }}/>
                    </li>
                    <li>
                      <Icon type="github" onClick={() => {
                        dispatch(showAuthLoader());
                        dispatch(userGithubSignIn());
                      }}/>
                    </li>
                    <li>
                      <Icon type="twitter" onClick={() => {
                        dispatch(showAuthLoader());
                        dispatch(userTwitterSignIn());
                      }}/>
                    </li>
                  </ul>
                </div> */}
              </Form>
            </div>
            {loader &&
            <div className="gx-loader-view">
              <CircularProgress/>
            </div>
            }
            {showMessage &&
            message.error(alertMessage)}
          </div>
        </div>
        <NotificationContainer/>
      </div>
    );
};


const WrappedSignUpForm =(SignUp);


export default WrappedSignUpForm;
