import {postRequest,getRequest,putRequest} from '../../Axios'
import {GET_ALL_DEBT_COLLECTOR,SET_SEARCH_DEBT_COLLECTOR,SELECT_FILTER_OPTION_DEBT_COLLECTOR,FILTER_DEBT_COLLECTOR,SET_ADD_DEBT_COLLECTOR_MODAL_OPEN,
        SET_ADD_DEBT_COLLECTOR_MODAL_CLOSE,TOOGLE_DRAWER_DEBT_COLLECTOR} from '../../../../constants/ActionTypes'

    export  const addDebtCollector=(params,body,CallBack)=>(dispatch)=>{
        dispatch(postRequest("/DebtCollector/AddDebtCollector",params,body))
        .then(resp=>{
            const {payload:{data,status}}=resp;
            CallBack(data,status)
        }).catch(error=>console.log(error));
    }   

    export  const updateDebtCollector=({Id},body,CallBack)=>(dispatch)=>{
    dispatch(putRequest(`/DebtCollector/${Id}`,{},{ ...body,Id}))
    .then(resp=>{
        const {payload:{data,status}}=resp;
        CallBack(data,status)
    }).catch(error=>console.log(error));
    }

    export const GetDebtCollector=()=>(dispatch)=>{
        dispatch(getRequest("/DebtCollector/GetAllDebtCollector",{}))
        .then(resp=>{
            const {payload:{data}}=resp;
            dispatch({
                type:GET_ALL_DEBT_COLLECTOR,
                payload:data
            })
        }).catch(error=>console.log(error));
    }

    export const setSearchDebtCollector=(seachText)=>{
        return{
            type:SET_SEARCH_DEBT_COLLECTOR,
            payload:seachText
        }
    }

    export const selectOptionDebtCollector=(optionId)=>{  

        return{
            type:SELECT_FILTER_OPTION_DEBT_COLLECTOR,
            payload:optionId
        }
    }

    export const filterDebtCollector=()=>{
        return {
            type:FILTER_DEBT_COLLECTOR
        }
    }


    export const addDebtCollectorModalOpen =()=>({
        type:SET_ADD_DEBT_COLLECTOR_MODAL_OPEN,
        payload:true
    })

    export const addDebtCollectorModalClose =()=>({
            type:SET_ADD_DEBT_COLLECTOR_MODAL_CLOSE,
            payload:false
    })

export const toogleDrawerDebtCollector=()=>({
    type:TOOGLE_DRAWER_DEBT_COLLECTOR
})