import React from 'react'
import {Switch,Route} from 'react-router-dom'
import asyncComponent from "../../../util/asyncComponent";
const ReportPointOfSale =({match})=>(
    <Switch>
          <Route  path={`${match.url}/SalePrice`} component={asyncComponent(()=>import('./SalePrice/index'))} />
          <Route  path={`${match.url}/StockOfGoods`} component={asyncComponent(()=>import( './StockOfGoods/index'))} />
          <Route  path={`${match.url}/SalesAnalysisByReference`} component={asyncComponent(()=>import( './SalesAnalysisByReference/index'))} />
          <Route  path={`${match.url}/SalesAnalysisByDate`} component={asyncComponent(()=>import( './SalesAnalysisByDate/index'))} />
          <Route  path={`${match.url}/MovementReference`} component={asyncComponent(()=>import( './MovementReference/index'))} />
          
    </Switch>
)
export default ReportPointOfSale;