import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import Main from "./main/index";
import Maintenance from './Maintenance/index'
import FcModule from './FC/index'
import FisModule from './FIS/index'
import CXC from './CXC/index'
import CXP from './CXP'
import FAC from './FAC'
import BANK from './Bank'
import UC from './UC'
import customViews from './customViews'
import { useLocation, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import AssetsModule from "./Assets";
import PointOfSale from "./PointOfSale"

const App = ({ match }) => {

  const location = useLocation();
  const history = useHistory()
  const navBarItems = useSelector(({ settings }) => settings.NavBarItems);



  useEffect(() => {
    const search = location.search;
    const pathname = location.pathname;
    if (search.trim() !== "" && navBarItems.length > 0) {
      const InterfaceId = new URLSearchParams(search).get("I");
      const ModuleId = new URLSearchParams(search).get("M");
      const TabId = new URLSearchParams(search).get("T");
      const Modulo = navBarItems.find(s => s.moduleId == ModuleId);
      if (typeof Modulo !== "undefined") {
        const Tab = Modulo.imrTabsInterfaces.find(t => t.tabId == TabId);
        if (typeof Tab !== "undefined") {
          const Interface = Tab.moduleInterfaces.find(x => x.id == InterfaceId && x.hasAccess);
          if (typeof Interface == "undefined") {
            history.push(`${match.url}EP/ErrorPages/error-401`);
          }
        }
      }
    }
  })


  return <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}main`} component={Main} />
      <Route path={`${match.url}FC`} component={FcModule} />
      <Route path={`${match.url}FIS`} component={FisModule} />
      <Route path={`${match.url}CXC`} component={CXC} />
      <Route path={`${match.url}CXP`} component={CXP} />
      <Route path={`${match.url}FAC`} component={FAC} />
      <Route path={`${match.url}Maintenance`} component={Maintenance} />
      <Route path={`${match.url}Bank`} component={BANK} />
      <Route path={`${match.url}UC`} component={UC} />
      <Route path={`${match.url}EP`} component={customViews} />
      <Route path={`${match.url}Assets`} component={AssetsModule} />
      <Route path={`${match.url}PointOfSale`} component={PointOfSale}></Route>
    </Switch>

  </div>
};
export default App;
