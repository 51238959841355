import React from "react";
import {Layout} from 'antd';
import {Link, useHistory} from "react-router-dom";
import {connect, useDispatch, useSelector} from "react-redux";
import {switchLanguage, toggleCollapsedSideNav} from "../../../appRedux/actions/Setting";
import UserInfo from "components/UserInfo";
import HorizontalNav from "../HorizontalNav";
import {TAB_SIZE} from "../../../constants/ThemeSetting";

const {Header} = Layout;

const InsideHeader = () => {

  const history=useHistory();
  //const navCollapsed = useSelector(({settings}) => settings.navCollapsed);
  const navCollapsed = useSelector(({common}) => common.navCollapsed);
 // const {locale, width, navCollapsed, navStyle} = useSelector(({settings}) => settings);
  const width = useSelector(({common}) => common.width);
  const company = useSelector(({auth}) => auth.company);
  const branch = useSelector(({auth}) => auth.branch);
  const dispatch = useDispatch();

  const Redirect=(Url)=>{
      history.push(Url);
  }
  return (
    company==null?null:
    <div className="gx-header-horizontal gx-header-horizontal-dark gx-inside-header-horizontal">
      <div className="gx-header-horizontal-top" style={{maxHeight:'5px'}}>
              <div className="gx-container gx-text-center">
                 <p className="gx-mb-0 gx-text-truncate" style={{fontSize:'x-large'}}> {company.displayCompany} / {branch.displayBranch} </p>
              </div>
      </div>
      <Header
        className="gx-header-horizontal-main">
        <div className="gx-container">
          <div className="gx-header-horizontal-main-flex">
            <div className="gx-d-block gx-d-lg-none gx-linebar gx-mr-xs-3 6e">
              <i className="gx-icon-btn icon icon-menu"
                 onClick={() => {
                   dispatch(toggleCollapsedSideNav(!navCollapsed));
                 }}
              />
            </div>
             <Link to="/" className="gx-d-block gx-d-lg-none gx-pointer gx-mr-xs-3 gx-pt-xs-1 gx-w-logo">
            <img alt="" src={company.fileNameImage || ""} style={{height:'40px',borderRadius:'50%'}}/></Link>
            <Link to="/" className="gx-d-none gx-d-lg-block gx-pointer gx-mr-xs-5 gx-logo">
              <img  alt="" src={company.fileNameImage || ""} style={{height:'50px',borderRadius:'10%'}}/></Link> 
             {//TODO:Se Quitó la condicion para que no renderizara de nuevo
             }
            
             {//width >= TAB_SIZE && ( 
            <div className="gx-header-horizontal-nav gx-header-horizontal-nav-curve gx-d-none gx-d-lg-block">
              <HorizontalNav/>
            </div>
             //)
             }
            <ul className="gx-header-notifications gx-ml-auto">
              <li className="gx-language">
              <span onClick={Redirect.bind(this,"/Maintenance/Main")} className="gx-pointer gx-flex-row gx-align-items-center">
                <i  className={`icon icon-setting icon-2x`} style={{fontSize:18}} /> 
              </span>
              </li> 
              <li className="gx-user-nav"><UserInfo/></li>
            </ul>
          </div>
        </div>
      </Header>
    </div>
  );
};

const mapStateToProps = ({settings}) => {
  const {locale, navCollapsed} = settings;
  return {locale, navCollapsed}
};
export default connect(mapStateToProps, {toggleCollapsedSideNav, switchLanguage})(InsideHeader);
