import {GET_STATE_ACCOUNT_SEARCH_DATA} from '../../../../constants/ActionTypes'
import {getRequest} from '../../Axios'

export const getStateAccountSeachData=()=>(dispatch)=>{
    dispatch(getRequest(`StateAccount/GetAll`,{}))
    .then(resp=>{
        const {payload:{data/*,status*/}}=resp;
        dispatch({
            type:GET_STATE_ACCOUNT_SEARCH_DATA,
            payload:data
        })
    })
    .catch(error=>{

    })
}