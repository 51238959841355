import React from 'react'
import {Switch,Route} from 'react-router-dom'
import asyncComponent from "../../../util/asyncComponent";
const ConsultCXP =({match})=>(
    <Switch>
          <Route  path={`${match.url}/CopyBuyGoodsAndService`} component={asyncComponent(()=>import( './CopyBuyGoodsAndService/index'))} />
          <Route  path={`${match.url}/ProviderMovements`} component={asyncComponent(()=>import( './ProviderMovements/index'))} />
          <Route  path={`${match.url}/CopyProviderPayment`} component={asyncComponent(()=>import( './CopyProviderPayment/index'))} />
          <Route  path={`${match.url}/CopyDebitNotice`} component={asyncComponent(()=>import( './CopyDebitNotice/index'))} />
    </Switch>
)
export default ConsultCXP;