import {SET_ALL_COMPANIES}  from '../../../constants/ActionTypes'
const InitialState={
    companyList:[]
}
function companySelector(state=InitialState, action){
    switch(action.type){
        case SET_ALL_COMPANIES:
            console.log({...state,companyList:action.payload.data},"Todo Bien")
            return {
              ...state,
              companyList:action.payload.data
            }
        default :
        return state;
    }
}
export default companySelector;