import {SET_VALIDATION_MESSAGE_VOUCHER_RECORD,TOOGLE_ALERT_VOUCHER_RECORD,GET_SPECIFIC_DOCUMENT_DATA_VOUCHER_RECORD,ADD_ROW_MOVEMENTS_TABLE,UPDATE_ROW_MOVEMENTS_TABLE,TOOGLE_HEADER_VISIBILITY,RESET_VOUCHER_RECORD,DELETE_ROW_MOVEMENTS_TABLE,FILTER_MOVEMENT_DATA_BY_REFERENCE,RESET_TABLE_MOVEMENTS_VOUCHER_RECORD,ADD_ACCOUNT_MOVEMENT_Depreciation_RECORD,ADD_ACCOUNT_MOVEMENT_VOUCHER_RECORD, SET_ALL_MOVEMENTS_CLOSING_DATE} from '../../../../constants/ActionTypes'

const InitialState={
    ValidationMessage:[],
    ShowAlert:false,
    DocumentInfo:{
            abbreviationCode: "",
            active: false,
            description: "",
            documentType: "",
            id: 0,
            isAuditable: false,
            isAutomatic: true,
            listReferences:[]
    },
    DisabledHeader:false,
    MovementsTableData:[],
    accountMovementSaved:{
        id:0,
        number:0,
        length:0
    }
    
}
    function voucherRecord(state=InitialState,action){
        switch (action.type) {
            case SET_VALIDATION_MESSAGE_VOUCHER_RECORD:
                return {
                    ...state,
                    ValidationMessage:action.payload
                }
            case TOOGLE_ALERT_VOUCHER_RECORD:
                return {
                    ...state,
                    ShowAlert:!state.ShowAlert
                }
            case GET_SPECIFIC_DOCUMENT_DATA_VOUCHER_RECORD:
                return {
                    ...state,
                    DocumentInfo:action.payload
                }
            case ADD_ROW_MOVEMENTS_TABLE:
                return {
                    ...state,
                    MovementsTableData:[...state.MovementsTableData,action.payload]
                }
            case UPDATE_ROW_MOVEMENTS_TABLE:
                //Busco la data actual
                let PrevData=state.MovementsTableData;
                //actualizo la posicion
                PrevData[action.payload.index]=action.payload.data;
                return {
                    ...state,
                    MovementsTableData:PrevData
                }
            case TOOGLE_HEADER_VISIBILITY:
                return {
                    ...state,
                    DisabledHeader: true //!state.DisabledHeader
                }
            case RESET_VOUCHER_RECORD:
                return InitialState;
            case DELETE_ROW_MOVEMENTS_TABLE:
                let D=state.MovementsTableData;
                D.splice(action.payload,1);
                return{
                    ...state,
                    MovementsTableData:D
                }
            case FILTER_MOVEMENT_DATA_BY_REFERENCE:

                    const NewData=state.DocumentInfo.listReferences
                                            .filter(x=>x.contableReferenceId===action.payload.id)
                                                .map(x=>({
                                                        Account:x.account,
                                                        Display:x.display,
                                                        AuxiliaryType:x.auxiliaryTypeId,
                                                        CatalogAccountId:x.catalogAccountId,
                                                        ListAuxiliaryMovements:[],
                                                        Origin:x.origin,
                                                        Value:x.value,
                                                        key:`${x.id}${x.origin}`
                                                    }));

                    return{
                        ...state,
                        MovementsTableData:NewData
                    }
                case RESET_TABLE_MOVEMENTS_VOUCHER_RECORD:
                    return {
                        ...state,
                        MovementsTableData:[]
                    }
                case ADD_ACCOUNT_MOVEMENT_VOUCHER_RECORD:
                    return{
                        ...state,
                        accountMovementSaved:action.payload.data
                    }
                case SET_ALL_MOVEMENTS_CLOSING_DATE :
                    return {
                        ...state,
                        MovementsTableData: action.payload   
                    }
            default:
                return state;
        }  
    }
export default voucherRecord;