import {GET_ALL_DOCUMENT_ABBREVIATION_DATA,SET_ADD_DOCUMENT_MODAL_OPEN,SET_ADD_DOCUMENT_MODAL_CLOSE,SET_SEARCH_DOCUMENT,TOOGLE_DOCUMENT_ABBREVIATION,FILTER_DOCUMENTS_ABBREVIATION,SELECT_FILTER_OPTION_DOCUMENT_EMISSION} from '../../../../constants/ActionTypes'
const InitialState={
    AllDocumentList:[],
    DocumentList:[],
    selectedSectionId: 2,
    drawerState: false,
    searchDocument: '',
    filterOption: 'All contacts',
    addDocumentVisible: false,
}

function documentMaintenance(state=InitialState,action){
    switch (action.type) {
        case GET_ALL_DOCUMENT_ABBREVIATION_DATA:

            const DocumentList=action.payload.map(document=>
                ({
                    AbbreviationCode:document.abbreviationCode,
                    Description:document.description,
                    DocumentType:document.documentType,
                    IsAuditable:document.isAuditable,
                    IsAutomatic:document.isAutomatic,
                    Active:document.active,
                    ActiveDisplay:(document.active)?"Activo":"Inactivo",
                    AuditableDisplay:(document.isAuditable)?"Auditable":"No Auditable",
                    IsAutomaticDisplay:(document.isAuditable)?"Automatico":"Manual",
                    Id:document.id,
                    key:document.id,
                    ParentId:document.parentId
                })
            )
            return {
                ...state,
                DocumentList:DocumentList.filter(x=> (state.selectedSectionId===1) || (state.selectedSectionId===2 && x.Active===true)||(state.selectedSectionId===3 && x.Active===false)?true:false),
                AllDocumentList:DocumentList
            }

        case SET_ADD_DOCUMENT_MODAL_OPEN:
            return {
                ...state,
                addDocumentVisible:action.payload
            } 
        case SET_ADD_DOCUMENT_MODAL_CLOSE:
            return {
                ...state,
                addDocumentVisible:action.payload
            }
        case SET_SEARCH_DOCUMENT:
            return {
                ...state,
                searchDocument:action.payload
            }
        case TOOGLE_DOCUMENT_ABBREVIATION:
            return {
                ...state,
                drawerState:action.payload
                
            }
        case FILTER_DOCUMENTS_ABBREVIATION:
            const NewData=state.AllDocumentList.filter(document=>JSON.stringify(document).toLowerCase().indexOf(state.searchDocument.toLowerCase())!==-1)
                               .filter(x=>
                                    (state.selectedSectionId===1) || (state.selectedSectionId===2 && x.Active===true)||(state.selectedSectionId===3 && x.Active===false)?true:false)
            return {
                ...state,
                DocumentList:NewData
            }
        case SELECT_FILTER_OPTION_DOCUMENT_EMISSION:
            const SelectedOption=action.payload;
            switch ((SelectedOption)) {
                case 1:
                    //All
                    return{
                        ...state,
                        selectedSectionId:SelectedOption,
                        DocumentList:state.AllDocumentList
                    }
                case 2:
                    //Active
                    return{
                        ...state,
                        selectedSectionId:SelectedOption,
                        DocumentList:state.AllDocumentList.filter(x=>x.Active===true)
                    }
                case 3:
                    //Inactive  
                    return{
                        ...state,
                        selectedSectionId:SelectedOption,
                        DocumentList:state.AllDocumentList.filter(x=>x.Active===false)
                    }
                default:
                    return state;
            }
            
        default:
            return state
    }
}

export default documentMaintenance;