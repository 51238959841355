import React from "react";
import {useHistory} from "react-router-dom";

const AppsNavigation = () =>{
  const history=useHistory();

  const Redirect=(Url)=>{
    history.push(Url);
  }
  
return(
 <ul className="gx-app-nav">
   <li>
     <span onClick={Redirect.bind(this,"/Maintenance/Main")} className="gx-pointer gx-flex-row gx-align-items-center">
      <i  className={`icon icon-setting icon-2x`} style={{fontSize:18}} /> 
     </span>
   </li> 
  </ul>
)
}
export default AppsNavigation;
