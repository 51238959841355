import { GET_TABLE_DATA_NCF_ACCOUNT } from '../../../../constants/ActionTypes'

const InitialState = {
    TableData: []
}
function NCFAccount(state = InitialState, action) {
    switch (action.type) {
        case GET_TABLE_DATA_NCF_ACCOUNT:
            return {
                ...state,
                TableData:action.payload
            }
        default:
            return state
    }

}

export default NCFAccount;