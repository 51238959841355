import {postRequest, putRequest} from '../../Axios'


export const addCatalogAccount=(params,body,callBack)=>(dispatch)=>{ 
    dispatch(postRequest('/CatalogAccount/',params,body))
    .then(resp=>{
        const {payload:{data,status}}=resp
        callBack(data,status);
    })
    .catch(error=>{
        console.log(error)
    })
}

export const updateCatalogAccount=({id},body,callBack)=>(dispatch)=>{ 
    dispatch(putRequest(`/CatalogAccount/UpdateCatalogAccount/${id}`,{id},body))
    .then(resp=>{
        const {payload:{data,status}}=resp
        callBack(data,status);
    })
    .catch(error=>{
        console.log(error)
    })
}