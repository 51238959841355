import {SET_ALL_BRANCHES} from '../../../constants/ActionTypes'
const InitialState={
    branchList:[]
}


 function BranchSelector(state=InitialState, action){

    switch(action.type){
        case SET_ALL_BRANCHES :
            return {
                ...state,
                branchList:action.payload.data
            }
        default:
         return state;

    }
}
export default BranchSelector;