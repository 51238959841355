import {GET_NCF_AVAILABLE_DATA,SET_DATE_NCF_AVAILABLE,RESET_NCF_AVAILABLE} from '../../../../constants/ActionTypes'
import moment from 'moment'

const InitialState={
    TableData:[],
    Year:""
}

function NCFAvailable (state=InitialState,action){

        switch (action.type) {
             case GET_NCF_AVAILABLE_DATA:
                return {
                    ...state,
                    TableData:action.payload.map(x=>({
                        ...x,
                        description:`${x.seriesName}${x.type} - ${x.description}`,
                        TotalAvailable:(x.availableMayor+x.availableInYear),

                    }))
                }
            case SET_DATE_NCF_AVAILABLE:
                return{
                    ...state,
                    Year: moment(action.payload).format("YYYY")
                }
            case RESET_NCF_AVAILABLE:
                return InitialState;
            default:
                return state;
        }
}

export default NCFAvailable;