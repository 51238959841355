import React, { useEffect } from "react";
import { Button, Form, Input, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { postRequest } from '../appRedux/actions/Axios'
import { NotificationContainer, NotificationManager } from "react-notifications";
import { NotificationErrorTimeOut } from '../constants/Config'
import LoginImage from '../assets/images/LoginImage.png'
import BackGround from '../assets/images/background.png'
import {
  hideMessage,
  showAuthLoader,
  hideAuthLoader,
  userSignIn,
  setEncriptionUser
} from "appRedux/actions/Auth";

import CircularProgress from "components/CircularProgress/index";

const FormItem = Form.Item;

const SignIn = (props) => {
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const { loader, alertMessage, showMessage, authUser } = useSelector(({ auth }) => auth);
  const history = useHistory();

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 100);
    }

    if (localStorage.getItem("token") !== null) {
      history.push('/');
    }

  });
  const handleSubmit = (e) => {
    form.validateFields().then(values => {
      dispatch(showAuthLoader());
      dispatch(postRequest(`/users/authenticate`, {}, values))
        .then((r) => {
          const { payload: { data } } = r;
          if (typeof data !== "undefined") {
            dispatch(setEncriptionUser(
              {
                email: data.user.email,
                firstName: data.user.person.firstName,
                lastName: data.user.person.lastName,
                userAccessId: data.user.userAccessId
              }
            ))
            dispatch(userSignIn(data));
          }
        })
        .catch((e) => {
            console.log(e);
          if (typeof e.error.response == "undefined") {
            NotificationManager.error("", e.toString(), NotificationErrorTimeOut);
            dispatch(hideAuthLoader())
            return;
          }

          const { error: { response } } = e;
          dispatch(hideMessage());
          if (typeof response != "undefined") {
            if (response.data === null) {
              NotificationManager.error("Ha ocurrido un error, si el problema persiste contacte a su administrador.", "Error!", NotificationErrorTimeOut);
              dispatch(hideAuthLoader())
            } else {
              NotificationManager.error("", response.data.message, NotificationErrorTimeOut);
              dispatch(hideAuthLoader())
            }
          } else {
            NotificationManager.error("Ha ocurrido un error, si el problema persiste contacte a su administrador.", "Error!", NotificationErrorTimeOut);
            dispatch(hideAuthLoader())
          }
        });
    }).catch(error => {
      console.error(error);
    })

    // e.preventDefault();
    //  props.form.validateFields((err, values) => {
    //   if (!err) {
    //     dispatch(showAuthLoader());
    //     dispatch(postRequest(`/users/authenticate`,{},values))
    //     .then((r)=>{
    //         const {payload:{data}}=r;
    //         if(typeof data!=="undefined"){
    //             dispatch(userSignIn(data));
    //         }})
    //     .catch((e)=>{
    //         const {error:{response}}=e;
    //         dispatch(hideMessage());
    //         if(typeof response!="undefined"){
    //             NotificationManager.error("",response.data.message,NotificationErrorTimeOut);
    //         }else{
    //           NotificationManager.error("Ha ocurrido un error, si el problema persiste contacte a su administrador.","Error!",NotificationErrorTimeOut);
    //         }
    //     });
    //   }
    // });
  };
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
    labelAlign: "left"

  };

  var Styles = {
    backgroundImage: `url(${BackGround})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  }


  return (
    <div className="gx-app-login-wrap" style={Styles}  >
      <div className="gx-app-login-container" >
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg">
              <img src={LoginImage} alt='Neature' />
            </div>
          </div>
          <div className="gx-app-login-content">
            <Form
              {...formItemLayout}
              onFinish={handleSubmit}
              onFinishFailed={handleSubmit}
              form={form}
              name="SignIn"
              className="gx-signin-form gx-form-row0">

              <FormItem
                name="Username"
                initialValue=""
                label="Correo Electrónico"
                rules={[{
                  required: true, type: 'email', message: 'La entrada no es un Correo Valido!',
                }]}
                className="gx-mt-2"
              >
                <Input /*placeholder="Correo Electrónico"*/ />
              </FormItem>
              <FormItem
                name="Password"
                label="Contraseña"
                initialValue=""
                className="gx-mt-3"
                rules={[{ required: true, message: 'Campo Contraseña es requerido.' }]}
              >
                <Input type="password" /*placeholder="Contraseña"*/ />
              </FormItem>
              {/* <FormItem
                  name="remember"
                  valuePropName= 'checked'
                  initialValue={ true}
                >
                    <Checkbox><IntlMessages id="appModule.iAccept"/></Checkbox>
                  <span className="gx-signup-form-forgot gx-link"><IntlMessages
                    id="appModule.termAndCondition"/></span>
                </FormItem> */}
              <FormItem className="gx-mb-4">
                <Button className="gx-mb-0 DaColor" htmlType="submit">
                  Iniciar Sesión
                </Button>
                {/* <span><IntlMessages id="app.userAuth.or" /></span> <Link to="/signup"><IntlMessages
                  id="app.userAuth.signUp" /></Link> */}
              </FormItem>
            </Form>
          </div>

          {loader ?
            <div className="gx-loader-view">
              <CircularProgress />
            </div> : null}
          {showMessage ?
            message.error(alertMessage.toString()) : null}
        </div>
      </div>
      <NotificationContainer />
    </div>
  );
};

const WrappedNormalLoginForm = (SignIn);

export default WrappedNormalLoginForm;
