import { GET_Clasification_LIST_Register } from "../../../../constants/ActionTypes";
import { zeroPad } from "../../../../util/DaFrameword";


const InitialValue={
    ClasificationList:[]
}

function ClasificationRegister(state=InitialValue,action){

    switch (action.type) {
        case GET_Clasification_LIST_Register:
            return {
                ...state,
                    ClasificationList:action.payload.map(x=>({
                    Id:zeroPad(x.id,8),
                    description:x.description             
                })) 
            }
        default:
           return state
    }  

}

export default ClasificationRegister;

