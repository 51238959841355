import React from 'react'
import {Route,Switch} from 'react-router-dom'
import asyncComponent from "../../../util/asyncComponent";
const UC_Entry =({match})=>(
    <Switch>
    
          <Route  path={`${match.url}/CompanyCreate`} component={asyncComponent(()=>import( './CompanyCreate/index'))} />
    </Switch>
)

export default UC_Entry;