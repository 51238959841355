import {GET_CONTAB_ACTIVE_REFERENCES,GET_CONTAB_ACTIVE_ACTION,GET_DOCUMENT_INTERFACE,GET_ALL_ACTION_PROGRAM_ACCOUNT_DATA,SET_ADD_ACTION_PROGRAM_ACCOUNT_MODAL_CLOSE,SET_ADD_ACTION_PROGRAM_ACCOUNT_MODAL_OPEN
    ,SET_SEARCH_APC,FILTER_ACTION_PROGRAM_ACCOUNT,SELECT_FILTER_OPTION_ACTION_PROGRAM_ACCOUNT,TOOGLE_DRAWER_CONTAB_ACTION_PROGRAM_ACCOUNT,
    ADD_Actions_Assigned,UPDATE_Actions_Assigned,Reset_Actions_Assigned,ADD_ACTION_ACCOUNT,RESET_ACTION_ACCOUNT,OPEN_CLOSE_ACTION_PROGRAM_ACCOUNT} from '../../../../constants/ActionTypes'
const InitialState={
    AllData:[],
    Data:[],
    selectedSectionId: 2,
    drawerState: false,
    search: '',
    filterOption: 'All contacts',
    addModalVisible: false,
    contableReferencesData:[],
    contableActionData:[],
    interfaceData:[],
    ActionsAssigned:[],
    ActionAccount:[],
    CurrencyId:0,
    OpenOrCloseModalActionAccount:false,
    DocumentEmissionId:0

}

function actionProgramaAccountMaintenance(state=InitialState,action){
    switch (action.type) {
            case GET_CONTAB_ACTIVE_REFERENCES:
                return {
                    ...state,
                    contableReferencesData:action.payload
                }
            case GET_CONTAB_ACTIVE_ACTION:
                return {
                    ...state,
                    contableActionData:action.payload
                }
            case GET_DOCUMENT_INTERFACE:
                return  {
                    ...state,
                    interfaceData:action.payload
                }
            case GET_ALL_ACTION_PROGRAM_ACCOUNT_DATA:

                return {
                    ...state,
                    AllData:action.payload,
                    Data:action.payload.filter(x=>((state.selectedSectionId===1)?true:false) || (state.selectedSectionId===2 && x.active===true ) || (state.selectedSectionId===3 && x.active===false )),
                }

                case SET_ADD_ACTION_PROGRAM_ACCOUNT_MODAL_OPEN:
                    return {
                        ...state,
                        addModalVisible:action.payload
                    } 
                case SET_ADD_ACTION_PROGRAM_ACCOUNT_MODAL_CLOSE:
                    return {
                        ...state,
                        addModalVisible:action.payload
                    }
                case SET_SEARCH_APC:
                    return {
                        ...state,
                        search:action.payload
                    }

                case FILTER_ACTION_PROGRAM_ACCOUNT:

                    const DT=state.AllData.filter((row)=>`${row.documentEmissionDisplay}${row.moduleInterface}${row.contableReference}${row.originDisplay}${row.contabAction}${row.activeDisplay}${row.group}`.toLowerCase().indexOf(state.search.toLowerCase())!==-1)
                                          .filter((x)=>((state.selectedSectionId===1)?true:false) || (state.selectedSectionId===2 && x.active===true ) || (state.selectedSectionId===3 && x.active===false ))
                    return {
                        ...state,
                        Data:DT
                    }
                case SELECT_FILTER_OPTION_ACTION_PROGRAM_ACCOUNT:
                    switch (action.payload) {
                        case 1: 
                        return{
                            ...state,
                            Data:state.AllData,
                            selectedSectionId:action.payload
                        }
                        case 2:
                            return {
                                ...state,
                                Data:state.AllData.filter(row=>row.active===true)
                                ,selectedSectionId:action.payload
                            }
                        case 3: 
                            return {
                                ...state,
                                Data:state.AllData.filter(row=>row.active===false), 
                                selectedSectionId:action.payload
                            }
                        default:
                            return state
                    }
            case TOOGLE_DRAWER_CONTAB_ACTION_PROGRAM_ACCOUNT:
                return {
                    ...state,
                    drawerState:!state.drawerState
                }

            case ADD_Actions_Assigned:
                return{
                    ...state,
                    ActionsAssigned:action.payload         
                }

            case ADD_ACTION_ACCOUNT:
                    return{
                        ...state,
                        ActionAccount:[...state.ActionAccount,action.payload]        
                    }
            case RESET_ACTION_ACCOUNT:
                    return {
                            ...state,
                            ActionAccount:[]
                        }
                    
        
                case UPDATE_Actions_Assigned:
                    let NewData=state.ActionsAssigned.map((data, index)=>(index != action.payload.index ?  data : ({
                        ...data,
                        ...action.payload.data
                      })));
                      return {
                          ...state,
                          ActionsAssigned:NewData
                }
                case Reset_Actions_Assigned:
                    return {
                        ...state,
                        ActionsAssigned:[]
                    }
                case OPEN_CLOSE_ACTION_PROGRAM_ACCOUNT:
                    return{
                        ...state,
                        CurrencyId:action.payload.currencyId,
                        OpenOrCloseModalActionAccount:action.payload.openOrClose,
                        DocumentEmissionId:action.payload.DocumentEmissionId
                    }
        default:
            return state
    }
}

export default actionProgramaAccountMaintenance;