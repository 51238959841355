import {GET_Model_LIST_Register,SET_Brand_Data} from "../../../../constants/ActionTypes";
import { zeroPad } from "../../../../util/DaFrameword";


const InitialValue={
    ModelList:[],
    Brand:null
}

function ModelRegister(state=InitialValue,action){

    switch (action.type) {
        case GET_Model_LIST_Register:
            return {
                ...state,
                    ModelList:action.payload.map(x=>({
                    Id:zeroPad(x.id,8),
                    brandDescription:x.brand.description,
                    machineDescription:x.machine.description,
                    description:x.description,
                    active:x.active,
                    brandId:x.brandId,    
                    machineId:x.machineId
                })) 
            }

        case SET_Brand_Data:
            return{
                ...state,
                Brand:action.payload
            }
        default: return state
    }  

}

export default ModelRegister;