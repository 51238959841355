import { GET_AssetCategory_LIST_Register } from "../../../../constants/ActionTypes";
import { zeroPad } from "../../../../util/DaFrameword";


const InitialValue={
    AssetCategoryList:[]
}

function AssetCategoryRegister(state=InitialValue,action){

    switch (action.type) {
        case GET_AssetCategory_LIST_Register:
            return {
                ...state,
                    AssetCategoryList:action.payload.map(x=>({
                    id:zeroPad(x.id,4),
                    code:x.code,
                    description:x.description,
                    clasificationId:x.clasification.id,

                    debitAccount:x.debitAccount,
                    debitAccountName:x.debitAccountName,
                    
                    depreciationAccount:x.depreciationAccount,
                    depreciationAccountName:x.depreciationAccountName,
                  
                    expenseAccount:x.expenseAccount,
                    expenseAccountName:x.expenseAccountName            
                })) 
            }
        default:
           return state
    }  

}

export default AssetCategoryRegister;