import React from "react";
import {useDispatch, useSelector } from "react-redux";
import {Avatar, Popover} from "antd";
import {userSignOut} from "appRedux/actions/Auth";
import { UserOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom'

const UserProfile = () => {
  const { companyGroup, company,user } = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();
  const history = useHistory();

  const GoTo = (Type) => {
    //console.log(companyGroup, company)
    if (Type === 1) {
      history.push({
        pathname: `/companyGroup`,
      });
    } else if (Type === 2) {
      history.push({
        pathname: `/companySelector`,
        state: { CompanyGroup: companyGroup.id }
      });
    } else if (Type === 3) {
      history.push({
        pathname: `/branchSelector`,
        state: { Company_ID: company.id }
      });
    }
  }

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li onClick={GoTo.bind(this, 1)}>Cambiar de Grupo</li>
      <li onClick={GoTo.bind(this, 2)}>Cambiar de Compañia</li>
      <li onClick={GoTo.bind(this, 3)}>Cambiar de Sucursal</li>
      <li onClick={() => dispatch(userSignOut())}>Cerrar Sesión
      </li>
    </ul>
  );

  return (

    <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
      <Popover title={`${user.firstName} ${user.lastName}`} overlayClassName="gx-popover-horizantal" placement="bottomRight" content={userMenuOptions}
      trigger="click">
      <Avatar size="small" icon={<UserOutlined />} className=" gx-pointer" alt="" />
      <span className="gx-avatar-name gx-ml-1" style={{ fontSize: 'small' }}> {user.firstName} {user.lastName} <i
        className="icon icon-chevron-down gx-fs-xxs gx-ml-2" /></span>
    </Popover>
    </div>

  )
};

export default UserProfile;
