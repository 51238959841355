import {APP_GET_VALUES_REQUEST} from '../actions/Axios'

const initialState={
    Values:{}
}

function axios (state=  initialState, action)  {
    switch (action) {
        case APP_GET_VALUES_REQUEST:
            return {
                ...state,
                values: action.payload.data
            };
           
        default: return state;
    }
};

export default axios;