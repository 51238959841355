import {GET_BANK_TRANSACTIONS_BANK_CONCILIATION,TOOGLE_TRANSACTION_BANK_CONCILIATION,SET_BANK_CONCILIATION_ID_BANK_CONCILIATION,SELECT_ALL_BANK_CONCILIATION, CALCULATE_RESUMEN_BANK_CONCILIATION,SET_BANK_ACCOUNT_ORIGIN_BANK_CONCILIATION,SET_LAST_BALANCE_BANK_CONCILIATION,SET_BANK_BALANCE_BANK_CONCILIATION,RESET_BANK_CONCILIATION} from '../../../../constants/ActionTypes'
import moment from 'moment'
import { currencyFormat, zeroPad, } from '../../../../util/DaFrameword';
const InitialState={

    BankConciliationId:null,
    BankAccountOrigin:null,
    BankTransactions:[],
    LastBalance:0.00,
    Debit:0.00,
    Credit:0.00,
    SheetBalance:0.00,
    DebitInTransit:0.00,
    CreditInTransit:0.00,
    ConciliateBalance:0.00,
    BankBalance:0.00,
    Diference:0.00
}


function BankConciliation(state=InitialState,action ){

    switch (action.type) {
        case GET_BANK_TRANSACTIONS_BANK_CONCILIATION:
          

            const DATA=action.payload.map((x)=>(
                    {...x,
                        DateString:moment(x.date).format("DD/MM/YYYY"),
                        AbbreviationDisplay:`${x.abbreviationCode}-${zeroPad(x.documentType,2)}`,
                        NumberDisplay: zeroPad(x.number,x.length),
                        ConceptDisplay: x.concept==null? x.headConcept.trim():x.concept,
                        DebitString: (x.origin==1)?currencyFormat(x.value):"",
                        CreditString: (x.origin==2)?currencyFormat(x.value):"",
                        Selected:x.conciliationId===state.BankConciliationId
                    })) 
               
            return {
                ...state,
                BankTransactions:DATA
                
            }
        case SET_BANK_CONCILIATION_ID_BANK_CONCILIATION:
            return{
                ...state,
                BankConciliationId:action.payload
            };

        case SET_BANK_ACCOUNT_ORIGIN_BANK_CONCILIATION:
            return{
                ...state,
                BankAccountOrigin:action.payload
            };
        case SET_LAST_BALANCE_BANK_CONCILIATION:
                return{
                    ...state,
                    LastBalance:action.payload
                };
        case SET_BANK_BALANCE_BANK_CONCILIATION:
            return{
                ...state,
                BankBalance:action.payload
            };
        case TOOGLE_TRANSACTION_BANK_CONCILIATION:

            const {Id,Index} = action.payload.params;
           // const Index=state.BankTransactions.findIndex((x=>x.amdId==Id));
            state.BankTransactions[Index].Selected=action.payload.Value;
            if(action.payload.Value===true){
                state.BankTransactions[Index].conciliationId=state.BankConciliationId;
            }else{
                state.BankTransactions[Index].conciliationId=null;
            }
          
            return{
                ...state
            }
        case CALCULATE_RESUMEN_BANK_CONCILIATION:
             
            var dResume={
                Debit:state.BankTransactions.filter(x=>x.origin===1 && x.wasInTransitInLastClosing===false).reduce((prev,cur)=>prev+cur["value"],0),
                Credit:state.BankTransactions.filter(x=>x.origin===2 && x.wasInTransitInLastClosing===false).reduce((prev,cur)=>prev+cur["value"],0),
                DebitInTransit: state.BankTransactions.filter(x=>x.origin===1 && x.Selected===false).reduce((prev,cur)=>prev+cur["value"],0),
                CreditInTransit: state.BankTransactions.filter(x=>x.origin===2 && x.Selected===false).reduce((prev,cur)=>prev+cur["value"],0),
                SheetBalance:0,
                ConciliateBalance:0,
                Diference:0
            }

            if(state.BankAccountOrigin===1)
            {
                dResume.SheetBalance=(state.LastBalance+dResume.Debit)-dResume.Credit;
                dResume.ConciliateBalance=dResume.SheetBalance - dResume.DebitInTransit + dResume.CreditInTransit;
                dResume.Diference = dResume.ConciliateBalance - state.BankBalance;
            }
            else if(state.BankAccountOrigin===2)
            {
                dResume.SheetBalance=(state.LastBalance+dResume.Credit)-dResume.Debit;
                dResume.ConciliateBalance=dResume.SheetBalance + dResume.DebitInTransit - dResume.CreditInTransit;
                dResume.Diference = dResume.ConciliateBalance - state.BankBalance;
            }
            return{
                ...state,
                Debit: Number(dResume.Debit.toFixed(2)),
                Credit: Number(dResume.Credit.toFixed(2)),
                DebitInTransit: Number(dResume.DebitInTransit.toFixed(2)),
                CreditInTransit:Number(dResume.CreditInTransit.toFixed(2)),
                Diference:Number(dResume.Diference.toFixed(2)),
                ConciliateBalance:Number(dResume.ConciliateBalance.toFixed(2)),
                SheetBalance:Number(dResume.SheetBalance.toFixed(2))
            }
        case RESET_BANK_CONCILIATION:
            return InitialState;
        
        case SELECT_ALL_BANK_CONCILIATION:
            const value=action.payload;

            var newList=state.BankTransactions.map(x=>({
                ...x,
                conciliationId:value?state.BankConciliationId:null,
                Selected:value
            }))
            console.log(newList)
            return{
                ...state,
                BankTransactions:newList
            }

        default:
            return state;
    }

}

export default BankConciliation;