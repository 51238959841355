import React from 'react'
import {Switch,Route} from 'react-router-dom'
import asyncComponent from "../../../util/asyncComponent";
const EntryAssets =({match})=>(
    <Switch>
          <Route  path={`${match.url}/AssetRegister`} component={asyncComponent(()=>import( './AssetRegister/index'))} />
          <Route  path={`${match.url}/AssetMovement`} component={asyncComponent(()=>import( './AssetMovement/index'))} />
          <Route  path={`${match.url}/MonthlyAssetDepreciation`} component={asyncComponent(()=>import( './MonthlyAssetDepreciation/index'))} />
    </Switch>
)
export default EntryAssets;