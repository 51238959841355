import createSagaMiddleware from "redux-saga";
import { applyMiddleware, compose, createStore } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import thunk from 'redux-thunk';
import rootSaga from "../sagas/index";
import createRootReducer from '../reducers'
import axios from 'axios';
import axiosMiddleware from "redux-axios-middleware";
import { BASE_URL,TIME_TO_UPDATE_TOKEN } from '../../constants/Config'
import { AxiosFetchStart, AxiosFetchSuccess, AxiosFetchError } from '../actions/LoadingCenter'
import { userSignOut } from "appRedux/actions/Auth";
import moment from 'moment'
import { UpdateTokenRequest } from '../actions/Axios'
const createBrowserHistory = require('history').createBrowserHistory;


export const history = createBrowserHistory();

const routeMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();


const Axios_Client = axios.create({ //all axios can be used, shown in axios documentation
  baseURL: `${BASE_URL}/api`,
  responseType: 'json'
});

const middlewareConfig = {
  returnRejectedPromiseOnError: true,
  // onSuccess:function(Info){
  //   //dispatch(AxiosFetchStart())
  //   console.log(Info)
  //   return Info;
  // },
  onError: function (Info) {
    const { response: { status, statusText }, message } = Info.error;
    //console.log(status, statusText, message);
    Info.dispatch(AxiosFetchError());


    if (status === 401)
      Info.dispatch(userSignOut());

    return (Info) ;
  },

  onComplete: function (Info) {
    const ExpirationDate = localStorage.getItem('expiration');
    if (ExpirationDate == null)
      return Info;
    var momentDate = moment(new Date(ExpirationDate));
    var duration = moment.duration(momentDate.diff(moment(new Date())));
    var minutes = duration.asMinutes();
    if (minutes <= TIME_TO_UPDATE_TOKEN && minutes > 0) {
      UpdateTokenRequest(({ token, expiration }) => {
        localStorage.removeItem('token');
        localStorage.removeItem('expiration');
        localStorage.setItem('token', token);
        localStorage.setItem('expiration', expiration);
      })
    }
    return Info;
  },
  interceptors: {
    request: [{
      success: function ({ getState, dispatch, getSourceAction }, req) {
        //const {auth} =getState();
        req.headers.Authorization = 'Bearer ' + localStorage.getItem("token");
        req.headers['Content-Type'] = 'application/json';
        req.headers['Access-Control-Allow-Origin'] = '*';
        req.headers['Access-Control-Allow-Methods'] = 'GET,PUT,POST,DELETE,PATCH,OPTIONS';
        
        //if(req.params.NOTSHOWLOADING!==true)
          dispatch(AxiosFetchStart())
          
        return req;
      },
      error: function ({ getState, dispatch, getSourceAction }, error) {
        //console.log("Request ",error)
        return Promise.reject(error);
      }
    }
    ],
    response: [{
      success: function ({ getState, dispatch, getSourceAction }, response) {
        dispatch(AxiosFetchSuccess());
        return response;
      },
      error: function ({ getState, dispatch, getSourceAction }, error) {
        return Promise.reject(error);
      }
    }
    ]
  }
};

const middlewares = [thunk, sagaMiddleware, routeMiddleware, axiosMiddleware(Axios_Client, middlewareConfig)];

export default function configureStore(preloadedState) {
  const store = createStore(
    createRootReducer(history), // root reducer with router state
    preloadedState,
    compose(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history actions
        ...middlewares
      ),
    ),
  );

  sagaMiddleware.run(rootSaga);
  return store;
}
