import {postRequest,getRequest,putRequest} from '../../Axios'
import {GET_ALL_COST_CENTER,SET_SEARCH_COST_CENTER,SELECT_FILTER_OPTION_COST_CENTER,FILTER_COST_CENTER,
        SET_ADD_COST_CENTER_MODAL_OPEN,SET_ADD_COST_CENTER_MODAL_CLOSE,TOOGLE_DRAWER_COST_CENTER} from '../../../../constants/ActionTypes'

    export  const addCostCenter=(params,body,CallBack)=>(dispatch)=>{
        dispatch(postRequest("/CostCenter",params,body))
        .then(resp=>{
            const {payload:{data,status}}=resp;
            CallBack(data,status)
        }).catch(error=>console.log(error));
    }   

    export  const updateCostCenter=({Id},body,CallBack)=>(dispatch)=>{
    dispatch(putRequest(`/CostCenter/${Id}`,{},{ ...body,Id}))
    .then(resp=>{
        const {payload:{data,status}}=resp;
        CallBack(data,status)
    }).catch(error=>console.log(error));
    }

    export const GetCostCenter=()=>(dispatch)=>{
        dispatch(getRequest("/CostCenter/GetAllCostCenter",{}))
        .then(resp=>{
            const {payload:{data}}=resp;
            dispatch({
                type:GET_ALL_COST_CENTER,
                payload:data
            })
        }).catch(error=>console.log(error));
    }

    export const setSearchCostCenter=(seachText)=>{
        return{
            type:SET_SEARCH_COST_CENTER,
            payload:seachText
        }
    }

    export const selectOptionCostCenter=(optionId)=>{  

        return{
            type:SELECT_FILTER_OPTION_COST_CENTER,
            payload:optionId
        }
    }

    export const filterCostCenter=()=>{
        return {
            type:FILTER_COST_CENTER
        }
    }


    export const addCostCenterModalOpen =()=>({
        type:SET_ADD_COST_CENTER_MODAL_OPEN,
        payload:true
    })

    export const  addCostCenternModalClose =()=>({
            type:SET_ADD_COST_CENTER_MODAL_CLOSE,
            payload:false
    })

export const toogleDrawerCostCenter=()=>({
    type:TOOGLE_DRAWER_COST_CENTER
})
  
