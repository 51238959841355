import {GET_TABLE_DATA_ACCOUNT_BANK} from '../../../../constants/ActionTypes'
const InitialState={
    TableData:[]
}
function BackAccount (state=InitialState, action){
    switch (action.type) {
        case GET_TABLE_DATA_ACCOUNT_BANK:
            return {
                TableData:action.payload
            };
        default:
          return state;
    }
}

export default BackAccount;