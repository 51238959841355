import {postRequest,getRequest,putRequest} from '../../../actions/Axios'
import {GET_ALL_DOCUMENT_ABBREVIATION_DATA,SET_ADD_DOCUMENT_MODAL_OPEN,SET_ADD_DOCUMENT_MODAL_CLOSE,SET_SEARCH_DOCUMENT,TOOGLE_DOCUMENT_ABBREVIATION,FILTER_DOCUMENTS_ABBREVIATION,SELECT_FILTER_OPTION_DOCUMENT_EMISSION} from '../../../../constants/ActionTypes'



export const setSearchDocument=(seachText)=>{
    return{
        type:SET_SEARCH_DOCUMENT,
        payload:seachText
    }
}

export const filterDocumentAbbreviation=()=>{
    return {
        type:FILTER_DOCUMENTS_ABBREVIATION
    }
}

export const toogleDocumentAbbreviation=(currentlyValue)=>(
    {
        type:TOOGLE_DOCUMENT_ABBREVIATION,
        payload:!currentlyValue
    }
)

export const  addDocumentModalOpen =()=>({
        type:SET_ADD_DOCUMENT_MODAL_OPEN,
        payload:true
})

export const  addDocumentModalClose =()=>({
        type:SET_ADD_DOCUMENT_MODAL_CLOSE,
        payload:false
})

export const setFilterOption=(option)=>(
{
    type:SELECT_FILTER_OPTION_DOCUMENT_EMISSION,
    payload:option
})
    
export  const addDocument=(params,body,CallBack)=>(dispatch)=>{
            dispatch(postRequest("/DocumentEmission",params,body))
            .then(resp=>{
                const {payload:{data,status}}=resp;
                CallBack(data,status)
            }).catch(error=>console.log(error));
}

export  const updateDocument=({Id},body,CallBack)=>(dispatch)=>{
    dispatch(putRequest(`/DocumentEmission/${Id}`,{},{ ...body,Id}))
    .then(resp=>{
        const {payload:{data,status}}=resp;
        CallBack(data,status)
    }).catch(error=>console.log(error));
}

export const GetAllDocuments=()=>(dispatch)=>{
    dispatch(getRequest("/DocumentEmission",{}))
    .then(resp=>{
        const {payload:{data}}=resp;

        dispatch({
            type:GET_ALL_DOCUMENT_ABBREVIATION_DATA,
            payload:data
        })

    }).catch(error=>console.log(error));
}
export const GetDocumentByCode=(DocumentAbbreviation)=> async(dispatch)=>{
   return await dispatch(getRequest("DocumentEmission/GetDocumentByCode",{DocumentAbbreviation}))
        .then(({payload:{data}})=>data)
        .catch(()=>null)
}

export const GetDocumentEmissionByVoucherType=(VoucherTypeId)=> async(dispatch)=>{
    return await dispatch(getRequest("DocumentEmission/GetDocumentEmissionByVoucherType",{VoucherTypeId}))
         .then(({payload:{data}})=>data)
         .catch(()=>null)
 }