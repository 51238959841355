import { GET_Department_LIST_Register } from "../../../../constants/ActionTypes";
import { zeroPad } from "../../../../util/DaFrameword";


const InitialValue={
    DepartmentList:[]
}

function DepartmentRegister(state=InitialValue,action){

    switch (action.type) {
        case GET_Department_LIST_Register:
            return {
                ...state,
                    DepartmentList:action.payload.map(x=>({
                    Id:zeroPad(x.id,8),
                    description:x.description,
                    active:x.active                
                })) 
            }
        default:
           return state
    }  

}

export default DepartmentRegister;