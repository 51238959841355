import {CONSULT_ACCOUNT_MOVEMENT,SET_CATALOG_ACCOUNT_CONSULT_ACCOUNT,RESET_CONSULT_ACCOUNT} from '../../../../constants/ActionTypes'
import moment from 'moment'
import {zeroPad,currencyFormat} from '../../../../util/DaFrameword'
const InitialState={
    LastBalance:{
        balance: 0,
        catalogAccountId: 0,
        lastClosingId: 0,
        totalCreditMovement: 0,
        totalDebitMovement: 0 ,  
    },
    Movement:[],
    CatalogAccount:{
        display:"",
        id:0,
        isControl:false,
        origin:0,
        account:""
    },
    TableData:[]

}

function  ConsultAccountReducer(state=InitialState,action){
    switch (action.type) {
        case CONSULT_ACCOUNT_MOVEMENT:
            let Balance=action.payload.balance.balance;
            const {display/*,id*/,origin,account}=state.CatalogAccount;
            const ListMovent=action.payload.movement;


            const FirstRow=
                        {
                            Account:account,
                            Display:display,
                            Date:"",
                            Document:"",
                            Number:"",
                            Debit:"",
                            Credit:"",
                            Balance:currencyFormat(Balance),
                            Concept:"",
                            key:1,
                        };

            
             const mapsListMovement= ListMovent.map((x,index)=>{
                    Balance= (origin===x.origin)? 
                                        Balance + x.value:
                                                 Balance - x.value;

                    return {
                     Account:"",
                     Display:"",
                     Date:moment(x.date).format("DD/MM/YYYY"),
                     Document:`${x.abbreviationCode}-${x.documentType}`,
                     prevBalance:"",
                     Number:zeroPad(x.number,x.length),
                     Debit:(x.origin===1)? currencyFormat(x.value):"",
                     Credit:(x.origin===2)? currencyFormat(x.value):"",
                     Balance: currencyFormat(Balance) ,
                     Concept:x.concept,
                     key:index+2
                    }
                })       

            return{
                ...state,
                LastBalance:action.payload.balance,
                Movement:action.movement,
                TableData:[FirstRow,
                                    ...mapsListMovement]
            }
        case SET_CATALOG_ACCOUNT_CONSULT_ACCOUNT:
            return {
                ...state,
                CatalogAccount:action.payload
            }
        case RESET_CONSULT_ACCOUNT:
        return{
            ...InitialState
        }
        default:
           return state;
        }
}
export default ConsultAccountReducer;