import { GET_TABLE_DATA_TAXPAYER_SEQUENCE_REQUEST_REGISTER, SET_FORM_DATA_TAXPAYER_SEQUENCE_REQUEST_REGISTER, SET_DISABLED_HEADER_TAXPAYER_SEQUENCE_REQUEST_REGISTER, UPDATE_AUTHORIZE_NUMBER_SEQUENCE_REQUEST_REGISTER,RESET_STATE_TAXPAYER_SEQUENCE_REQUEST_REGISTER } from '../../../../constants/ActionTypes'
import {zeroPad} from '../../../../util/DaFrameword'
const InitialState = {
    TableData: [],
    FormData: {
        NumberRequest: null,
        VoucherTypeSerie: null,
        ExpirationDate: null
    },
    DisabledHeader: false

}
function TPSRequestRegister(state = InitialState, action) {
    switch (action.type) {
        case GET_TABLE_DATA_TAXPAYER_SEQUENCE_REQUEST_REGISTER:
            return {
                ...state,
                TableData: action.payload
            }
        case SET_FORM_DATA_TAXPAYER_SEQUENCE_REQUEST_REGISTER:
            return {
                ...state,
                FormData: action.payload
            }
        case SET_DISABLED_HEADER_TAXPAYER_SEQUENCE_REQUEST_REGISTER:
        
            return {
                ...state,
                DisabledHeader: true
            }
        case UPDATE_AUTHORIZE_NUMBER_SEQUENCE_REQUEST_REGISTER:

            const newData = action.payload;
            const authorization = (newData.authorization === "" ? "0000000000" :  zeroPad(newData.authorization,10));
            const endSequence = (Number(newData.beginSequence)) + Number(newData.quantity);
            // const index = newData.findIndex((item) => action.payload.id === item.id);
            // newData.splice(index, 1, action.payload );
            // console.log(newData)
            return {
                ...state,
                TableData: state.TableData.map((row) => (row.id === newData.id) ? ({
                    ...newData,
                    authorization,
                    endSequence
                }) : row)
            }
            case RESET_STATE_TAXPAYER_SEQUENCE_REQUEST_REGISTER:
                return InitialState;
        default:
            return state;

    }


}

export default TPSRequestRegister;