import {SET_ALL_BRANCHES} from '../../../constants/ActionTypes'
import {getRequest} from '../Axios'

export const  setBranches= (data)=>{
    return {
        type:SET_ALL_BRANCHES,
        payload:{
            data
        }
    }
}

export const GetBranches=(params)=>(dispatch)=>{
    dispatch(getRequest(`/Branches/GetBranches`,params))
    .then(({payload:{data}})=>{
        dispatch({
                type:SET_ALL_BRANCHES,
                payload:{
                    data
                }
            })
    })
}