import {GET_FINANCIAL_STATEMENT_TYPE,GET_CONTABLE_GROUP,GET_CONTAB_TITLE,GET_CONTAB_ANNEX,
        /*ADD_STATEMENT_ACCOUNT,UPDATE_STATEMENT_ACCOUNT*/} from '../../../../constants/ActionTypes'
import {getRequest,postRequest,putRequest} from '../../Axios'

export const getFinancialStetement=()=>(dispatch)=>{
  
    dispatch(getRequest('/FinancialStatementType',{}))
    .then(resp=>{
            dispatch({
                type:GET_FINANCIAL_STATEMENT_TYPE,
                payload:resp.payload.data
            })
    })
    .catch(error=>{
        console.log(error)
    })
}

export const getContableGroup=(params)=>(dispatch)=>{
  
    dispatch(getRequest('/ContableGroup/ContableGroupByFST',params))
    .then(resp=>{
            dispatch({
                type:GET_CONTABLE_GROUP,
                payload:resp.payload.data
            })
    })
    .catch(error=>{
        console.log(error)
    })
}

export const getContableTitle=(params)=>(dispatch)=>{ 
   
    dispatch(getRequest('/ContabTitle/GetContabTitle',params))
    .then(resp=>{
            dispatch({
                type:GET_CONTAB_TITLE,
                payload:resp.payload.data
            })
    })
    .catch(error=>{
        console.log(error)
    })
}
export const getContableAnnex=(params)=>(dispatch)=>{ 
    dispatch(getRequest('/ContabAnnex/GetContabAnnex',params))
    .then(resp=>{
            dispatch({
                type:GET_CONTAB_ANNEX,
                payload:resp.payload.data
            })
    })
    .catch(error=>{
        console.log(error)
    })
}

export const addStateAccount=(params,body,callBack)=>(dispatch)=>{ 
    dispatch(postRequest('/StateAccount/AddWithAutomaticCode',params,body))
    .then(resp=>{
        const {payload:{data,status}}=resp
        callBack(data,status);
    })
    .catch(error=>{
        console.log(error)
    })
}

export const updateStateAccount=(params,body,callBack)=>(dispatch)=>{ 
    dispatch(putRequest(`/StateAccount/${body.Id}`,params,body))
    .then(resp=>{
        const {payload:{data,status}}=resp;
        callBack(data,status);
    })
    .catch(error=>{
        console.log(error)
    })
}


export const getStateAccountByCode=(params,callBack)=>(dispatch)=>{ 
    dispatch(getRequest(`/StateAccount/GetAccountByCode/${params.value}`,params))
    .then(resp=>{
        const {payload:{data,status}}=resp;
        callBack(data,status);
    })
    .catch(error=>{
        console.log(error)
    })
}


//const [FinancialStatementType]= useHttpRequest([],'GET',{Url:'/FinancialStatementType'});
//dispatch(getRequest(HttpParams.Url,{signal: ac.signal,...HttpParams.Params}))]