import { GET_Color_LIST_Register } from "../../../../constants/ActionTypes";
import { zeroPad } from "../../../../util/DaFrameword";


const InitialValue={
    ColorList:[]
}

function ColorRegister(state=InitialValue,action){

    switch (action.type) {
        case GET_Color_LIST_Register:
            return {
                ...state,
                    ColorList:action.payload.map(x=>({
                    Id:zeroPad(x.id,8),
                    description:x.description,
                    active:x.active                
                })) 
            }
        default:
           return state
    }  

}

export default ColorRegister;