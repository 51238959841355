import {postRequest,getRequest,putRequest} from '../../Axios'
import {GET_ALL_CURRENCYTAX,SET_SEARCH_CURRENCYTAX,SELECT_FILTER_OPTION_CURRENCYTAX,FILTER_CURRENCYTAX,SET_ADD_CURRENCYTAX_MODAL_OPEN,SET_ADD_CURRENCYTAX_MODAL_CLOSE,
        TOOGLE_DRAWER_CURRENCYTAX} from '../../../../constants/ActionTypes'

    export  const addCurrencyTax=(params,body,CallBack)=>(dispatch)=>{
        dispatch(postRequest("/Currency/AddCurrencyTax",params,body))
        .then(resp=>{
            const {payload:{data,status}}=resp;
            CallBack(data,status)
        }).catch(error=>console.log(error));
    }   

//     export  const updateCurrencyTax=({Id},body,CallBack)=>(dispatch)=>{
//     dispatch(putRequest(`/CurrencyTax/${Id}`,{},{ ...body,Id}))
//     .then(resp=>{
//         const {payload:{data,status}}=resp;
//         CallBack(data,status)
//     }).catch(error=>console.log(error));
//     }

    export const GetAllCurrencyTax=()=>(dispatch)=>{
        dispatch(getRequest("/Currency/GetCurrencyWithTax",{}))
        .then(resp=>{
            const {payload:{data}}=resp;
            dispatch({
                type:GET_ALL_CURRENCYTAX,
                payload:data
            })
        }).catch(error=>console.log(error));
    }

    export const setSearchCurrencyTax=(seachText)=>{
        return{
            type:SET_SEARCH_CURRENCYTAX,
            payload:seachText
        }
    }

    export const selectOptionCurrencyTax=(optionId)=>{  

        return{
            type:SELECT_FILTER_OPTION_CURRENCYTAX,
            payload:optionId
        }
    }

    export const filterCurrencyTax=()=>{
        return {
            type:FILTER_CURRENCYTAX
        }
    }


    export const addCurrencyTaxModalOpen =()=>({
        type:SET_ADD_CURRENCYTAX_MODAL_OPEN,
        payload:true
    })

    export const addCurrencyTaxModalClose =()=>({
            type:SET_ADD_CURRENCYTAX_MODAL_CLOSE,
            payload:false
    })

export const toogleDrawerCurrencyTax=()=>({
    type:TOOGLE_DRAWER_CURRENCYTAX
})
  
