import React from 'react'
import {Route,Switch} from 'react-router-dom'
import asyncComponent from "../../../util/asyncComponent";

const FIS_Report =({match})=>(
    <Switch>
          <Route  path={`${match.url}/ValidationReport606`} component={asyncComponent(()=>import( './ValidationReport606/index'))} />
          <Route  path={`${match.url}/ValidationReport607`} component={asyncComponent(()=>import( './ValidationReport607/index'))} />
          <Route  path={`${match.url}/ValidationReport608`} component={asyncComponent(()=>import( './ValidationReport608/index'))} />
    </Switch>
)
export default FIS_Report;