import {GET_FINANCIAL_STATEMENT_TYPE,GET_CONTABLE_GROUP,GET_CONTAB_TITLE,GET_CONTAB_ANNEX} from '../../../../constants/ActionTypes'
const initialState={
    FinancialStatatementTypeData:[],
    ContableGroupData:[],
    ContabTitleData:[],
    ContabAnnexData:[]
    
}
function financialStatementType(state=initialState, action){
    switch (action.type){
        case GET_FINANCIAL_STATEMENT_TYPE:
            return{
                ...state,
                FinancialStatatementTypeData:action.payload
            }
        case GET_CONTABLE_GROUP:
            return{
                ...state,
                ContableGroupData:action.payload
            }
        case GET_CONTAB_TITLE:
            return{
                ...state,
                ContabTitleData:action.payload
            }
        case GET_CONTAB_ANNEX:
        return{
            ...state,
            ContabAnnexData:action.payload
        }
        default :
        return state;
    }
}
export default financialStatementType;