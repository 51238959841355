import {GET_ALL_PricingModel,SET_ADD_PricingModel_MODAL_OPEN,SET_ADD_PricingModel_MODAL_CLOSE,TOOGLE_DRAWER_Pricingmodel,GET_ALL_TypePrice} from '../../../../constants/ActionTypes'

const InitialState={
 AllData:[],
 Data:[],
 selectedSectionId: 2,
 drawerState: false,
 filterOption: 'All contacts',
 addModalVisible: false,
}


function PricingModelingMaintenance(state=InitialState,action){
    switch (action.type) {
        case GET_ALL_PricingModel:
        return {
                ...state,
                AllData:action.payload,
                Data:action.payload.filter(x=>((state.selectedSectionId===1)?true:false) || (state.selectedSectionId===2 && x.active===true ) || (state.selectedSectionId===3 && x.active===false )),
            };


            case SET_ADD_PricingModel_MODAL_OPEN:
                return {
                    ...state,
                    addModalVisible:action.payload
                } 
            case SET_ADD_PricingModel_MODAL_CLOSE:
                return {
                    ...state,
                    addModalVisible:action.payload
                }
            case TOOGLE_DRAWER_Pricingmodel:
                return {
                    ...state,
                    drawerState:!state.drawerState
                }
                default:
                    return state
    }


}

export default PricingModelingMaintenance;