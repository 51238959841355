
import React from 'react'
import {Route,Switch} from 'react-router-dom'
import Entry from '../FAC/Entry'
import Consult from '../FAC/Consult'
import Report from '../FAC/Report'
const FAC_Module =({match})=>(
    <Switch>
        <Route path={`${match.url}/Entry`} component={Entry}  /> 
        <Route path={`${match.url}/Consult`} component={Consult}  />
        <Route path={`${match.url}/Report`} component={Report} />
    </Switch>
)

export default FAC_Module;